import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import fingerPrint from "shared/utils/fingerPrint.min";
import {authHelper} from "./Auth.helper";
import {resetState} from "shared/store/resetState";
import {initialState} from "./auth.types";

export const getFingerID = createAsyncThunk(
  "getFingerID",
  async (_, {rejectWithValue}) => {
    try {
      const FP = await fingerPrint.load();
      const {visitorId} = await FP.get();
      return visitorId;
    } catch (e: any) {
      return rejectWithValue(e);
    }
  },
);

export const authSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    ...resetState,

    setVisitorId(state, action) {
      state.visitorId = action.payload;
    },
    setIsNeedToConfirm(state, action) {
      state.isNeedToConfirm = action.payload;
    },
    removeError(state) {
      state.dataError = "";
    },
    setRefreshInProcess(state, action) {
      state.refreshInProcess = action.payload;
    },
    setIsRememberMe(state, action) {
      state.isRememberMe = action.payload;
    },
    // setNeedRefreshToken(state, action) {
    //   state.needRefresh = action.payload;
    // },
    setIsAuth(state, action) {
      state.isAuth = action.payload;
      localStorage.setItem("auth", action.payload + "");
      !action.payload && authHelper.removeToken();
    },
    setIsRegistered(state, {payload}) {
      state.isRegistered = payload;
    },
  },
  extraReducers: (builder) =>
    builder
      //getFingerID
      .addCase(getFingerID.fulfilled, (state, action) => {
        state.isLoadingFingerID = false;
        state.visitorId = action.payload;
      })
      .addCase(getFingerID.pending, (state) => {
        state.isLoadingFingerID = true;
        state.dataError = "";
      })
      .addCase(getFingerID.rejected, (state, action) => {
        state.isLoadingFingerID = false;
        state.dataError = action.payload;
      }),
});

export const {
  setIsNeedToConfirm,
  removeError,
  setRefreshInProcess,
  setVisitorId,
  setIsRememberMe,
  setIsAuth,
  setIsRegistered,
} = authSlice.actions;

export default authSlice.reducer;
