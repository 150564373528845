import {axiosGlobal} from "shared/services/axiosInstance/instAxios";
import {IUpdateReq} from "pages/CompanyUsers/types/httpTypes/httpTypes";
import {ServiceResponse} from "../servicesAll";
import {IPublicSearchReq, IPublicSearchResponse} from "./types";

const ENDP_V2 = "/company/v2";

export const companyService = {
  searchCompany: (params) => axiosGlobal().get("/company/search", {params}),
  companyInfo: (id) => axiosGlobal().get(`/company/${id}/info`),
  employeeList: () => axiosGlobal().get("/company/v2/employee-list"),
  invitationCodes: () => axiosGlobal().get("/company/v2/invitation-codes"),
  availableRoles: () => axiosGlobal().get("/company/v2/available-roles"),
  cancel: (params) => axiosGlobal().post("/company/v2/tickets/cancel", {}, {params}),
  fromRevision: (params, body) =>
    axiosGlobal().post("/company/v2/tickets/from-revision", body, {params}),
  join: (params) => axiosGlobal().post("/company/v2/join", {}, {params}),
  invitationCode: (body) => axiosGlobal().post("/company/v2/invitation-code", body),
  leave: () => axiosGlobal().get("/company/v2/leave"),
  dismiss: (params) =>
    axiosGlobal().get("/company/v2/employees/dismiss", {
      params,
    }),
  update: (body: IUpdateReq) => axiosGlobal().patch("/company/v2/employees/update", body),
  publicSearch: (params: IPublicSearchReq): ServiceResponse<IPublicSearchResponse> =>
    axiosGlobal().get("/company/public/search", {
      params,
    }),
};

// нужно перенести этот список в методы сервиса....
export const companyServiceList = {
  searchCompany: "/company/search",
  companyInfo: (id) => `${ENDP_V2}/${id}/info`,
  employeeList: `${ENDP_V2}/employee-list`,
  invitationCodes: `${ENDP_V2}/invitation-codes`,
  availableRoles: `${ENDP_V2}/available-roles`,
  cancel: `${ENDP_V2}/tickets/cancel`,
  fromRevision: `${ENDP_V2}/tickets/from-revision`,
  join: `${ENDP_V2}/join`,
  invitationCode: `${ENDP_V2}/invitation-code`,
  leave: `${ENDP_V2}/leave`,
  dismiss: `${ENDP_V2}/employees/dismiss`,
  update: `${ENDP_V2}/employees/update`,
  getOneTicket: `${ENDP_V2}/tickets`,
  publicSearch: "/company/public/search",
};
