import {ChatEvents} from "./ChatEvents";
import {store} from "shared/store/store";
import {
  prependChats,
  setArchivedChats,
  setBlackListChats,
  setChatLoaders,
  setChatMedia,
  setChatMessages,
  setChatScrollState,
  setChats,
  setCurrentChat,
  setDirectSearchUsers,
  setGroupSearchUsers,
  setInviteAccepted,
  setInviteRejected,
  setLastCreatedChat,
  setMeetingSearchUsers,
  setMessageIsRead,
  setMessageSendSuccess,
  setNewMessageInChat,
  setUnreadMessagesCnt,
  setUserIsOnline,
  triggerChatMessageInProtocol,
} from "shared/ReduxSlices/chatSlice/chatSlice";
import {
  ArchiveApiResponse,
  ChatCreatedMessage,
  ChatGetChatDto,
  ChatGetChatMessage,
  ClosedApiResponse,
  DirectCreateSearchApiResponse,
  GetArchivedChatsApiResponse,
  GetBlacklistChatsApiResponse,
  GetChatsApiResponse,
  GetMediaApiResponse,
  GetMessagesApiResponse,
  GetUnreadMessagesCntApiResponse,
  GroupCreateSearchApiResponse,
  GroupLeaveApiResponse,
  InProtocolApiResponse,
  IsDeliveredApiResponse,
  IsReadApiResponse,
  MeetingCloseApiResponse,
  MeetingCreateSearchApiResponse,
  MeetingInviteAcceptApiResponse,
  MessageSendApiResponse,
  OpenedApiResponse,
  UserBlacklistApiResponse,
} from "RTKQuery/types/chatApi";
import {chatUtils} from "./chatUtils";
import {CHAT_TYPES} from "./chatConstants";
import {TInvitationRequestChat} from "shared/ReduxSlices/chatSlice/types";

// eslint-disable-next-line no-undef
let IsReadTimer: NodeJS.Timeout;

export const chatEventsHandler = {
  // Распределение логики в зависимости от приходящего евента
  [ChatEvents.ChatService.GetChats]: (payload: GetChatsApiResponse) => {
    store.dispatch(setChats(payload.message));
  },
  [ChatEvents.ChatService.GetChat]: (payload: ChatGetChatMessage) => {
    // получение информации по одному чату
    // используется при получении нового сообщения из незагруженных чатов
    store.dispatch(prependChats(payload.message));
  },
  [ChatEvents.ChatService.GetUnreadMessagesCnt]: (
    payload: GetUnreadMessagesCntApiResponse,
  ) => {
    store.dispatch(setUnreadMessagesCnt(payload.message));
  },
  // получение архивных чатов
  [ChatEvents.ChatService.GetArchivedChats]: (payload: GetArchivedChatsApiResponse) => {
    store.dispatch(setArchivedChats(payload.message));
  },
  // обновление списка архивных чатов, после его изменения
  [ChatEvents.ChatService.Archive]: (payload: ArchiveApiResponse) => {
    return;
    // возвращает ничего в ответе payload.message пустой объект
    // chatUtils.bsSend({}, ChatEvents.ChatService.GetArchivedChats);
    // chatUtils.bsSend({}, ChatEvents.ChatService.GetChats);
  },
  // получение чатов из черного списка
  [ChatEvents.ChatService.GetBlackListChats]: (payload: GetBlacklistChatsApiResponse) => {
    store.dispatch(setBlackListChats(payload.message));
  },
  // обновление списка чатов из черного списка, после его изменения
  [ChatEvents.ChatService.UserBlacklist]: (payload: UserBlacklistApiResponse) => {
    return;
    // возвращает ничего в ответе payload.message пустой объект
    // chatUtils.bsSend({}, ChatEvents.ChatService.GetBlackListChats);
    // chatUtils.bsSend({}, ChatEvents.ChatService.GetChats);
  },
  [ChatEvents.ChatService.GetMessages]: (payload: GetMessagesApiResponse) => {
    // console.log(store.getState().chatSlice.loaders.GetMessages);
    store.dispatch(setChatMessages(payload.message));
  },
  [ChatEvents.ChatService.GetMedia]: (payload: GetMediaApiResponse) => {
    store.dispatch(setChatMedia(payload.message));
  },
  [ChatEvents.ChatService.SendSuccess]: (payload: MessageSendApiResponse) => {
    store.dispatch(setMessageSendSuccess(payload.message));
    store.dispatch(setChatLoaders({[ChatEvents.ChatService.MessageSend]: false}));
  },
  [ChatEvents.ChatService.MessageReceived]: (payload: MessageSendApiResponse) => {
    chatUtils.playNewMessageJingle();
    // ищем чат среди загруженных
    const chat = store
      .getState()
      .chatSlice.GetChats.chats.find(({id}) => id === payload.message.chatId);
    if (!chat) {
      // если ещё не загружен, то запрашиваем инфу по нему и поднимаем в списке
      const reqBody: ChatGetChatDto = {chatId: payload.message.chatId};
      chatUtils.bsSend(reqBody, ChatEvents.ChatService.GetChat);
    } else {
      // если уже загружен, то поднимаем в списке
      store.dispatch(setNewMessageInChat(payload.message));
    }
    store.dispatch(
      setUnreadMessagesCnt({
        unreadMessagesCnt: store.getState().chatSlice.GetUnreadMessagesCnt + 1,
      }),
    );
  },
  // [ChatEvents.ChatService.Typing]: (payload: TypingApiResponse) => {
  //   store.dispatch(setTypingInChat(payload.message));
  // },
  [ChatEvents.ChatService.ChatOnUsers]: (state = {}, payload = {}) => {
    console.log({ChatOnUsers: payload});
    // const users = payload.message || [];
    // const eventShort = ChatEvents.ChatService.ChatOnUsers;
    // state[eventShort] = Object.assign({}, ...users.map((x) => ({[x.id]: {...x}})));
  },
  [ChatEvents.ChatService.UserIsOnline]: (payload: OpenedApiResponse) => {
    store.dispatch(setUserIsOnline({user: payload.message, online: true}));
  },
  [ChatEvents.ChatService.UserIsOffline]: (payload: ClosedApiResponse) => {
    store.dispatch(setUserIsOnline({user: payload.message, online: false}));
  },
  [ChatEvents.ChatService.DirectSearch]: (payload: DirectCreateSearchApiResponse) => {
    store.dispatch(setDirectSearchUsers(payload.message));
  },
  [ChatEvents.ChatService.GroupSearch]: (payload: GroupCreateSearchApiResponse) => {
    store.dispatch(setGroupSearchUsers(payload.message));
  },
  [ChatEvents.ChatService.MeetingSearch]: (payload: MeetingCreateSearchApiResponse) => {
    store.dispatch(setMeetingSearchUsers(payload.message));
  },
  [ChatEvents.ChatService.ChatCreated]: (payload: ChatCreatedMessage) => {
    // chatUtils.bsSend({}, ChatEvents.ChatService.GetChats);
    store.dispatch(prependChats(payload.message));
    const type = payload.message?.chat?.type;
    if (type === CHAT_TYPES.GROUP) {
      store.dispatch(setChatLoaders({[ChatEvents.ChatService.GroupCreate]: false}));
    } else if (type === CHAT_TYPES.DIRECT) {
      store.dispatch(setLastCreatedChat(payload.message));
      store.dispatch(setChatLoaders({[ChatEvents.ChatService.DirectCreate]: false}));
    } else if (type === CHAT_TYPES.MEETING) {
      store.dispatch(setChatLoaders({[ChatEvents.ChatService.MeetingCreate]: false}));
    }
  },
  [ChatEvents.ChatService.IsRead]: (payload: IsReadApiResponse) => {
    store.dispatch(setMessageIsRead(payload.message));
    clearTimeout(IsReadTimer);
    IsReadTimer = setTimeout(() => {
      chatUtils.bsSend({}, ChatEvents.ChatService.GetUnreadMessagesCnt);
    }, 10000);
  },
  [ChatEvents.ChatService.MessageSend]: (payload: IsReadApiResponse) => {
    store.dispatch(setChatScrollState(true));
  },
  [ChatEvents.ChatService.IsDelivered]: (payload: IsDeliveredApiResponse) => {
    store.dispatch(setMessageSendSuccess(payload.message));
  },
  // [ChatEvents.ChatService.UserBlacklist]: (state = {}, payload = {}) => {
  //   const chat = state.GetChats?.find((x) => payload.message?.chatId === x.id);
  //   if (payload.rid === -1) {
  //     chat.meInBlackList = payload.message?.value;
  //   }
  // },
  /** получение приглашения в чат */
  [ChatEvents.ChatService.InvitationRequest]: (payload: TInvitationRequestChat) => {
    chatUtils.playNewMessageJingle();
    store.dispatch(prependChats(payload.message));
    chatUtils.bsSend({}, ChatEvents.ChatService.GetUnreadMessagesCnt);
  },
  /** принял приглашение в чат */
  [ChatEvents.ChatService.InviteAccepted]: (payload: MeetingInviteAcceptApiResponse) => {
    store.dispatch(setInviteAccepted(payload.message));
  },
  /** отклонил приглашение в чат */
  [ChatEvents.ChatService.InviteRejected]: (payload: MeetingInviteAcceptApiResponse) => {
    store.dispatch(setInviteRejected(payload.message));
    store.dispatch(setCurrentChat(undefined));
  },
  // [ChatEvents.ChatService.InviteRejected]: (state = {}, payload = {}) => {
  //   const chats = ChatEvents.ChatService.GetChats;
  //   const chatId = payload.message.chatId;
  //   const rid = payload.rid;
  //   if (rid >= 0) {
  //     const chatIndex = state[chats].findIndex((x) => x.id === chatId);
  //     state[chats].splice(chatIndex, 1);
  //     state.currentChat = -1;
  //     let unread = state.GetUnreadMessagesCnt;
  //     unread.unreadMessagesCnt > 0 && --unread.unreadMessagesCnt;
  //   }
  // },
  // [ChatEvents.ChatService.InviteAccepted]: (state = {}, payload = {}) => {
  //   const chats = ChatEvents.ChatService.GetChats;
  //   const chatId = payload.message.chatId;
  //   const chat = findChatByID(state[chats], chatId);
  //   chat?.unreadMessagesCnt && chat.unreadMessagesCnt--;

  //   if (chat.type === CHAT_TYPES.GROUP) {
  //     state.loaders[ChatEvents.ChatService.GroupInviteAccept] = false;
  //   }
  //   if (chat.type === CHAT_TYPES.DIRECT) {
  //     state.loaders[ChatEvents.ChatService.DirectInviteAccept] = false;
  //   }
  //   if (chat.type === CHAT_TYPES.MEETING) {
  //     state.loaders[ChatEvents.ChatService.MeetingInviteAccept] = false;
  //   }

  //   Object.assign(chat || {}, {invitationState: INVITATION_STATE.ACCEPT});
  // },

  /** слушатель успешного добавления сообщения в протокол переговоров */
  [ChatEvents.ChatService.InProtocolSuccess]: (payload: InProtocolApiResponse) => {
    store.dispatch(setChatLoaders({[ChatEvents.ChatService.InProtocol]: false}));
    store.dispatch(triggerChatMessageInProtocol(payload.message));
  },
  /** слушатель успешного завершения переговоров */
  [ChatEvents.ChatService.MeetingClosed]: (payload: MeetingCloseApiResponse) => {
    store.dispatch(setChatLoaders({[ChatEvents.ChatService.MeetingClose]: false}));
    // chatUtils.bsSend({}, ChatEvents.ChatService.GetChats);
  },
  // [ChatEvents.ChatService.ProtocolCreated]: (state = {}, payload = {}) => {
  //   const chatId = payload.message.chatId;
  //   const chat = findChatByID(state.GetChats, chatId);
  //   chat.protocol = payload.message.protocol;
  // },
  // [ChatEvents.ChatService.ConnectionAccepted]: (state = {}, payload = {}) => {
  //   state.userId = payload.userId;
  // },
  // [ChatEvents.ChatService.ChatGroupAddUsersSuccess]: (state = {}, payload = {}) => {
  //   state.typeCreateState = "";
  // setNewMessageInChat
  // TODO добавить обработчик добавления нового пользователя в чат
  // },
  [ChatEvents.ChatService.ChatDeleted]: () => {
    // chatUtils.bsSend({}, ChatEvents.ChatService.GetChats);
  },
  [ChatEvents.ChatService.ChatLeaved]: (payload: GroupLeaveApiResponse) => {
    // chatUtils.bsSend({}, ChatEvents.ChatService.GetChats);
  },
};

// function deleteChat(state, chatId) {
//   state.GetChats = state.GetChats?.filter((x) => x.id !== chatId);
//   state.currentChat = -1;
// }

// todo добавить обработчики валидационных сообщений пример:
// Ответ ws:
// event: "ChatService.DirectCreate"
// message: {
//   text: ['chat', 'lastMessage', 'avatar']: "field required",
//   traceback: "Traceback (most recent call last):\n  File \"/app/src/mediator/",
//   type: "ValidationError"
// }
// rid: 7
// statusCode: 422
