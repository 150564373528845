import { useState} from "react";

/**
 * Используется для управления контекста в провайдере ModalWrapper
 */
export const useModal = () => {
  const [open, setOpen] = useState(false);
  const [titleText, setTitleText] = useState("");

  const handleShowModal = (title = "") => {
    setOpen(true);
    setTitleText(title);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return {
    open,
    handleShowModal,
    titleText,
    handleClose,
  };
};
