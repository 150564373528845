import {ThemeProvider} from "@mui/material/styles";
import {THEMES} from "../lib/themes";
import {ComponentType, ReactNode} from "react";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import ru from "date-fns/locale/ru";
import {ruRU} from "@mui/x-date-pickers/locales";
import CssBaseline from "@mui/material/CssBaseline";
import {useAppColorThemeContext} from "shared/hooks/useAppColorThemeContext/useAppColorThemeContext";
import "shared/styles/index.scss";
import "animate.css";
import "highcharts/css/stocktools/gui.scss";
import "highcharts/css/annotations/popup.scss";

type Props = {
  children?: ReactNode;
};

function ThemeProviderApp({children}: Props) {
  const {colorMode} = useAppColorThemeContext();

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={ru}
      localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText}
    >
      <ThemeProvider theme={THEMES[colorMode]}>{children}</ThemeProvider>{" "}
    </LocalizationProvider>
  );
}

export default ThemeProviderApp;

export const WithThemeProvider = (Component: ComponentType) => () => {
  return (
    <ThemeProviderApp>
      <CssBaseline enableColorScheme />
      <Component />
    </ThemeProviderApp>
  );
};
