import Box from "@mui/material/Box";
import {AppWrapper} from "shared/components/AppWrapper/AppWrapper";

function SuspenseLoader() {
  return (
    <Box sx={{padding: "20px 0"}}>
      <AppWrapper>Идёт загрузка данных...</AppWrapper>
    </Box>
  );
}

export default SuspenseLoader;
