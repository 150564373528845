import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {feedbackService} from "shared/services/FeedbackService";
import {resetState} from "../../store/resetState";
import {initialState} from "./types";

export const helpdesk = createAsyncThunk("helpdesk", async (body, {rejectWithValue}) => {
  try {
    const {data} = await feedbackService.helpdesk(body);
    return data;
  } catch (e) {
    return rejectWithValue(e.response.data);
  }
});

export const head = createAsyncThunk("head", async (body, {rejectWithValue}) => {
  try {
    const {data} = await feedbackService.head(body);
    return data;
  } catch (e) {
    return rejectWithValue(e.response.data);
  }
});

export const feature = createAsyncThunk("feature", async (body, {rejectWithValue}) => {
  try {
    const {data} = await feedbackService.feature(body);
    return data;
  } catch (e) {
    return rejectWithValue(e.response.data);
  }
});

export const question = createAsyncThunk(
  "question",
  async ({body, onSuccess}, {rejectWithValue}) => {
    try {
      const {data} = await feedbackService.question(body);
      onSuccess(data.message);
      return data;
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  },
);
export const questionComplex = createAsyncThunk(
  "questionComplex",
  async ({body, onSuccess, onError}, {rejectWithValue}) => {
    try {
      const {data} = await feedbackService.questionComplex(body);
      onSuccess(data.message);
      return data;
    } catch (e) {
      if (typeof e.response.data?.detail === "object") {
        onError(e.response.data.detail);
      }
      return rejectWithValue(e.response.data);
    }
  },
);

export const feedbackSlice = createSlice({
  name: "feedbackSlice",
  initialState,
  reducers: {
    ...resetState,
    setIsVisibleNotification: (state, action) => {
      state.isVisibleNotification = action.payload;
    },
    clearErrorsQuestion: (state) => {
      state.dataError = {};
    },
  },
  extraReducers: (builder) =>
    builder
      //helpdesk
      .addCase(helpdesk.fulfilled, (state) => {
        state.isLoading = false;
        state.isVisibleNotification = true;
      })
      .addCase(helpdesk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(helpdesk.rejected, (state, action) => {
        state.isLoading = false;
        state.dataError = action.payload.detail;
        if (action.payload.detail.fio) {
          state.dataError.nameFIO = action.payload.detail.fio;
        }
        state.isVisibleNotification = false;
      })
      //head
      .addCase(head.fulfilled, (state) => {
        state.isLoading = false;
        state.isVisibleNotification = true;
      })
      .addCase(head.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(head.rejected, (state, action) => {
        state.isLoading = false;
        state.dataError = action.payload.detail;
        if (action.payload.detail.fio) {
          state.dataError.nameFIO = action.payload.detail.fio;
        }
        state.isVisibleNotification = false;
      })
      //feature
      .addCase(feature.fulfilled, (state) => {
        state.isLoading = false;
        state.isVisibleNotification = true;
      })
      .addCase(feature.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(feature.rejected, (state, action) => {
        state.isLoading = false;
        state.dataError = action.payload?.detail;
        if (action.payload.detail.fio) {
          state.dataError.nameFIO = action.payload.detail.fio;
        }
        state.isVisibleNotification = false;
      })
      //question
      .addCase(question.fulfilled, (state) => {
        state.isLoading = false;
        state.dataError = {};
      })
      .addCase(question.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(question.rejected, (state, action) => {
        state.isLoading = false;
        state.dataError = action.payload?.detail;
      })
      //questionComplex
      .addCase(questionComplex.fulfilled, (state) => {
        state.isLoadingQuestionComplex = false;
        state.dataError = {};
      })
      .addCase(questionComplex.pending, (state) => {
        state.isLoadingQuestionComplex = true;
      })
      .addCase(questionComplex.rejected, (state, action) => {
        state.isLoadingQuestionComplex = false;
        state.dataError = action.payload?.detail;
      }),
});

export const {toggleNotification, setIsVisibleNotification, clearErrorsQuestion} =
  feedbackSlice.actions;

export default feedbackSlice.reducer;
