import {HTTPHelper} from "../HTTPHelper/HTTPHelper";

let t;

export default class AuthHelper extends HTTPHelper {
  constructor() {
    super();
    this.setServerErrorCB = this.setServerErrorCB.bind(this);
  }

  setServerErrorCB(serverErrorCB) {
    // this.serverErrorCB = serverErrorCB;
  }

  process400(response) {
    return {detail: response.data?.detail};
  }

  process401(response, dispatch, cb) {
    if (response.refreshInProcess) {
      return {detail: response.data.detail};
    }

    return cb ? cb() : {detail: response.data?.detail};
  }

  process403(response) {
    return {detail: response.data?.detail};
  }

  process404(response) {
    return {detail: response.data?.detail};
  }

  process406(response) {
    // this.removeToken();
    return {detail: response.data?.detail};
  }

  process418(response) {
    return {detail: response.data?.detail, isNeedToConfirm: true};
  }

  process422(response) {
    return {detail: response.data?.detail};
  }

  // setToken(accessToken) {
  //   t = accessToken;
  //   BroadcastChannelService.handleBroadcastLogin({login: t});
  //   br.onmessage = (e) => {
  //     e.data.getToken && br.postMessage({token: t});
  //   };
  // }

  setExpiresDate(seconds) {
    const ts = new Date().getTime() + seconds * 1000;
    localStorage.setItem("expiresDate", ts + "");
  }

  removeToken = () => {
    t = null;
    localStorage.setItem("auth", "false");
  };

  getToken = () => t;

  handleRejectedResponse(state, action) {
    state.isLoading = false;
    if (action.payload?.isNeedToConfirm) {
      state.isNeedToConfirm = action.payload.isNeedToConfirm;
    }

    state.dataError = action.payload?.detail;
  }
}

export const authHelper = new AuthHelper();
