import {ComponentType, FC, ReactNode} from "react";
import {LOCAL_STORAGE_THEME_KEY, themes} from "shared/constants/themes";
import {useColorMode} from "shared/hooks/useColorMode/useColorMode";
import {ColorAppContext} from "shared/utils/context/ColorAppContext";

interface AppProviderProps {
  children: ReactNode;
}
const defaultTheme =
  (localStorage.getItem(LOCAL_STORAGE_THEME_KEY) as any) || themes.LIGHT;

const ColorAppProvider: FC<AppProviderProps> = ({children}) => {
  // управление переключением цветовой темы приложения
  const {colorMode, setColorMode} = useColorMode(defaultTheme);

  return (
    <ColorAppContext.Provider value={{colorMode, setColorMode}}>
      {children}
    </ColorAppContext.Provider>
  );
};

export {ColorAppProvider};

export const WithColorThemeProvider = (Component: ComponentType) => () => {
  return (
    <ColorAppProvider>
      <Component />
    </ColorAppProvider>
  );
};
