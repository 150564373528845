import {matchRoutes, useLocation} from "react-router-dom";
import {createAppConfig} from "shared/utils/createAppConfig/createAppConfig";

/**
 * получить path из ROUTES с которым матчится текущий path
 */
export const useAppCurrentPath = (prefix = "", basename = ""): string => {
  const location = useLocation();
  const pathMatches = matchRoutes(createAppConfig(prefix), location, basename);

  if (!pathMatches) {
    return "*";
  }
  const {route} = pathMatches[0];

  return route?.path || "*";
};
