import {PaletteOptions} from "@mui/material/styles";

declare module "@mui/material/styles/createPalette" {
  interface Palette {
    border: Palette["primary"];
    white: Palette["primary"];
    primary700: Palette["primary"];
    primary300: Palette["primary"];
    primary100: Palette["primary"];
    primary50: Palette["primary"];
    black: Palette["primary"];
    black800: Palette["primary"];
    black500: Palette["primary"];
    black400: Palette["primary"];
    black300: Palette["primary"];
    black200: Palette["primary"];
    black100: Palette["primary"];
    green: Palette["primary"];
    green600: Palette["primary"];
    red: Palette["primary"];
    red800: Palette["primary"];
    red600: Palette["primary"];
    red500: Palette["primary"];
    red400: Palette["primary"];
    red100: Palette["primary"];
    orange: Palette["primary"];
    orange600: Palette["primary"];
    yellow: Palette["primary"];
    yellow600: Palette["primary"];
    backgroundBlock: Palette["background"] & {
      secondary: string;
      card: string;
      top: string;
      inputDisabled: string;
    };
    tableBodyBorder: Palette["primary"];
    purple: Palette["primary"];
  }
  interface PaletteOptions {
    border: PaletteOptions["primary"];
    white: PaletteOptions["primary"];
    primary700: PaletteOptions["primary"];
    primary300: PaletteOptions["primary"];
    primary100: PaletteOptions["primary"];
    primary50: PaletteOptions["primary"];
    black: PaletteOptions["primary"];
    black800: PaletteOptions["primary"];
    black500: PaletteOptions["primary"];
    black400: PaletteOptions["primary"];
    black300: PaletteOptions["primary"];
    black200: PaletteOptions["primary"];
    black100: PaletteOptions["primary"];
    green: PaletteOptions["primary"];
    green600: PaletteOptions["primary"];
    red: PaletteOptions["primary"];
    red800: PaletteOptions["primary"];
    red600: PaletteOptions["primary"];
    red500: PaletteOptions["primary"];
    red400: PaletteOptions["primary"];
    red100: PaletteOptions["primary"];
    orange: PaletteOptions["primary"];
    orange600: PaletteOptions["primary"];
    yellow: PaletteOptions["primary"];
    yellow600: PaletteOptions["primary"];
    backgroundBlock: PaletteOptions["background"] & {
      secondary: string;
      card: string;
      top: string;
      inputDisabled: string;
    };
    tableBodyBorder: PaletteOptions["primary"];
    purple: PaletteOptions["primary"];
  }
}
declare module "@mui/material/Chip" {
  export interface ChipPropsColorOverrides {
    border: true;
    white: true;
    primary700: true;
    primary300: true;
    primary100: true;
    primary50: true;
    black: true;
    black800: true;
    black500: true;
    black400: true;
    black300: true;
    black200: true;
    black100: true;
    green: true;
    green600: true;
    red: true;
    red800: true;
    red600: true;
    red500: true;
    red400: true;
    red100: true;
    orange: true;
    orange600: true;
    yellow: true;
    yellow600: true;
    backgroundBlock: true;
    tableBodyBorder: true;
    purple: true;
  }
}
const _orange = "#FBA943";
const _red = "#F95F5F";
export const palette = {
  primary: {
    main: "#31ACB8",
  },
  primary700: {
    main: "#15C2D2",
  },
  primary300: {
    main: "#CBEAED",
  },
  primary100: {
    main: "#EAF7F8",
  },
  primary50: {
    main: "#f7fcfc",
  },
  border: {
    main: "#CDCDCC",
  },
  white: {
    main: "#FFF",
  },
  black: {
    main: "#373735",
  },
  blackStatic: {
    main: "black",
  },
  black800: {
    main: "#5f5f5d",
  },
  black500: {
    main: "#9B9B9A",
  },
  black400: {
    main: "rgba(155, 155, 154, 0.4)",
  },
  black300: {
    main: "#d7d7d7",
  },
  black200: {
    main: "#F4F4F4",
  },
  black100: {
    main: "#F9F9F9",
  },
  green: {
    main: "#24B894",
  },
  green600: {
    main: "#E1F6EF",
  },
  red: {
    main: _red,
  },
  red800: {
    main: "#EF4242",
  },
  red600: {
    main: "#FFCFD4",
  },
  red500: {
    main: "#FDE1E4",
  },
  red400: {
    main: "#fde1e466",
  },
  red100: {
    main: "#FFF4F6",
  },
  orange: {
    main: _orange,
  },
  orange600: {
    main: "#FFF3E4",
  },
  yellow: {
    main: "#F8E749",
  },
  yellow600: {
    main: "#FFF9F2",
  },
  warning: {
    main: _orange,
  },
  error: {
    main: _red,
  },
  text: {
    primary: "#373735",
    secondary: "#9B9B9A",
    disabled: "rgba(0, 0, 0, 0.8)",
  },
  background: {
    default: "#fff",
    paper: "#fff",
  },
  backgroundBlock: {
    default: "#fff",
    paper: "#F4F4F4",
    secondary: "#EDF0F7",
    card: "#fff",
    top: "#012D4F",
    inputDisabled: "#C5C5C5",
  },
  action: {
    active: "rgba(0, 0, 0, 0.54)",
    hover: "rgba(0, 0, 0, 0.04)",
    selected: "rgba(0, 0, 0, 0.08)",
    disabled: "rgba(0, 0, 0, 0.26)",
    disabledBackground: "rgba(0, 0, 0, 0.12)",
  },
  tableBodyBorder: {
    main: "#d7d7d7",
  },
  purple: {
    main: "#be2882",
  },
} as const;

const defaultColors = [
  "text",
  "background",
  "action",
  "backgroundBlock",
  "tableBodyBorder",
  "textExtra",
];
export const darkPalete = Object.entries(palette).reduce(
  (res, [k, v]) => {
    if (defaultColors.includes(k)) {
      return res;
    }

    res[k] = {};

    if ("dark" in v) {
      res[k].main = v.dark;
    } else {
      res[k] = v;
    }
    return res;
  },
  {
    text: {
      primary: "#fff",
      secondary: "#9B9B9A",
      disabled: "rgba(255, 255, 255, 0.5)",
    },
    background: {
      default: "#21262B",
      paper: "#222",
    },
    backgroundBlock: {
      default: "#2E363E",
      paper: "#37434F",
      secondary: "#37434F",
      card: "#2e363e",
      top: "#012D4F",
      inputDisabled: "#C5C5C5",
    },
    action: {
      active: "#fff",
      hover: "rgba(255, 255, 255, 0.08)",
      selected: "rgba(255, 255, 255, 0.16)",
      disabled: "rgba(255, 255, 255, 0.3)",
      disabledBackground: "rgba(255, 255, 255, 0.12)",
    },
    tableBodyBorder: {
      main: "#CDCDCC",
    },
  } as unknown as PaletteOptions,
);
