import {UserInfo} from "RTKQuery/types/userApi";
import {useAppSelector} from "../useAppSelector";
import {rolePermissions} from "../../configs/authPermissions/commonPermissionsConfig";
import {PROTECTED_ROUTES_CONFIG} from "shared/configs/authPermissions/routesAuthPermissions.config";
import {ROUTES} from "shared/constants/paths";
import {CommonPermissionActions} from "../../constants/userActionsForCheck";

type TTarget =
  | (typeof ROUTES)[keyof typeof ROUTES]
  | (typeof CommonPermissionActions)[keyof typeof CommonPermissionActions];

interface ICommand {
  type: "action" | "routes";
  target: TTarget;
}

/**
 * Есть ли доступ у пользователя по текущим ролям на выполнение команды
 * @param command  проверяемая команда (роут или действие)
 * @returns
 */
export const useCommonPermission = (command: ICommand): boolean => {
  const userData = useAppSelector((s) => s.UserRegisterSlice.userData);

  return userData ? _getPermission(userData.permissions, command) : false;
};

/**
 * Есть ли разрешение на данную команду у роли
 */
function _getPermission(
  permissions: UserInfo["permissions"],
  command: ICommand,
): boolean {
  const {target, type} = command;
  if (!permissions) return false;
  if (type === "action") {
    return permissions.some((permission) => {
      if (rolePermissions[permission]) {
        return rolePermissions[permission].includes(target);
      }
      return false;
    });
  }
  if (type === "routes") {
    const route = Object.values(PROTECTED_ROUTES_CONFIG).find(
      ({path}) => target === path,
    );
    if (route) {
      return route.permissionNeed
        ? permissions.some((permission) => {
            if (typeof route.permissionNeed === "string") {
              return route.permissionNeed === permission;
            }
            return route.permissionNeed?.includes(permission as any);
          })
        : // роут не требует пермишенов
          true;
    }
  }
  // роут не найден
  return false;
}
