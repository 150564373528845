import axios, {AxiosInstance} from "axios";
import {API_URL} from "shared/constants/API";
import {logBackendErrors} from "shared/services/errorLoggerService/errorLoggerService";
import {store} from "shared/store/store";

let instance: AxiosInstance | undefined;

// Функция создания экземпляра
const createAxiosInstance = (): AxiosInstance => {
  const inst = axios.create({
    baseURL: API_URL,
    withCredentials: true,
  });

  // token && addInterceptor(inst);
  addInterceptor(inst);

  return inst;
};

export const axiosGlobal = (): AxiosInstance => {
  if (instance) {
    return instance;
  }
  instance = createAxiosInstance();
  return instance;
};

let isRefreshing = false;
let failedQueue: (() => void)[] = [];

const processQueue = () => {
  failedQueue.forEach((callback) => callback());
  failedQueue = [];
};

const addInterceptor = (instance: AxiosInstance) => {
  instance.interceptors.response.use(
    (res) => res,
    async (error) => {
      const originalRequest = error.config;

      if (
        error.response.status > 399 &&
        error.response.status !== 406 &&
        process.env.NODE_ENV === "production"
      ) {
        const userId = store.getState()?.UserRegisterSlice?.userData?.id || "";
        logBackendErrors({
          data: {response: error.response, originalRequest},
          userId,
        });
      }

      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        if (!isRefreshing) {
          isRefreshing = true;

          try {
            processQueue();
          } catch (e) {
            console.log("Не удалось обновить токен");
          } finally {
            isRefreshing = false;
          }
        }

        return new Promise((resolve, reject) => {
          failedQueue.push(() => {
            instance.request(originalRequest).then(resolve).catch(reject);
          });
        });
      }

      return Promise.reject(error);
    },
  );
};
