import { ChatEvents } from "./ChatEvents";

export const CHAT_TYPES = {
  GROUP: "GROUP",
  DIRECT: "DIRECT",
  MEETING: "MEETING",
  // ADD_USER_GROUP: "ADD_USER_GROUP",
} as const;

/**
 * тип списков чата (обычный / архивный / черный список)
 */
export const CHAT_MENU_TYPES = {
  COMMON: "COMMON",
  ARCHIVE: "ARCHIVE",
  BLACKLIST: "BLACKLIST",
} as const;

/**
 * тип окна переписки с пользователем (список сообщений / список участников / список файлов из чата)
 */
export const CHAT_WITH_USER_TYPES = {
  MESSAGES_LIST: "MESSAGES_LIST",
  USERS_LIST: "USERS_LIST",
  FILE_LIST: "FILE_LIST",
} as const;

export const CHAT_ADMIN = {
  operator: "Оператор площадки",
  tech: "Техническая поддержка",
} as const;

export const INVITATION_STATE = {
  REQUEST: "REQUEST",
  ACCEPT: "ACCEPT",
} as const;

export const initialChatEvents = [
  ChatEvents.ChatService.GetUnreadMessagesCnt,
  ChatEvents.ChatService.GetChats,
];
