import {useEffect} from "react";
import {useAppSelector} from "shared/hooks/useAppSelector";
import {ChatEvents} from "shared/utils/chatUtils/ChatEvents";
import {TCreateDirectChat} from "shared/ReduxSlices/chatSlice/types";
import {chatUtils} from "shared/utils/chatUtils/chatUtils";
import {CHAT_TYPES} from "shared/constants/chat";
import {useAppDispatch} from "shared/hooks/useAppDispatch";
import {
  setCurrentChat,
  setCurrentChatMenu,
  setLastCreatedChat,
  setOpenChat,
} from "shared/ReduxSlices/chatSlice/chatSlice";

type TProps = {
  onClose?: () => void;
};

/**
 * Хук для открытия чата с пользователем
 * @param onClose для закрытия модального окна если хук вызывается из модального окна
 */

export const useOpenChatWithUser = ({
  onClose = () => {
    return;
  },
}: TProps) => {
  const dispatch = useAppDispatch();
  const {GetChats, GetArchivedChats, GetBlackListChats, lastCreatedChat, loaders} =
    useAppSelector((state) => state.chatSlice);

  // открыть чат с пользователем
  const onOpenChatWithUser = ({userId}: {userId: string}) => {
    const currentChat =
      GetChats.chats.find(
        (chat) => chat.type === "DIRECT" && chat.users.includes(userId),
      ) ||
      GetArchivedChats?.chats.find(
        (chat) => chat.type === "DIRECT" && chat.users.includes(userId),
      ) ||
      GetBlackListChats?.chats.find(
        (chat) => chat.type === "DIRECT" && chat.users.includes(userId),
      );
    if (currentChat) {
      dispatch(setOpenChat(true));
      dispatch(setCurrentChat(currentChat));
      dispatch(setCurrentChatMenu("MESSAGES_LIST"));
      onClose();
    } else {
      const data: TCreateDirectChat = {chat: {type: CHAT_TYPES.DIRECT}, users: [userId]};
      chatUtils.bsSend(data, ChatEvents.ChatService.DirectCreate);
    }
  };

  useEffect(() => {
    if (lastCreatedChat) {
      dispatch(setOpenChat(true));
      dispatch(setCurrentChat(lastCreatedChat));
      dispatch(setCurrentChatMenu("MESSAGES_LIST"));
      dispatch(setLastCreatedChat(undefined));
      onClose();
    }
  }, [lastCreatedChat]);

  return {onOpenChatWithUser, isLoading: !!loaders[ChatEvents.ChatService.DirectCreate]};
};
