import {Components} from "@mui/material/styles";

export const muiInputLabel: Components["MuiInputLabel"] = {
  variants: [
    {
      props: {variant: "outlined"},
      style: {
        margin: "3px",
        fontSize: 13,
        fontWeight: 500,
        position: "static",
        transform: "none",
        maxWidth: "100%",
        lineHeight: "110%",
      },
    },
  ],
};
