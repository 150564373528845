import {api} from "RTKQuery/api";
import {
  AuthV2LoginPostApiArg,
  AuthV2LoginPostApiResponse,
  AuthV2LogoutGetApiArg,
  AuthV2LogoutGetApiResponse,
  AuthV2OneTimeTokenGetApiArg,
  AuthV2OneTimeTokenGetApiResponse,
  AuthV2UsersExistsGetApiArg,
  AuthV2UsersExistsGetApiResponse,
  AuthV2UsersForgotPasswordGetApiArg,
  AuthV2UsersForgotPasswordGetApiResponse,
  AuthV2UsersForgotPasswordPostApiArg,
  AuthV2UsersForgotPasswordPostApiResponse,
  AuthV2UsersRegisterConfirmationGetApiArg,
  AuthV2UsersRegisterConfirmationGetApiResponse,
  AuthV2UsersRegisterPostApiArg,
  AuthV2UsersRegisterPostApiResponse,
  AuthV2UsersSendConfirmationCodeAgainGetApiArg,
  AuthV2UsersSendConfirmationCodeAgainGetApiResponse,
} from "RTKQuery/types/authApi";

export const authCustomApi = api.injectEndpoints({
  endpoints: (build) => ({
    authV2LogoutGet: build.mutation<AuthV2LogoutGetApiResponse, AuthV2LogoutGetApiArg>({
      query: () => ({url: `/auth/v2/logout`, method: "GET"}),
      invalidatesTags: (_, err) => (err ? [] : ["user"]),
    }),
    authV2UsersRegisterConfirmationGet: build.query<
      AuthV2UsersRegisterConfirmationGetApiResponse,
      AuthV2UsersRegisterConfirmationGetApiArg
    >({
      query: (queryArg) => ({
        url: `/auth/v2/users/register-confirmation`,
        params: {code: queryArg.code, email: queryArg.email},
      }),
    }),
    authV2UsersSendConfirmationCodeAgainGet: build.query<
      AuthV2UsersSendConfirmationCodeAgainGetApiResponse,
      AuthV2UsersSendConfirmationCodeAgainGetApiArg
    >({
      query: (queryArg) => ({
        url: `/auth/v2/users/send-confirmation-code-again`,
        params: {email: queryArg.email},
      }),
    }),
    authV2UsersRegisterPost: build.mutation<
      AuthV2UsersRegisterPostApiResponse,
      AuthV2UsersRegisterPostApiArg
    >({
      query: (queryArg) => ({
        url: `/auth/v2/users/register`,
        method: "POST",
        body: queryArg.registerRequest,
      }),
    }),
    authV2UsersExistsGet: build.query<
      AuthV2UsersExistsGetApiResponse,
      AuthV2UsersExistsGetApiArg
    >({
      query: (queryArg) => ({
        url: `/auth/v2/users/exists`,
        params: {fieldName: queryArg.fieldName, fieldValue: queryArg.fieldValue},
      }),
    }),
    authV2UsersForgotPasswordGet: build.query<
      AuthV2UsersForgotPasswordGetApiResponse,
      AuthV2UsersForgotPasswordGetApiArg
    >({
      query: (queryArg) => ({
        url: `/auth/v2/users/forgot-password`,
        params: {email: queryArg.email},
      }),
    }),
    authV2UsersForgotPasswordPost: build.mutation<
      AuthV2UsersForgotPasswordPostApiResponse,
      AuthV2UsersForgotPasswordPostApiArg
    >({
      query: (queryArg) => ({
        url: `/auth/v2/users/forgot-password`,
        method: "POST",
        body: queryArg.forgotPasswordRequestSchema,
        params: {code: queryArg.code, email: queryArg.email},
      }),
    }),
    authV2LoginPost: build.mutation<AuthV2LoginPostApiResponse, AuthV2LoginPostApiArg>({
      query: (queryArg) => ({
        url: `/auth/v2/login`,
        method: "POST",
        body: queryArg.loginRequestSchema,
      }),
    }),
    authV2OneTimeTokenGet: build.mutation<
      AuthV2OneTimeTokenGetApiResponse,
      AuthV2OneTimeTokenGetApiArg
    >({
      query: () => ({url: `/auth/v2/one-time-token`}),
    }),
  }),
  overrideExisting: true,
});
