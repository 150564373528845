export const NotificationsEvents = {
  NotificationServiceEvent: "NotificationService.",
  NotificationService: {
    GetNotifications: "GetNotifications",
    GetUnreadNotificationsCount: "GetUnreadNotificationsCount",
    MarksIsRead: "MarksIsRead",
    MarkIsRead: "MarkIsRead",
    GetSubscriptionsSettings: "GetSubscriptionsSettings",
    SetSubscription: "SetSubscription",
    SetSubscriptions: "SetSubscriptions",
    DeleteNotification: "DeleteNotification",
    DeleteNotifications: "DeleteNotifications",
    NewNotification: "NewNotification",
  },
};
export const processGeneralEvents = [
  NotificationsEvents.NotificationService.NewNotification,
];
