import {Palette, Theme} from "@mui/material/styles";
import {SystemStyleObject} from "@mui/system";

type TOptions = {
  width: string;
  borderRadius: string;
  colorThumb: string;
  colorTrack: string;
  colorScrollbar: string;
};

export const renderScrollStyle = (
  palette: Palette,
  options?: TOptions,
): SystemStyleObject<Theme> => ({
  scrollbarColor: "auto",
  "::-webkit-scrollbar": {
    width: options?.width || "2px",
    height: options?.width || "2px",
    borderRadius: options?.borderRadius || "8px",
    backgroundColor: options?.colorScrollbar || palette.black200.main,
  },

  "::-webkit-scrollbar-track": {
    backgroundColor: options?.colorTrack || palette.black200.main,
  },
  "::-webkit-scrollbar-thumb": {
    borderRadius: options?.borderRadius || "8px",
    backgroundColor: options?.colorThumb || palette.black500.main,
    opacity: 0.4,
    cursor: "grab",
  },
});
