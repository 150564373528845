import {
  PROTECTED_ROUTES_CONFIG,
  TProtectedRoute,
} from "shared/configs/authPermissions/routesAuthPermissions.config";

export const useAppProtectedRoute = (matchedRoutePattern: string): TProtectedRoute => {
  const route = Object.values(PROTECTED_ROUTES_CONFIG).find(
    ({path}) => path === matchedRoutePattern,
  );

  return {type: route?.type || "public"};
};
