import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {API_URL} from "shared/constants/API";
import queryString from "query-string";
import {tagTypes_SRM} from "shared/constants/apiTags";
import type {BaseQueryFn, FetchArgs, FetchBaseQueryError} from "@reduxjs/toolkit/query";
import {on401, on406} from "shared/ReduxSlices/bcSlice/bcSlice";

export const sharedAppsTags = ["tickets", "ticketDetail", "user"] as const;

const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  credentials: "include",
  paramsSerializer(params) {
    return queryString.stringify(params, {arrayFormat: "bracket"});
  },
});

const baseQueryWithIntercept: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error) {
    console.warn("---===!!!result.error!!!===---", result.error);
  }
  if (result.error && result.error.status === 401) {
    console.warn("---===!!!401 code!!!===---");
    api.dispatch(on401());
  }
  if (result.error && result.error.status === 406) {
    console.warn("---===!!!406 code!!!===---");
    api.dispatch(on406());
  }
  return result;
};

export const api = createApi({
  reducerPath: "api",
  tagTypes: [...sharedAppsTags, ...tagTypes_SRM],
  baseQuery: baseQueryWithIntercept,

  endpoints: (b) => ({}),
});
