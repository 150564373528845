import {lazy} from "react";
import {RouteObject} from "react-router-dom";
import App from "../../../App";
import {ROUTES} from "shared/constants/paths";
import {ErrorRouteLoader} from "shared/components/ErrorRouteLoader";
import {publicRoutes} from "./(public)/publicRoutes";
import {privateRoutes} from "./(private)/privateRoutes";
import {authRoutes} from "./(auth)/authRoutes";

const Error404 = lazy(() => import("pages/Error404/Error404"));

export const APP_ROUTES: RouteObject[] = [
  {
    path: ROUTES.HOME,
    element: <App />,
    errorElement: <ErrorRouteLoader />,
    // ErrorBoundary: ErrorRouteLoader,
    children: [
      ...publicRoutes,
      ...privateRoutes,
      ...authRoutes,
      {
        path: ROUTES[404],
        element: <Error404 />,
      },
    ],
  },
];
