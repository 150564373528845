export interface ITicketsState {
  isLoading: boolean;
  tikets: any[];
  dataError: any;
  totalCount: number;
  themes: any[];
  statuses: any[];
  currentService: string;
  currentTicket: any;
  filters: any;
  isDesc: boolean;
  prevCurrentPath: string;
  isLoadingPre: boolean;
  preTotalCount: any;
}
export const initialState = {
  isLoading: false,
  tikets: [],
  dataError: {},
  totalCount: 0,
  themes: [],
  statuses: [],
  currentService: "",
  currentTicket: {},
  filters: {},
  isDesc: false,
  prevCurrentPath: "",
  isLoadingPre: false,
  preTotalCount: null,
};
