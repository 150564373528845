import {RouteObject} from "react-router-dom";
import {APP_ROUTES_SRM} from "../routeConfigSRM";
import {ROUTES} from "shared/constants/paths";
import TestCryptoPro from "pages/__TestCryptoPro__";
import {lazyMinLoadTime} from "shared/hooks/useDebounceLoader";

const Main = lazyMinLoadTime(() => import("pages/Main/V3/Main"));
const Trade = lazyMinLoadTime(() => import("pages/Trade/Trade/Trade"));
const News = lazyMinLoadTime(() => import("pages/News/News"));
const NewsDetail = lazyMinLoadTime(() => import("pages/NewsDetail/NewsDetail"));
const SafeRegistry = lazyMinLoadTime(() => import("pages/SafeRegistry/SafeRegistry"));
const Catalog = lazyMinLoadTime(() => import("pages/Catalog/Catalog"));
const SRM = lazyMinLoadTime(() => import("pages/SRM"));
const FZ = lazyMinLoadTime(() => import("pages/FZ/FZ"));
const Bz = lazyMinLoadTime(() => import("pages/Bz/Bz"));
const Blog = lazyMinLoadTime(() => import("pages/Blog/Blog/Blog"));
const BlogId = lazyMinLoadTime(() => import("pages/Blog/BlogArticle/[...id]"));
const TradeDetail = lazyMinLoadTime(() => import("pages/Trade/TradeDetail/TradeDetail"));
const RegisterOfCompanies = lazyMinLoadTime(
  () => import("pages/RegistryOfCompanies/RegisterOfCompanies"),
);
const QuotesPage = lazyMinLoadTime(() => import("pages/Quotes"));
const ServicesPage = lazyMinLoadTime(() => import("pages/ServicesPage/ServicesPage"));
const Tariffs = lazyMinLoadTime(() => import("pages/Tariffs/Tariffs"));
const AboutUs = lazyMinLoadTime(() => import("pages/AboutUs/AboutUs"));
const SearchPage = lazyMinLoadTime(() => import("pages/SearchPage/SearchPage"));
const FAQ = lazyMinLoadTime(() => import("pages/FAQ/FAQ"));
const Safety = lazyMinLoadTime(() => import("pages/Safety/Safety"));
const Documents = lazyMinLoadTime(() => import("pages/Documents/Documents"));

export const publicRoutes: RouteObject[] = [
  {
    path: ROUTES.HOME,
    element: <Main />,
  },
  {
    path: ROUTES.NEWS,
    element: <News />,
  },
  {
    path: ROUTES.NEWS_DETAIL,
    element: <NewsDetail />,
  },
  {
    path: ROUTES.SAFE_DEAL,
    element: <SafeRegistry />,
  },

  {
    path: ROUTES.CATALOG,
    element: <Catalog />,
  },

  {
    path: ROUTES.SRM,
    element: <SRM />,
    children: APP_ROUTES_SRM,
    // loader: loaderSrmApp,
  },
  // {
  //   path: ROUTES.FZ_223,
  //   element: <FZ />,
  // },
  {
    path: ROUTES.BZ,
    element: <Bz />,
  },
  {
    path: ROUTES.BLOG,
    element: <Blog />,
  },
  {
    path: ROUTES.BLOG_ID,
    element: <BlogId />,
  },
  {
    path: ROUTES.TRADE,
    element: <Trade />,
  },
  {
    path: ROUTES.TRADE_DETAIL,
    element: <TradeDetail />,
  },
  {
    path: ROUTES.COMPANIES,
    element: <RegisterOfCompanies />,
  },
  {
    path: ROUTES.QUOTES,
    element: <QuotesPage />,
  },
  {
    path: ROUTES.SERVICES,
    element: <ServicesPage />,
  },
  {
    path: ROUTES.TARIFFS,
    element: <Tariffs />,
  },
  {
    path: ROUTES.ABOUT_US,
    element: <AboutUs />,
  },
  {
    path: ROUTES.SEARCH,
    element: <SearchPage />,
  },
  {
    path: ROUTES.FAQ,
    element: <FAQ />,
  },
  {
    path: ROUTES.SAFETY,
    element: <Safety />,
  },
  {
    path: ROUTES.TEST_CRYPTO_PRO,
    element: <TestCryptoPro />,
  },
  {
    path: ROUTES.DOCUMENTS,
    element: <Documents />,
  },
];
