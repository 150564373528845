import {store} from "shared/store/store";
import {BcService} from "shared/services/BroadcastChannelService";
import {ChatEvents} from "./ChatEvents";
import {TChatMenuType, TChatUser} from "shared/ReduxSlices/chatSlice/types";
import {chatEventsHandler} from "./chatEventsHandler";
import {setChatLoaders, setChatsIsInit} from "shared/ReduxSlices/chatSlice/chatSlice";
import newMsgMP3 from "shared/static/sound/newMessage.mp3";
import {initialChatEvents} from "./chatConstants";

let isPlay = false;

export const chatUtils = {
  store: () => store.getState().chatSlice,
  dispatch: store.dispatch,
  // для определения что получаемое сообщение относится к уведомлениям
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _isChatEvent: (event: MessageEvent<any>) => {
    return event?.data?.response?.event?.indexOf(ChatEvents.ChatServiceEvent) === 0;
  },
  // callback для установки слушателя, которая предаёт сообщение в store
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _chatMessagesListener: async (event: MessageEvent<any>) => {
    if (chatUtils._isChatEvent(event)) {
      const chatEvent: string = event.data.response.event?.split(".");
      if (event.data.response.statusCode !== 200) {
        // await поскольку при работе с нескольких вкладках состояние не успевает синхронизироваться
        // и прелоадер переходит в false а потом в true
        await chatUtils.dispatch(setChatLoaders({[chatEvent[1]]: null}));
      } else {
        await chatUtils.dispatch(setChatLoaders({[chatEvent[1]]: false}));
      }
      if (chatEventsHandler[chatEvent[1]]) {
        chatEventsHandler[chatEvent[1]](event.data.response);
        return;
      }
    }
  },
  /** установка слушателя, для передачи сообщений в store */
  chatMessagesStartListener: () => {
    BcService.messageReceiver.addEventListener(
      "message",
      chatUtils._chatMessagesListener,
    );
  },
  /** снятие слушателя, для передачи сообщений в store */
  chatMessagesStopListener: () => {
    BcService.messageReceiver.removeEventListener(
      "message",
      chatUtils._chatMessagesListener,
    );
  },
  /** Отправка сообщений через Broadcast для отправки по ws */
  bsSend: async function (data = {}, event: keyof typeof ChatEvents.ChatService | "") {
    await chatUtils.dispatch(setChatLoaders({[event]: true}));
    BcService.handleBcSendGlobalMessage({
      request: {message: data, event: ChatEvents.ChatServiceEvent + event},
    });
  },
  getInitialChatStateByWs: () => {
    chatUtils.dispatch(setChatsIsInit(true));
    initialChatEvents.forEach((event) => chatUtils.bsSend({}, event));
  },
  setChatMobileStateLocalStorage: (value: boolean) => {
    localStorage.setItem("chat_isMobileView", `${value}`);
  },
  getChatUserById: ({
    userID,
    type = chatUtils.store().currentMainMenu,
  }: {
    userID: string;
    type?: TChatMenuType;
  }) => {
    let usersArray: TChatUser[] = [];
    if (type === "COMMON") {
      usersArray = chatUtils.store().GetChats.users;
    } else if (type === "ARCHIVE") {
      usersArray = chatUtils.store().GetArchivedChats.users;
    } else {
      usersArray = chatUtils.store().GetBlackListChats.users;
    }
    try {
      return usersArray.find((user) => user.id === userID);
    } catch (e) {
      console.warn("не удалось получить чат. ошибка:");
      console.warn(e);
    }
  },
  // getChatById: ({
  //   chatId = chatUtils.store().currentChat,
  //   type = chatUtils.store().currentMainMenu,
  // }: {
  //   chatId?: number;
  //   type?: TChatMenuType;
  // }) => {
  //   // debugger;
  //   let chatsArray: TChat[] = [];
  //   if (type === "COMMON") {
  //     chatsArray = chatUtils.store().GetChats.chats;
  //   } else if (type === "ARCHIVE") {
  //     chatsArray = chatUtils.store().GetArchivedChats.chats;
  //   } else {
  //     chatsArray = chatUtils.store().GetBlackListChats.chats;
  //   }

  //   try {
  //     // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  //     return chatsArray.find((chat) => chat.id === chatId)!;
  //   } catch (e) {
  //     console.warn("не удалось получить чат. ошибка:");
  //     console.warn(e);
  //     return chatsArray[0];
  //   }
  // },
  /**
   * хук для поиска сообщения, возвращающий его позицию в чате
   * @param param0
   * @returns
   */
  getMsgPosition: ({messageId = 0}: {messageId?: number}) => {
    const currentChatId = chatUtils.store().currentChat?.id;
    if (!currentChatId) return;
    try {
      const messages = chatUtils.store().GetMessages[currentChatId];
      const myId = chatUtils.store().userId;
      let msgID: number | undefined;
      if (!messageId) {
        msgID = messages.find(({isRead, userId}) => !isRead && myId !== userId)?.id;
      } else {
        msgID = messages.find(({id}) => messageId === id)?.id;
      }
      const msg = document.getElementById("chat_msg" + msgID);
      return msg ? msg.offsetTop : null;
    } catch (e) {
      console.warn("Не удалось получить позицию сообщения. ошибка:");
      console.warn(e);
    }
  },
  playNewMessageJingle: () => {
    if (!chatUtils.store().isMuteChat) {
      const audio = new Audio(newMsgMP3);
      !isPlay && audio.play().catch((e) => console.error(e));
      isPlay = true;
      audio.addEventListener("play", () => (isPlay = true));
      audio.addEventListener("ended", () => (isPlay = false));
    }
  },
};
