import {AlertColor} from "@mui/material/Alert";
import {SyntheticEvent, useState} from "react";

/**
 * Используется для управления контекста в провайдере SnackbarWrapper
 */
export const useSnackbar = () => {
  const [open, setOpen] = useState(false);
  const [textSnackbar, setTextSnackbar] = useState("");
  const [colorSnackbar, setColorSnackbar] = useState<AlertColor>("info");
  const [title, setTitleSnackbar] = useState("");

  const handleShowSnackbar = (text: string, color: AlertColor, title = "") => {
    setOpen(true);
    setTextSnackbar(text);
    setColorSnackbar(color);
    setTitleSnackbar(title);
  };
  const handleClose = (event: SyntheticEvent | Event | null, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  return {
    open,
    handleShowSnackbar,
    colorSnackbar,
    textSnackbar,
    handleClose,
    title,
  };
};
