import {createSlice} from "@reduxjs/toolkit";
import {resetState} from "../store/resetState";

export const breadCrumbsSlice = createSlice({
  name: "breadCrumbsSlice",
  initialState: {
    isLoading: false,
    endpoint: "",
    disabled: false,
  },
  reducers: {
    ...resetState,
    setDynamicCrumbsEndpoint: (state, action) => {
      state.endpoint = action.payload;
    },
    disableBreadCrumbs: (state) => {
      state.disabled = true;
    },
  },
});

export const {setDynamicCrumbsEndpoint, disableBreadCrumbs} = breadCrumbsSlice.actions;

export default breadCrumbsSlice.reducer;
