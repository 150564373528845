import {Components} from "@mui/material/styles";
import type {} from "@mui/x-date-pickers/themeAugmentation";
import {renderScrollStyle} from "shared/components/UI/UI_ScrollContainer/utils/ScrollStyle";

export const muiDateTimePicker: Components["MuiDateTimePicker"] = {
  defaultProps: {
    slotProps: {
      layout: {
        sx: ({palette}) => ({
          ".MuiYearCalendar-root": {
            ...renderScrollStyle(palette),

            ".MuiPickersYear-root": {
              button: {
                "&.Mui-selected": {
                  background: `${palette.primary700.main} !important`,

                  ":hover": {
                    background: `${palette.primary.main} !important`,
                  },
                },
              },
            },
          },

          ul: {
            ...renderScrollStyle(palette),

            li: {
              "&.Mui-selected": {
                background: `${palette.primary700.main} !important`,

                ":hover": {
                  background: `${palette.primary.main} !important`,
                },
              },
            },
          },
        }),
      },

      popper: {
        sx: ({palette}) => ({
          "& .MuiPickersDay-root": {
            "&.Mui-selected": {
              background: `${palette.primary700.main} !important`,

              ":hover": {
                background: `${palette.primary.main} !important`,
              },
            },
          },
        }),
      },
    },
  },
};
