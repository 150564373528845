import {EmployeeProfileSemiPublic} from "RTKQuery/types/companyApi";
import {ReactNode} from "react";
import {fieldLabels} from "../../constants/profileFields";

const rolesFields: string[] = ["srmRoles", "etpRoles"];
const onlyForMyCompany: string[] = [
  ...rolesFields,
  "projectManagerRole",
  "blockedInCompany",
  "systemRole",
];
export const getLabelAndValue = (
  field: string,
  otherUserProps: Partial<EmployeeProfileSemiPublic>,
  isNotMyCompany: boolean | null,
): {label: ReactNode; value: ReactNode} => {
  if (!(field in otherUserProps)) {
    return {label: null, value: null};
  }
  if (onlyForMyCompany.includes(field) && isNotMyCompany) {
    return {label: null, value: null};
  }
  if (field === "blockedInCompany") {
    return {label: fieldLabels[field], value: otherUserProps[field] ? "Да" : "Нет"};
  }
  if (rolesFields.includes(field)) {
    return {
      label: fieldLabels[field],
      value: otherUserProps[field].map(({i10n}) => i10n).join(", "),
    };
  }
  if (field === "projectManagerRole") {
    return {label: fieldLabels[field], value: otherUserProps[field] ? "Да" : "-"};
  }
  if (field === "systemRole") {
    return {label: fieldLabels[field], value: otherUserProps[field].i10n};
  }

  return {label: fieldLabels[field], value: otherUserProps[field] || "-"};
};
