import {Components, Theme} from "@mui/material/styles";

export const muiCssBaseline: Components<Theme>["MuiCssBaseline"] = {
  styleOverrides: ({palette}) => ({
    "*::-webkit-scrollbar": {
      width: "4px",
      height: "4px",
      borderRadius: "2px",
      backgroundColor: palette.primary100.main,
    },

    "*::-webkit-scrollbar-track": {
      backgroundColor: palette.primary300.main,
    },
    "*::-webkit-scrollbar-thumb": {
      borderRadius: "2px",
      backgroundColor: palette.primary.main,
      opacity: 0.4,
      cursor: "grab",
    },

    body: {
      lineHeight: "110%",
      overflowY: "scroll",
      overflowX: "hidden",
      WebkitFontSmoothing: "antialiased",
      MozOsxFontSmoothing: "grayscale",
      // "-webkit-font-smoothing": "antialiased",
      // "-moz-osx-font-smoothing": "grayscale",
      background: `${palette.background.default} !important`,
      color: palette.text.primary,
      scrollBehavior: "smooth",
    },
    "p,h1,h2,h3,h4,h5,h6,span,a": {
      lineHeight: "110%",
    },
  }),
};
