import {axiosGlobal} from "shared/services/axiosInstance/instAxios";

const TRADE_URL = "/procedures";

export const tradeService = {
  getList: (body) => axiosGlobal().post(`${TRADE_URL}/procedures/getList/`, body),

  procedures: (id) => axiosGlobal().get(`${TRADE_URL}/procedures/${id}`),

  disabled: (body) =>
    axiosGlobal().post(`${TRADE_URL}/procedures/getList/disabled`, body),

  files: (id) => axiosGlobal().get(`${TRADE_URL}/procedures/${id}/files`),

  lots: (id) => axiosGlobal().get(`${TRADE_URL}/procedures/${id}/lots`),

  protocols: (id) => axiosGlobal().get(`${TRADE_URL}/procedures/${id}/protocols`),

  questions: (id) => axiosGlobal().get(`${TRADE_URL}/questions/getList/${id}`),

  addQuestion: ({id, body}) =>
    axiosGlobal().post(`${TRADE_URL}/questions/addQuestion/${id}`, body),

  addAnswer: ({id, body}) =>
    axiosGlobal().post(`${TRADE_URL}/questions/addAnswer/${id}`, body),

  documents: (procId) => axiosGlobal().get(`${TRADE_URL}/procedures/${procId}/documents`),

  events: (procId) => axiosGlobal().get(`${TRADE_URL}/procedures/${procId}/events`),

  welcome: (body) => axiosGlobal().post(`${TRADE_URL}/welcome/sendRegWelcome`, body),

  dashboardProceduresCons: () =>
    axiosGlobal().get(`${TRADE_URL}/dashboard/consumerProcedures`),

  dashboardProceduresSup: () =>
    axiosGlobal().get(`${TRADE_URL}/dashboard/supplierProcedures`),

  dashboardProceduresTickets: () =>
    axiosGlobal().get(`${TRADE_URL}/dashboard/proceduresTickets`),

  cancelProcedure: ({id, params}) =>
    axiosGlobal().delete(`${TRADE_URL}/tickets/${id}`, {params}),

  getEmployees: (id) => axiosGlobal().get(`${TRADE_URL}/procedures/${id}/getEmployees`),

  setEmployees: ({id, body}) =>
    axiosGlobal().post(`${TRADE_URL}/procedures/${id}/setEmployees`, body),

  getEmployeesParticipation: (id) =>
    axiosGlobal().get(`${TRADE_URL}/participations/${id}/getEmployees`),

  setEmployeesParticipation: ({id, body}) =>
    axiosGlobal().post(`${TRADE_URL}/participations/${id}/setEmployees`, body),

  toRebidding: (id) => axiosGlobal().post(`${TRADE_URL}/procedures/${id}/toRebidding`),

  saveRebidding: ({id, body}) =>
    axiosGlobal().post(`${TRADE_URL}/participations/${id}/saveRebidding`, body),

  getRebiddingList: (id) =>
    axiosGlobal().get(`${TRADE_URL}/procedures/${id}/getRebiddingList`),
};
