export const ACTIONS = {
  allowedClose: "allowedClose",
  allowedCloseTicket: "allowedCloseTicket",
  allowedSetPositionStatusToGet: "allowedSetPositionStatusToGet",
  allowedSetStatusPositionToBillIsPayed: "allowedSetStatusPositionToBillIsPayed",
  allowedSetStatusPositionToOnTrade: "allowedSetStatusPositionToOnTrade",
  allowedSetQuantityOfPositionStorage: "allowedSetQuantityOfPositionStorage",
  allowedChangeQuantityOfPositionStorage: "allowedChangeQuantityOfPositionStorage",
  allowedDeleteTrade: "allowedDeleteTrade",
  navigateToProcedureTicket: "navigateToProcedureTicket",
  navigateToApproveExecutor: "navigateToApproveExecutor",
  navigateToApproveController: "navigateToApproveController",
  navigateToGenerateProtocol: "navigateToGenerateProtocol",
  navigateToChooseSupplier: "navigateToChooseSupplier",
  navigateToTradeProcedure: "navigateToTradeProcedure",
  addOrEditeContactInProject: "addOrEditeContactInProject",
  canDeletePositionInvoice: "canDeletePositionInvoice",
  allowCreateTicketSchema: "allowCreateTicketSchema",
} as const;
