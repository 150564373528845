import {ComponentType, lazy, useEffect, useState} from "react";

let handler;
/**
 * Используется для "замедления" отображения прелоадера
 */
export const useDebounceLoader = (value: boolean) => {
  const [debouncedLoader, setDebouncedLoader] = useState(value);
  const [currDate, setNewDate] = useState(Date.now());
  const [delay, setDelay] = useState<0 | 100>(0);

  useEffect(() => {
    if (value) {
      setNewDate(Date.now());
      setDebouncedLoader(value);
    }
    if (!value && debouncedLoader) {
      setDelay(Date.now() - currDate > 5000 ? 0 : 100);
      handler = setTimeout(() => {
        setDebouncedLoader(value);
      }, delay);
    }

    return () => {
      clearTimeout(handler);
    };
  }, [delay, value]);

  return {debouncedLoader};
};

// используется в случаях, когда загрузка длится меньше секунды
// и фраза "идёт загрузка" слишком быстро переходит в отображаемый контент
// но, в случае если загрузка идёт дольше 5 секунд,
// то дополнительное ожидание не добавляется

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const lazyMinLoadTime = <T extends ComponentType<any>>(
  factory: () => Promise<{default: T}>,
) =>
  lazy(() =>
    Promise.all([factory(), new Promise((resolve) => setTimeout(resolve, 1000))]).then(
      ([moduleExports]) => moduleExports,
    ),
  );
