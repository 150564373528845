import {SnackbarProps} from "@mui/base";
import {AlertColor} from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {useTheme} from "@mui/material/styles";
import {ReactNode} from "react";
import CloseIcon from "@mui/icons-material/Close";

interface UI_SnackbarProps extends SnackbarProps {
  type: AlertColor;
  text: string;
  label?: ReactNode;
}
const titleMap: Record<AlertColor, string> = {
  error: "Ошибка",
  info: "Уведомление",
  success: "Уведомление",
  warning: "Уведомление",
};

function UI_Snackbar({
  open,
  onClose,
  autoHideDuration,
  type,
  text,
  title = "",
}: UI_SnackbarProps) {
  const {palette} = useTheme();
  const colorMap: Record<AlertColor, {backgroundColor: string; color: string}> = {
    error: {backgroundColor: palette.red500.main, color: palette.red.main},
    info: {backgroundColor: palette.primary300.main, color: palette.black.main},
    success: {backgroundColor: palette.green600.main, color: palette.green.main},
    warning: {backgroundColor: palette.yellow.main, color: palette.black.main},
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration || 4000}
      onClose={onClose}
      message="Note archived"
      anchorOrigin={{vertical: "bottom", horizontal: "right"}}
    >
      <Stack
        gap={1}
        sx={{
          p: 3,
          flexDirection: "column",
          height: "92px",
          width: "340px",
          borderRadius: "16px",
          backgroundColor: title
            ? colorMap["info"].backgroundColor
            : colorMap[type].backgroundColor,
          boxShadow:
            "0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
        }}
      >
        <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
          <Typography
            sx={{color: title ? colorMap["info"].color : colorMap[type].color}}
            variant="h4-regular-12"
          >
            {title || titleMap[type]}
          </Typography>
          <Button
            variant="text"
            onClick={(e) => onClose && onClose(e, "escapeKeyDown")}
            sx={{
              minWidth: "unset",
              p: 0,
              minHeight: "unset",
              color: palette.black.main,
            }}
          >
            <CloseIcon fontSize="small" />
          </Button>
        </Stack>
        <Typography
          variant="h3-regular-13"
          sx={{
            color: palette.black.main,
            overflow: "hidden",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 3,
            textOverflow: "ellipsis",
            display: "-webkit-box",
          }}
        >
          {text}
        </Typography>
      </Stack>
    </Snackbar>
  );
}

export default UI_Snackbar;
