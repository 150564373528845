import {useState} from "react";
import {createAttachedSignature, createDetachedSignature, createHash} from "crypto-pro";
import Message from "./components/Message";
import Certificate from "./components/Certificate";
import SignatureType from "./components/SignatureType";
import Hash from "./components/Hash";
import Signature from "./components/Signature";
import CustomSystemInfo from "./components/CustomSystemInfo";
import SystemInfo from "./components/SystemInfo";
import {FileComponent} from "./components/FileComponent";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import UploadFile from "./components/UploadFile";

type TTargetForSignature = "text" | "File";

const MAX_FILE_SIZE = 25000000 as const;

function readFileByFileReader(file: File | null): Promise<string | ArrayBuffer | null> {
  return new Promise(function (resolve, reject) {
    if (file === null || !(file instanceof File)) {
      reject("Нет файла");
      return;
    }
    let fileReader = new FileReader();

    fileReader.onload = function () {
      resolve(this.result);
    };

    if (file.size > MAX_FILE_SIZE) {
      reject("Файл для подписи не должен превышать " + MAX_FILE_SIZE / 1000000 + "МБ");
      return;
    }

    fileReader.readAsArrayBuffer(file);
  });
}

const TestCryptoPro = () => {
  const [message, setMessage] = useState("");
  const [file, setFile] = useState<null | File>(null);
  const [certificate, setCertificate] = useState<any>(null);
  const [detachedSignature, setSignatureType] = useState(null);
  const [hash, setHash] = useState("");
  const [hashStatus, setHashStatus] = useState("Не вычислен");
  const [hashError, setHashError] = useState(null);
  const [signature, setSignature] = useState("");
  const [signatureStatus, setSignatureStatus] = useState("Не создана");
  const [signatureError, setSignatureError] = useState(null);

  const getTargetForSignature: Record<
    TTargetForSignature,
    () => Promise<string | ArrayBuffer | null>
  > = {
    text: () => Promise.resolve(message),
    File: () => readFileByFileReader(file),
  };

  async function createSignature(target: TTargetForSignature) {
    let hash;

    setSignature("");
    setSignatureError(null);
    setHash("");
    setHashError(null);
    setHashStatus("Вычисляется...");

    try {
      const message = await getTargetForSignature[target]();
      if (!message) {
        throw Error("message === null !!!!!");
      }
      hash = await createHash(message);
      setHash(hash);
    } catch (error) {
      setHashError(error.message);
      return;
    }

    setHashStatus("Не вычислен");
    setSignatureStatus("Создается...");

    if (detachedSignature) {
      try {
        setSignature(await createDetachedSignature(certificate.thumbprint, hash));
      } catch (error) {
        setSignatureError(error.message);
      }
      setSignatureStatus("Не создана");
      return;
    }

    try {
      setSignature(await createAttachedSignature(certificate.thumbprint, message));
    } catch (error) {
      setSignatureError(error.message);
    }

    setSignatureStatus("Не создана");
  }
  const copyData = () => {
    const copyText = ` 
  SERTIFICATE = ${JSON.stringify(certificate)} \n 
  HASH = ${hash} \n 
  SIGNATURE = ${signature}
  `;
    navigator.clipboard.writeText(copyText);
  };
  return (
    <>
      <form noValidate>
        <Stack my={4}>
          <Certificate onChange={setCertificate} />
          <SignatureType onChange={setSignatureType} />
          <Divider />
        </Stack>

        <fieldset>
          <Stack gap={2}>
            <Stack direction={"row"} gap={2}>
              <Message onChange={setMessage} message={message} />
              <FileComponent onChange={setFile} />
              <UploadFile setFile={setFile} />
            </Stack>
            <br />
            {file ? (
              <Stack alignItems={"center"}>
                <Typography>Название: {file.name}</Typography>
                <Typography>Размер: {file.size} байт</Typography>
              </Stack>
            ) : null}
          </Stack>
          <br />
          <hr />

          <Button
            variant="main"
            type="button"
            disabled={!certificate || !message}
            onClick={() => createSignature("text")}
          >
            Подписать текст
          </Button>
          <Button
            variant="main"
            type="button"
            disabled={!certificate || !file}
            onClick={() => createSignature("File")}
          >
            Подписать файл
          </Button>
        </fieldset>
      </form>

      <fieldset>
        <Hash
          hash={hash}
          hashStatus={hashStatus}
          hashError={hashError}
          copyData={copyData}
        />

        <Signature
          signature={signature}
          signatureStatus={signatureStatus}
          signatureError={signatureError}
        />

        <p>
          Для{" "}
          <a
            href="https://www.gosuslugi.ru/pgu/eds/"
            target="_blank"
            rel="nofollow noopener noreferrer"
            title="Перейти к проверке подписи"
          >
            проверки
          </a>{" "}
          нужно создать файл со сгенерированной подписью в кодировке UTF-8 с расширением
          *.sgn
          <br />
          для отделенной подписи (или *.sig для совмещенной).
        </p>
      </fieldset>

      <fieldset>
        <legend>Информация о системе</legend>
        <CustomSystemInfo />
        <SystemInfo />
      </fieldset>
    </>
  );
};

export default TestCryptoPro;
