export interface IVerificationCompanyState {
  isLoading: boolean;
  isLoadingFindCompanies: boolean;
  reqMini: any | null;
  registredCompanies: any[];
}
export const initialState: IVerificationCompanyState = {
  isLoading: false,
  isLoadingFindCompanies: false,
  reqMini: null,
  registredCompanies: [],
};
