import {api} from "RTKQuery/api";
// import {chatSliceActions} from "shared/ReduxSlices/chatSlice/chatSlice";
import {createStateSyncMiddleware} from "redux-state-sync";
import {WSSliceActions} from "shared/ReduxSlices/webSocketSlice/WSSlice";
import {resetMiddleware} from "./middlewares/resetMiddleware";
import {listenerMiddleware} from "./middlewares/listenerMiddleware";
import {userRegisterSliceActions} from "shared/ReduxSlices/userSlice/userSlice";
import {chatSliceActions} from "shared/ReduxSlices/chatSlice/chatSlice";
import {notificationSliceActions} from "shared/ReduxSlices/notificationsSlice/notificationsSlice";

//массив диспатчей, для синхронизации
// например ["chatSlice/chatInit", ...]
export const middlewares = [
  listenerMiddleware.middleware,
  api.middleware,
  resetMiddleware,
  createStateSyncMiddleware({
    whitelist: [
      ...chatSliceActions,
      ...WSSliceActions,
      ...userRegisterSliceActions,
      // ...notificationSliceActions,
    ],
  }),
];
