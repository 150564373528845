import AuthHelper, {authHelper} from "../authSlice/Auth.helper";

class TradeHelper extends AuthHelper {
  /**
   * @protected
   * @param {import("axios").AxiosResponse} response
   * @returns {object} detail - описание поля ошибкиб и доп. флаг или опции
   */
  process400(response) {
    console.log(400);

    return {detail: response.data.detail};
  }

  /**
   * @protected
   * @param {import("axios").AxiosResponse} response
   * @returns {object} detail - описание поля ошибкиб и доп. флаг или опции
   */
  process401(response, dispatch, requestFunction) {
    return authHelper.handlerError(response, dispatch, requestFunction);
  }
  /**
   * @protected
   * @param {import("axios").AxiosResponse} response
   * @returns {object} detail - описание поля ошибкиб и доп. флаг или опции
   */
  process404(response) {
    if (typeof response.data === "string") {
      return {detail: {warning: response.data}};
    }
    return {detail: response.data.detail};
  }
  /**
   * @protected
   * @param {import("axios").AxiosResponse} response
   * @returns {object} detail - описание поля ошибкиб и доп. флаг или опции
   */
  process409(response) {
    return {detail: response.data.detail};
  }
  /**
   * @protected
   * @param {import("axios").AxiosResponse} response
   * @returns {object} detail - описание поля ошибкиб и доп. флаг или опции
   */
  process418(response) {
    return {detail: response.data.detail, isNeedToConfirm: true};
  }
  /**
   * @protected
   * @param {import("axios").AxiosResponse} response
   * @returns {object} detail - описание поля ошибкиб и доп. флаг или опции
   */
  process422(response) {
    return {detail: response.data.detail};
  }
  processServerError(response) {
    authHelper.processServerError(response);
    return {detail: response?.data?.detail || {form: "Ошибка"}};
  }

  handleRejectedResponse(state, action) {
    state.isLoading = false;
    state.errorData = action?.payload?.detail;
  }
}

export const tradeHelper = new TradeHelper();
