import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import React from "react";

function Signature({signature, signatureStatus, signatureError}) {
  return (
    <Box margin={"0 10px"}>
      <br />
      <TextField
        label="Подпись (PKCS7):"
        multiline
        id="signature"
        cols="80"
        rows="30"
        value={signature}
        placeholder={signatureStatus}
        readOnly
      />
      {/* <textarea
        id="signature"
        cols="80"
        rows="30"
        value={signature}
        placeholder={signatureStatus}
        readOnly
      /> */}

      <pre>{signatureError || null}</pre>
    </Box>
  );
}

export default Signature;
