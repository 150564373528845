import {useAppSelector} from "../useAppSelector";
import {useAppDispatch} from "../useAppDispatch";
import {onLogout} from "shared/ReduxSlices/bcSlice/bcSlice";
import {setUser} from "shared/ReduxSlices/userSlice/userSlice";
import {useEffect} from "react";
import {BcService} from "shared/services/BroadcastChannelService";
import {assignNotificationState} from "shared/ReduxSlices/notificationsSlice/notificationsSlice";
import {useModalContext} from "../useModalContext/useModalContext";
import {assignChatsState} from "shared/ReduxSlices/chatSlice/chatSlice";

export const useBcHelpers = () => {
  const {isMainApp, tabsCount} = useAppSelector((store) => store.broadcastChannelSlice);
  const {userData} = useAppSelector((store) => store.UserRegisterSlice);
  const {messageReceiver, handleBcSendMessage} = BcService;
  const {handleShowModal} = useModalContext();
  const chatSlice = useAppSelector((store) => store.chatSlice);
  const notificationSlice = useAppSelector((store) => store.notificationSlice);
  const dispatch = useAppDispatch();

  const messageHandler = ({data}: MessageEvent) => {
    if (data.userData) {
      dispatch(setUser(data.userData));
    } else if (data.chatSlice) {
      dispatch(assignChatsState({...data.chatSlice}));
    } else if (data.notificationSlice) {
      dispatch(assignNotificationState({...data.notificationSlice}));
    } else if (data.expired) {
      dispatch(onLogout());
      handleShowModal("Время сессии истекло");
    } else if (data.alreadyConnected) {
      dispatch(onLogout());
      handleShowModal("Другой пользователь уже подключился");
    } else if (data.manuallyLoggedOut) {
      dispatch(onLogout());
      handleShowModal("Вы вышли из системы");
    } else if (data.loggedOtherPlace) {
      dispatch(onLogout());
      handleShowModal("Подключение с другого устройства");
    } else if (data.blockedCompanyLevel) {
      dispatch(onLogout());
      handleShowModal("Вы заблокированы на уровне компании");
    } else if (data.blockedSystemLevel) {
      dispatch(onLogout());
      handleShowModal("Вы заблокированы на уровне системы");
    } else if (data.CompanyBlocked) {
      dispatch(onLogout());
      handleShowModal("Ваша компания заблокирована");
    }
    return;
  };

  useEffect(() => {
    messageReceiver.onmessage = (e) => messageHandler(e);
    // messageReceiver.addEventListener("message", messageHandler);
    return () => messageReceiver.removeEventListener("message", messageHandler);
  }, []);

  useEffect(() => {
    if (!userData || !isMainApp) return;
    const {isOpenChat, currentChat, loaders, isInit: some, ...chatSliceRest} = chatSlice;
    const {isInit, ...notificationSliceRest} = notificationSlice;
    handleBcSendMessage({userData: userData});
    handleBcSendMessage({chatSlice: chatSliceRest});
    handleBcSendMessage({notificationSlice: notificationSliceRest});
  }, [tabsCount]);
};
