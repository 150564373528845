
import Typography from "@mui/material/Typography";
import {FC, PropsWithChildren} from "react";
import {Navigate} from "react-router-dom";
import {AppWrapper} from "shared/components/AppWrapper/AppWrapper";
import {ROUTES} from "shared/constants/paths";
import {useAppCurrentPath} from "shared/hooks/useAppCurrentPath/useAppCurrentPath";
import {useAppProtectedRoute} from "shared/hooks/useAppProtectedRoute/useAppProtectedRoute";
import {useAppUserData} from "shared/hooks/useAppUserData";
import {useCommonPermission} from "shared/hooks/useCommonPermission";

const ProtectedRouteWrapper: FC<PropsWithChildren> = ({children}) => {
  const currentRoutePattern = useAppCurrentPath();
  const userData = useAppUserData();
  const {type} = useAppProtectedRoute(currentRoutePattern);

  const isAllow = useCommonPermission({
    target: currentRoutePattern as any,
    type: "routes",
  });

  if (type === "public") {
    return <> {children}</>;
  }
  if (type === "auth") {
    return userData ? <Navigate to={ROUTES.HOME} replace={true} /> : <> {children}</>;
  }
  if (type === "forbidden") {
    return <Navigate to={ROUTES.HOME} replace={true} />;
  }
  if (!isAllow) {
    return <NotPermission />;
  }
  return <> {children}</>;
};

export {ProtectedRouteWrapper};

function NotPermission() {
  return (
    <AppWrapper>
      <Typography
        paragraph
        variant="20px"
        sx={({palette}) => ({color: palette.red.main, mt: 4})}
      >
        У вас нет прав для просмотра этой страницы
      </Typography>
    </AppWrapper>
  );
}
