import {Theme, createTheme} from "@mui/material/styles";
import {muiButton} from "./ovirridesPropsThemeComponent/button";
import {muiInput} from "./ovirridesPropsThemeComponent/input";
import {getObjectValuesType} from "shared/utils/typesUtils/getObjectValuesType";
import {typography} from "./overridesTypography/typography";
import {muiInputLabel} from "./ovirridesPropsThemeComponent/inputLabel";
import {muiCheckbox} from "./ovirridesPropsThemeComponent/checkbox";
import {muiTextField} from "./ovirridesPropsThemeComponent/textField";
import {palette, darkPalete} from "./paleteOverrides/paleteOverrides";
import {muiDatePicker} from "./ovirridesPropsThemeComponent/datePicker";
import type {} from "@mui/x-date-pickers/themeAugmentation";
import {muiTab} from "./ovirridesPropsThemeComponent/tab";
import {muiTabs} from "./ovirridesPropsThemeComponent/tabs";
import {muiTable} from "./ovirridesPropsThemeComponent/table";
import {muiTableHead} from "./ovirridesPropsThemeComponent/tableHead";
import {muiTableBody} from "./ovirridesPropsThemeComponent/tableBody";
import {muiTableCell} from "./ovirridesPropsThemeComponent/tableCell";
import {muiButtonBase} from "./ovirridesPropsThemeComponent/muiButtonBase";
import {muiPagination} from "./ovirridesPropsThemeComponent/pagination";
import {muiSelect} from "./ovirridesPropsThemeComponent/select";
import {muiInputBase} from "./ovirridesPropsThemeComponent/inputBase";
import {muiAutocomplete} from "./ovirridesPropsThemeComponent/muiAutocomplete";
import {muiTypography} from "./ovirridesPropsThemeComponent/typography";
import {muiTooltip} from "./ovirridesPropsThemeComponent/tooltip";
import {muiTimePicker} from "./ovirridesPropsThemeComponent/timePicker";
import {muiDateTimePicker} from "./ovirridesPropsThemeComponent/dateTimePicker";
import {muiDateCalendar} from "./ovirridesPropsThemeComponent/dateCalendar";
import {muiDivider} from "./ovirridesPropsThemeComponent/divider";
import {muiHelperText} from "./ovirridesPropsThemeComponent/helperText";
import {themes} from "shared/constants/themes";
import {muiCssBaseline} from "./baselineOverrides/baseline";

const general = createTheme({
  palette,
  spacing: 4,
  components: {
    MuiCssBaseline: muiCssBaseline,
    MuiButton: muiButton,
    MuiInput: muiInput,
    MuiInputLabel: muiInputLabel,
    MuiCheckbox: muiCheckbox,
    MuiTextField: muiTextField,
    MuiDatePicker: muiDatePicker,
    MuiTimePicker: muiTimePicker,
    MuiDateTimePicker: muiDateTimePicker,
    MuiDateCalendar: muiDateCalendar,
    MuiTab: muiTab,
    MuiTabs: muiTabs,
    MuiTable: muiTable,
    MuiTableHead: muiTableHead,
    MuiTableBody: muiTableBody,
    MuiTableCell: muiTableCell,
    MuiButtonBase: muiButtonBase,
    MuiPagination: muiPagination,
    MuiSelect: muiSelect,
    MuiInputBase: muiInputBase,
    MuiAutocomplete: muiAutocomplete,
    MuiTypography: muiTypography,
    MuiTooltip: muiTooltip,
    MuiDivider: muiDivider,
    MuiFormHelperText: muiHelperText,
  },
  typography,
});

const light = createTheme(general, {
  palette: {
    mode: themes.LIGHT,
  },
});
const dark = createTheme(general, {
  palette: {
    mode: themes.DARK,
    ...darkPalete,
  },
  components: {
    MuiInput: {
      styleOverrides: {
        input: {
          border: `1px solid ${general.palette.white.main}`,
          "::placeholder": {
            color: general.palette.white.main,
          },
        },
      },
    },
  },
});

export const THEMES: Record<getObjectValuesType<typeof themes>, Theme> = {
  [themes.LIGHT]: light,
  [themes.DARK]: dark,
};
