
import Button from "@mui/material/Button";

const FileComponent = ({onChange}) => {
  return (
    <Button variant="outlined" component={"label"} htmlFor="fff" sx={{width: "100%"}}>
      <input
        style={{
          display: "none",
        }}
        id="fff"
        type="file"
        onChange={({target}) => onChange(target?.files?.[0])}
      />
      Добавить файл с компьютера
    </Button>
  );
};

export {FileComponent};
