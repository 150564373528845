import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {createTradeServise} from "../../services/CreateTradeProcedureServise";
import {resetState} from "../../store/resetState";
import {initialState} from "./type";

export const getProds = createAsyncThunk(
  "getProdsCatalogSlice",
  async ({onpage, page, catID}, {rejectWithValue}) => {
    try {
      const response = await createTradeServise.getProds({
        onpage,
        page,
        catID,
      });
      const {data} = response;
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data || error);
    }
  },
);

export const getChars = createAsyncThunk(
  "getCharsCatalogSlice",
  async (prodID, {rejectWithValue}) => {
    try {
      const response = await createTradeServise.getChars(prodID);
      const {data} = response;
      data.parentId = prodID;
      return data;
    } catch (error) {
      return rejectWithValue(error.response || error);
    }
  },
);

export const searchProds = createAsyncThunk(
  "searchProds",
  async ({query, onpage, page, catID}, {rejectWithValue}) => {
    try {
      const params = {};
      if (parseInt(catID) === 0) {
        params.query = query;
        params.onpage = onpage;
        params.page = page;
      } else {
        params.query = query;
        params.onpage = onpage;
        params.page = page;
        params.cat_id = catID;
      }
      const response = await createTradeServise.searchProds(params);
      const {data} = response;
      return data;
    } catch (error) {
      return rejectWithValue(error.response || error);
    }
  },
);
export const searchProdsAll = createAsyncThunk(
  "searchProdsAll",
  async ({query, onpage, page}, {rejectWithValue}) => {
    try {
      const response = await createTradeServise.searchProds({
        query,
        onpage,
        page,
      });
      const {data} = response;
      return data;
    } catch (error) {
      return rejectWithValue(error.response || error);
    }
  },
);

export const catalogSlice = createSlice({
  name: "catalogSlice",
  initialState,

  reducers: {
    ...resetState,
    resetCash(state) {
      state.cashGoods = {};
      state.amountGoods = 0;
    },
    addCharsToLoading(state, action) {
      state.charsIsLoadingCash[action.payload] = true;
    },
    deleteCharsToLoading(state, action) {
      state.charsIsLoadingCash[action.payload] = false;
    },
    setSearchString(state, action) {
      state.searchString = action.payload;
    },
  },

  extraReducers: (builder) =>
    builder
      //getProds
      .addCase(getProds.fulfilled, (state, action) => {
        const tmp = {};
        state.goodsIsLoading = false;
        action.payload.products.forEach((item) => {
          if (tmp[item.id]) {
            return;
          }
          tmp[item.id] = {...item, chars: []};
        });
        state.cashGoods = tmp;
        state.amountGoods = action.payload.count;
      })
      .addCase(getProds.pending, (state) => {
        state.goodsIsLoading = true;
      })
      .addCase(getProds.rejected, (state) => {
        state.goodsIsLoading = false;
        state.amountGoods = 0;
      })
      //getChars
      .addCase(getChars.fulfilled, (state, action) => {
        state.charsIsLoading = false;
        state.cashGoods[action.payload.parentId].chars = action.payload;
        state.charsIsLoadingCash[action.payload.parentId] = false;
      })
      .addCase(getChars.pending, (state) => {
        state.charsIsLoading = true;
      })
      .addCase(getChars.rejected, (state, action) => {
        state.charsIsLoading = false;
        state.charsIsLoadingCash[action.payload.parentId] = false;
      })
      //---------------------------------------
      //searchProds
      .addCase(searchProds.fulfilled, (state, action) => {
        state.goodsIsLoading = false;
        const tmp = {};

        action.payload.products.forEach((item) => {
          if (tmp[item.id]) {
            return;
          }
          tmp[item.id] = {...item, chars: []};
        });
        state.cashGoods = tmp;
        state.amountGoods = action.payload.count;
      })
      .addCase(searchProds.pending, (state) => {
        state.goodsIsLoading = true;
      })
      .addCase(searchProds.rejected, (state) => {
        state.goodsIsLoading = false;
        state.amountGoods = 0;
        state.cashGoods = {};
      })
      //searchProdsAll
      .addCase(searchProdsAll.fulfilled, (state, action) => {
        state.goodsIsLoading.products = false;
        action.payload.forEach((item) => {
          if (state.cashGoods[item.id]) {
            return;
          }
          state.cashGoods[item.id] = {...item, chars: []};
        });
        state.amountGoods = action.payload.count;
      })
      .addCase(searchProdsAll.pending, (state) => {
        state.goodsIsLoading = true;
      })
      .addCase(searchProdsAll.rejected, (state) => {
        state.goodsIsLoading = false;
        state.amountGoods = 0;
        state.cashGoods = {};
      }),
});

export const {resetCash, addCharsToLoading, deleteCharsToLoading, setSearchString} =
  catalogSlice.actions;

export default catalogSlice.reducer;
