import {TNameWidget} from "../providers/DashboardProvider";

export const WIDGET_NAMES: Record<TNameWidget, string> = {
  // Request: "Заявки",
  Procedures: "Всего процедур",
  ProcerureAndParticipationList: "Торги",
  // Logistics: "Логистика",
  // Loading: "Расчет схемы погрузки",
  // ECP: "Электронная подпись",
  // Contract: "Договоры",
  // Consumption: "Расходы за месяц",
  Alarm: "Уведомления",
  Quotes: "Котировки",
  CompanyVerification: "Проверка компании",
  BuySellStatistics: "Статистика торгов",
  Planner: "Планировщик",
  TeamList: "Моя команда",
  Tickets: "Черновики",
  SrmTicketsExecutor: "Заявки SRM ( Исполнитель )",
  SrmTicketsInitiator: "Заявки SRM ( Инициатор )",
  // SrmTicketsController: "Заявки SRM ( Контролер )",
} as const;
