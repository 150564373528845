import {ThemeOptions} from "@mui/material/styles";
import {CSSProperties} from "react";

declare module "@mui/material/styles" {
  interface TypographyVariants {
    "28px": CSSProperties;
    "24px": CSSProperties;
    "20px": CSSProperties;
    "18px": CSSProperties;
    "16px": CSSProperties;
    "15px": CSSProperties;
    error: CSSProperties;
    "h1-medium-20": CSSProperties;
    "h1-regular-20": CSSProperties;
    "h2-medium-14": CSSProperties;
    "h2-regular-14": CSSProperties;
    "h3-medium-13": CSSProperties;
    "h3-regular-13": CSSProperties;
    "h4-medium-12": CSSProperties;
    "h4-regular-12": CSSProperties;
    "sub-font": CSSProperties;
    link: CSSProperties;
  }

  interface TypographyVariantsOptions {
    "28px"?: CSSProperties;
    "24px"?: CSSProperties;
    "20px": CSSProperties;
    "18px": CSSProperties;
    "16px": CSSProperties;
    "15px": CSSProperties;
    error: CSSProperties;
    "h1-medium-20": CSSProperties;
    "h1-regular-20": CSSProperties;
    "h2-medium-14": CSSProperties;
    "h2-regular-14": CSSProperties;
    "h3-medium-13": CSSProperties;
    "h3-regular-13": CSSProperties;
    "h4-medium-12": CSSProperties;
    "h4-regular-12": CSSProperties;
    "sub-font": CSSProperties;
    link: CSSProperties;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    "28px": true;
    "24px": true;
    "20px": true;
    "18px": true;
    "16px": true;
    "15px": true;
    error: true;
    "h1-medium-20": true;
    "h1-regular-20": true;
    "h2-medium-14": true;
    "h2-regular-14": true;
    "h3-medium-13": true;
    "h3-regular-13": true;
    "h4-medium-12": true;
    "h4-regular-12": true;
    "sub-font": true;
    "opacity-12": true;
    link: true;
  }
}
const fontFamily = ["Gilroy", "sans-serif"].join(",");
export const typography: ThemeOptions["typography"] = ({
  error,
  black800,
  mode,
  black300,
  text,
  primary,
}) => ({
  fontFamily,
  "28px": {
    fontSize: "28px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "130%",
    fontFamily,
    marginBottom: 0,
  },
  "24px": {
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "160%",
    fontFamily,
    marginBottom: 0,
  },
  "20px": {
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "130%",
    fontFamily,
    marginBottom: 0,
  },
  "18px": {
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "130%",
    fontFamily,
    marginBottom: 0,
  },
  "16px": {
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "110%",
    fontFamily,
    marginBottom: 0,
  },
  "15px": {
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "130%",
    fontFamily,
    marginBottom: 0,
  },
  error: {
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "110%",
    fontFamily,
    color: error.main,
    marginBottom: 0,
  },
  "h1-medium-20": {
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "120%",
    fontFamily,
    marginBottom: 0,
  },
  "h1-regular-20": {
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "130%",
    fontFamily,
    marginBottom: 0,
  },
  "h2-medium-14": {
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "110%",
    fontFamily,
    marginBottom: 0,
  },
  "h2-regular-14": {
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "110%",
    fontFamily,
    marginBottom: 0,
  },
  "h3-medium-13": {
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "110%",
    fontFamily,
    marginBottom: "0px",
  },
  "h3-regular-13": {
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "110%",
    fontFamily,
    marginBottom: "0px",
  },
  "h4-medium-12": {
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "110%",
    fontFamily,
    marginBottom: 0,
  },
  "h4-regular-12": {
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "110%",
    fontFamily,
    marginBottom: 0,
  },

  "opacity-12": {
    fontSize: "12px",
    opacity: 0.7,
  },
  allVariants: {
    marginBottom: 0,
  },
  "sub-font": {
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "110%",
    fontFamily,
    color: text.secondary,
  },
  body1: {
    fontWeight: 500,
    fontSize: "13px",
    lineHeight: "110%",
  },
  link: {
    color: primary.main,
    padding: 0,
    fontWeight: "500",
    cursor: "pointer",
    ":hover": {
      textDecoration: "underline",
      backgroundColor: "inherit",
    },
  },
});
