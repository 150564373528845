import {UserInfo} from "RTKQuery/types/userApi";
import {setUser} from "entities/user";
import {Dispatch} from "redux";
import {BcService} from "shared/services/BroadcastChannelService";

export const setUserData = async (dispatch: Dispatch, data: UserInfo) => {
  try {
    if (!data) {
      throw new Error("Нет данных от сервера");
    }
    dispatch(setUser(data));
    BcService.handleBcSendMessage({userData: data});
  } catch (error) {
    dispatch(setUser(undefined));
  }
};
