export const CHAT_TYPES = {
  GROUP: "GROUP",
  DIRECT: "DIRECT",
  MEETING: "MEETING",
  // ADD_USER_GROUP: "ADD_USER_GROUP",
} as const;

export const CHAT_ADMIN = {
  operator: "Оператор площадки",
  tech: "Техническая поддержка",
} as const;
export const INVITATION_STATE = {
  REQUEST: "REQUEST",
  ACCEPT: "ACCEPT",
} as const;
