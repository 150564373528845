import {setIsMainTabApp, setTabsCount} from "shared/ReduxSlices/bcSlice/bcSlice";

export const SharedWorkerListener = (dispatch) => {
  const worker = new SharedWorker(new URL("./counter.ts", import.meta.url));
  worker.port.postMessage({type: "connect"});
  worker.port.onmessage = (e: MessageEvent) => {
    dispatch(setTabsCount(e.data.tabsCount));
    // подумать как реализовать защиту от
    // скачкообразного закрытия вкладок например
    // нажатие "закрыть все вкладки справа"
    const isMain = e.data.tabNumber === 1;
    dispatch(setIsMainTabApp(isMain));
  };

  const unloadHandler = () => {
    worker.port.postMessage({type: "disconnect"});
    worker.port.close();
  };

  window.addEventListener("beforeunload", unloadHandler);

  return () => {
    unloadHandler();
    window.removeEventListener("beforeunload", unloadHandler);
  };
};
