import {axiosGlobal} from "shared/services/axiosInstance/instAxios";

const FEEDBACK_ENDPOINT = "/notifications/v1/tickets";

export const feedbackService = {
  helpdesk: (body) => axiosGlobal().post(FEEDBACK_ENDPOINT + "/helpdesk", body),

  head: (body) => axiosGlobal().post(FEEDBACK_ENDPOINT + "/head", body),

  feature: (body) => axiosGlobal().post(FEEDBACK_ENDPOINT + "/feature", body),

  question: (body) => axiosGlobal().post(FEEDBACK_ENDPOINT + "/question", body),

  questionComplex: (body) => axiosGlobal().post(FEEDBACK_ENDPOINT + "/question2", body),
};
