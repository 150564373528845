import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {CHECKBOX_TYPE} from "pages/Tickets/constants.tickets";
import {ticketsService} from "shared/services/TicketsService";
import {resetState} from "../../store/resetState";
import {initialState} from "./types";

export const getUserView = createAsyncThunk(
  "getUserView",
  async (_, {rejectWithValue}) => {
    try {
      const response = await ticketsService.userView();
      const {data} = response;
      return data;
    } catch (/** @type {any} */ e) {
      return rejectWithValue(e);
    }
  },
);
export const user = createAsyncThunk("user", async (params, {rejectWithValue}) => {
  try {
    const response = await ticketsService.user(params);
    const {data} = response;
    return data;
  } catch (/** @type {any} */ e) {
    return rejectWithValue(e);
  }
});

export const getCompanyView = createAsyncThunk(
  "getCompanyView",
  async (_, {rejectWithValue}) => {
    try {
      const response = await ticketsService.companyView();
      const {data} = response;
      return data;
    } catch (/** @type {any} */ e) {
      return rejectWithValue(e);
    }
  },
);
export const company = createAsyncThunk("company", async (params, {rejectWithValue}) => {
  try {
    const response = await ticketsService.company(params);
    const {data} = response;
    return data;
  } catch (/** @type {any} */ e) {
    return rejectWithValue(e);
  }
});

export const getCurrentTicket = createAsyncThunk(
  "getCurrentTicket",
  async ({id, currentService}, {rejectWithValue}) => {
    try {
      const response = await ticketsService.ticket({
        service: currentService,
        ticketId: id,
      });
      const {data} = response;
      return data;
    } catch (/** @type {any} */ e) {
      return rejectWithValue(e);
    }
  },
);

export const count = createAsyncThunk("count", async (params, {rejectWithValue}) => {
  try {
    const {data} = await ticketsService.count(params);
    return data;
  } catch (e) {
    return rejectWithValue(e);
  }
});

export const ticketsSlice = createSlice({
  name: "ticketsSlice",
  initialState,
  reducers: {
    ...resetState,
    removeCurrentService(state) {
      state.currentService = "";
    },
    setCurrentService(state, action) {
      state.currentService = action.payload;
    },
    setPrevCurrentPath(state, action) {
      state.prevCurrentPath = action.payload;
    },
    setFilters(state, action) {
      const {field, type, value} = action.payload;
      if (!(field in state.filters) && type === CHECKBOX_TYPE) {
        state.filters[field] = [];
      }
      switch (type) {
        case CHECKBOX_TYPE:
          if (state.filters[field].includes(value)) {
            state.filters[field] = [...state.filters[field].filter((v) => v !== value)];
            break;
          }
          state.filters[field].push(value);
          break;
        default:
          state.filters[field] = value;
      }
    },
    setIsDesc(state, action) {
      state.isDesc = action.payload;
    },
    clearFilters(state) {
      state.filters = {};
    },
  },
  extraReducers: (builder) =>
    builder
      //getUserView
      .addCase(getUserView.fulfilled, (state, action) => {
        state.isLoading = false;
        state.tikets = action.payload.data;
        state.totalCount = action.payload.totalCount;
        state.themes = action.payload.themes;
        state.statuses = action.payload.statuses;
      })
      .addCase(getUserView.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserView.rejected, (state, action) => {
        state.isLoading = false;
        state.dataError = action.payload;
      }) //getCurrentTicket
      .addCase(getCurrentTicket.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(getCurrentTicket.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCurrentTicket.rejected, (state, action) => {
        state.isLoading = false;
        state.dataError = action.payload;
      })
      //user
      .addCase(user.fulfilled, (state, action) => {
        state.isLoading = false;
        state.tikets = action.payload.data;
        state.totalCount = action.payload.totalCount;
      })
      .addCase(user.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(user.rejected, (state, action) => {
        state.isLoading = false;
        state.dataError = action.payload;
      })
      //getCompanyView
      .addCase(getCompanyView.fulfilled, (state, action) => {
        state.isLoading = false;
        state.tikets = action.payload.data;
        state.totalCount = action.payload.totalCount;
        state.themes = action.payload.themes;
        state.statuses = action.payload.statuses;
      })
      .addCase(getCompanyView.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCompanyView.rejected, (state, action) => {
        state.isLoading = false;
        state.dataError = action.payload;
      })
      //company
      .addCase(company.fulfilled, (state, action) => {
        state.isLoading = false;
        state.tikets = action.payload.data;
        state.totalCount = action.payload.totalCount;
      })
      .addCase(company.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(company.rejected, (state, action) => {
        state.isLoading = false;
        state.dataError = action.payload;
      })
      //count
      .addCase(count.fulfilled, (state, action) => {
        state.isLoadingPre = false;
        state.preTotalCount = action.payload.totalCount;
      })
      .addCase(count.pending, (state) => {
        state.isLoadingPre = true;
      })
      .addCase(count.rejected, (state) => {
        state.isLoadingPre = false;
        state.preTotalCount = null;
      }),
});

export const {
  removeCurrentService,
  setCurrentService,
  setFilters,
  setIsDesc,
  clearFilters,
  setPrevCurrentPath,
} = ticketsSlice.actions;

export default ticketsSlice.reducer;
