import {api} from "RTKQuery/api";
import {
  UsersV2ChangePasswordPostApiArg,
  UsersV2ChangePasswordPostApiResponse,
  UsersV2MeGetApiArg,
  UsersV2MeGetApiResponse,
  UsersV2MePatchApiArg,
  UsersV2MePatchApiResponse,
  UsersV2GetAfstGetApiResponse,
  UsersV2GetAfstGetApiArg,
} from "RTKQuery/types/userApi";
import {setUserData} from "../lib/utils/setUserData";

export const addTagTypes = ["user", "afst"] as const;

export const userCustomApi = api.enhanceEndpoints({addTagTypes}).injectEndpoints({
  endpoints: (build) => ({
    usersV2MeGet: build.query<UsersV2MeGetApiResponse, UsersV2MeGetApiArg>({
      query: () => ({url: `/users/v2/me`}),
      providesTags: ["user"],
      keepUnusedDataFor: 3600,
      async onQueryStarted(_, {dispatch, queryFulfilled}) {
        try {
          const {data} = await queryFulfilled;
          await setUserData(dispatch, data);
        } catch {
          console.log("setUserData", "- fail");
        }
      },
    }),
    usersV2MePatch: build.mutation<UsersV2MePatchApiResponse, UsersV2MePatchApiArg>({
      query: (queryArg) => ({
        url: `/users/v2/me`,
        method: "PATCH",
        body: queryArg.editProfileRequest,
      }),
      invalidatesTags: ["user", "afst"],
    }),
    usersV2ChangePasswordPost: build.mutation<
      UsersV2ChangePasswordPostApiResponse,
      UsersV2ChangePasswordPostApiArg
    >({
      query: (queryArg) => ({
        url: `/users/v2/change-password`,
        method: "POST",
        body: queryArg.changePasswordRequestSchema,
      }),
      invalidatesTags: ["user"],
    }),
    usersV2GetAfstGet: build.query<UsersV2GetAfstGetApiResponse, UsersV2GetAfstGetApiArg>(
      {
        query: () => ({url: `/users/v2/get_afst`}),
        providesTags: ["afst"],
      },
    ),
  }),
  overrideExisting: true,
});
