import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {WIDGET_NAMES} from "pages/Dashboard/components/bigWidgets/widgetNames";
import {companyService} from "shared/services/CompanyService/CompanyService";
import {PartisipantService} from "shared/services/PartisipantService";
import {tradeService} from "shared/services/TradeService/TradeService";
import {tradeHelper} from "./Trade.helper";
import {resetState} from "../../store/resetState";
import {initialState} from "./type";

export const getList = createAsyncThunk(
  "getList",
  async (_, {rejectWithValue, getState}: any) => {
    try {
      const body = {
        page: 1,
        display: getState().tradeSlice.display,
        sort_by: getState().tradeSlice.sort_by,
        onpage: 10, //tradeController.onpage,
        sort_trend: !getState().tradeSlice.sort_trend ? "desc" : "asc",
      };
      const {data} = await tradeService.getList(body);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const getListWithFilters = createAsyncThunk(
  "getListWithFilters",
  async (body, {rejectWithValue}) => {
    try {
      const {data} = await tradeService.getList(body);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const getListToDashboard = createAsyncThunk<
  any,
  {body; onSuccessFetch; onErrorFetch; changeList}
>(
  "getListToDashboard",
  async ({body, onSuccessFetch, onErrorFetch, changeList}, {rejectWithValue}) => {
    try {
      const {data} = await tradeService.getList(body);
      onSuccessFetch();
      return {...data, changeList};
    } catch (e) {
      onErrorFetch(e.response.data);
      return rejectWithValue(e);
    }
  },
);

export const getParticipationListToDashboard = createAsyncThunk<
  unknown,
  {body; onSuccessFetch; onErrorFetch; changeList}
>(
  "getParticipationListToDashboard",

  async ({body, onSuccessFetch, onErrorFetch, changeList}, {rejectWithValue}) => {
    try {
      const {data} = await PartisipantService.getListPartisipatnTickets(body);
      onSuccessFetch();
      return {...data, changeList};
    } catch (e) {
      onErrorFetch(e.response.data);
      return rejectWithValue(e);
    }
  },
);

export const disabled = createAsyncThunk("disabled", async (body, {rejectWithValue}) => {
  try {
    const {data} = await tradeService.disabled(body);
    return data;
  } catch (e) {
    return rejectWithValue(e);
  }
});

export const getProcedure = createAsyncThunk(
  "getProcedure",
  async (procedureId, {rejectWithValue, dispatch}) => {
    try {
      const {data} = await tradeService.procedures(procedureId);
      return data;
    } catch (e) {
      const response = tradeHelper.handlerError(e.response || e, dispatch);
      return rejectWithValue(response);
    }
  },
);

export const lots = createAsyncThunk("lots", async (procedureId, {rejectWithValue}) => {
  try {
    const {data} = await tradeService.lots(procedureId);
    await new Promise((resolve) => {
      setTimeout(resolve, 300);
    });
    return data;
  } catch (e) {
    return rejectWithValue(e);
  }
});

export const getCompanyInfo = createAsyncThunk(
  "getCompanyInfo",
  async (procedureId, {rejectWithValue}) => {
    try {
      const {data} = await companyService.companyInfo(procedureId);
      await new Promise((resolve) => {
        setTimeout(resolve, 300);
      });
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const dashboardProcedures = createAsyncThunk<any, boolean>(
  "dashboardProcedures",
  async (isConsumer, {rejectWithValue, dispatch}) => {
    try {
      if (isConsumer) {
        const {data} = await tradeService.dashboardProceduresCons();
        return data;
      } else {
        const {data} = await tradeService.dashboardProceduresSup();
        return data;
      }
    } catch (e) {
      const response = tradeHelper.handlerError(e.response || e, dispatch);
      return rejectWithValue(response);
    }
  },
);

export const dashboardProceduresTickets = createAsyncThunk(
  "dashboardProceduresTickets",
  async (_, {rejectWithValue, dispatch}) => {
    try {
      const {data} = await tradeService.dashboardProceduresTickets();
      return data;
    } catch (e) {
      const response = tradeHelper.handlerError(e.response || e, dispatch);
      return rejectWithValue(response);
    }
  },
);

export const cancelProcedure = createAsyncThunk<any, {id; onSuccess; onError; params}>(
  "cancelProcedure",
  async ({id, onSuccess, onError, params}, {rejectWithValue, dispatch}) => {
    try {
      const {data} = await tradeService.cancelProcedure({id, params});
      onSuccess();
      return data;
    } catch (e) {
      onError(e.response);
      const response = tradeHelper.handlerError(e.response || e, dispatch);
      return rejectWithValue(response);
    }
  },
);

export const tradeSlice = createSlice({
  name: "tradeSlice",
  initialState,

  reducers: {
    ...resetState,
    setProcedureId(state, {payload}) {
      state.procedureId = payload;
    },

    clearProcedure(state) {
      state.procedure = {};
    },
    setProcedure(state, action) {
      state.procedure = action.payload;
    },

    procedureStatus(state, action) {
      // @ts-ignore

      state.procedure.status = action.payload;
    },

    clearDashboard(state) {
      state.dashboard = {
        proceduresList: {list: [], types: []},
        participationList: {list: [], types: []},
      };
    },
  },

  extraReducers: (builder) =>
    builder
      .addCase(getList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.totalCount = action.payload.totalCount;
        state.procedureList = action.payload.data;

        state.types = action.payload.types;
        state.statuses = action.payload.statuses;
        state.formats = action.payload.formats;
      })
      .addCase(getList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getList.rejected, (state) => {
        state.isLoading = false;
        state.procedureList = [];
      })
      //getListWithFilters
      .addCase(getListWithFilters.fulfilled, (state, action) => {
        state.isLoading = false;
        state.totalCount = action.payload.totalCount;
        state.procedureList = action.payload.data;

        state.types = action.payload.types;
        state.statuses = action.payload.statuses;
        state.formats = action.payload.formats;
      })
      .addCase(getListWithFilters.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getListWithFilters.rejected, (state) => {
        state.isLoading = false;
        state.procedureList = [];
      })
      //disabled
      .addCase(disabled.fulfilled, (state, action) => {
        state.isLoadingPre = false;
        state.preTotalCount = action.payload.totalCount;
      })
      .addCase(disabled.pending, (state) => {
        state.isLoadingPre = true;
      })
      .addCase(disabled.rejected, (state) => {
        state.isLoadingPre = false;
        state.preTotalCount = null;
      })
      //getProcedure
      .addCase(getProcedure.fulfilled, (state, action) => {
        state.isLoading = false;
        state.procedure = action.payload;
      })
      .addCase(getProcedure.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProcedure.rejected, (state, action) => {
        tradeHelper.handleRejectedResponse(state, action);
        // state.procedure = {};
      })

      //lots
      .addCase(lots.fulfilled, (state, action) => {
        state.isLoadingLots = false;
        const proc = state.procedureList.find(
          // @ts-ignore

          (procedure) => procedure.id === action.payload.id,
        ); // @ts-ignore

        proc["lots"] = action.payload.lots;
      })
      .addCase(lots.pending, (state) => {
        state.isLoadingLots = true;
      })
      .addCase(lots.rejected, (state) => {
        state.isLoadingLots = false;
      })
      //getCompanyInfo
      .addCase(getCompanyInfo.fulfilled, (state, action) => {
        state.isLoadingCompany = false; // @ts-ignore

        state.procedure.companyInfo = action.payload;
      })
      .addCase(getCompanyInfo.pending, (state) => {
        state.isLoadingCompany = true;
      })
      .addCase(getCompanyInfo.rejected, (state) => {
        state.isLoadingCompany = false; // @ts-ignore

        state.procedure.companyInfo = null;
      })
      //dashboardProceduresTickets
      .addCase(dashboardProceduresTickets.fulfilled, (state, action) => {
        state.dashboardLoadings = state.dashboardLoadings.filter(
          // @ts-ignore

          (widgetName) => widgetName !== WIDGET_NAMES.REQUEST,
        ); // @ts-ignore

        state.dashboard.request = action.payload;
      })
      .addCase(dashboardProceduresTickets.pending, (state) => {
        // @ts-ignore

        state.dashboardLoadings.push(WIDGET_NAMES.REQUEST);
      })
      .addCase(dashboardProceduresTickets.rejected, (state) => {
        state.dashboardLoadings = state.dashboardLoadings.filter(
          // @ts-ignore

          (widgetName) => widgetName !== WIDGET_NAMES.REQUEST,
        ); // @ts-ignore

        state.dashboard.request = null;
      })
      //dashboardProcedures
      .addCase(dashboardProcedures.fulfilled, (state, action) => {
        state.dashboardLoadings = state.dashboardLoadings.filter(
          // @ts-ignore

          (widgetName) => widgetName !== WIDGET_NAMES.PROCEDURES,
        ); // @ts-ignore

        state.dashboard.procedures = action.payload;
      })
      .addCase(dashboardProcedures.pending, (state) => {
        // @ts-ignore

        state.dashboardLoadings.push(WIDGET_NAMES.PROCEDURES);
      })
      .addCase(dashboardProcedures.rejected, (state) => {
        state.dashboardLoadings = state.dashboardLoadings.filter(
          // @ts-ignore

          (widgetName) => widgetName !== WIDGET_NAMES.PROCEDURES,
        ); // @ts-ignore

        state.dashboard.procedures = null;
      })
      //cancelProcedure
      .addCase(cancelProcedure.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(cancelProcedure.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(cancelProcedure.rejected, (state) => {
        state.isLoading = false;
      })
      //getListToDashboard
      .addCase(getListToDashboard.fulfilled, (state, action) => {
        state.dashboardLoadings = state.dashboardLoadings.filter(
          // @ts-ignore

          (widgetName) => widgetName !== WIDGET_NAMES.PROCEDURES_LIST,
        );
        if (action.payload.changeList) {
          state.dashboard.proceduresList.list = action.payload.data;
        } else {
          // @ts-ignore

          state.dashboard.proceduresList.list.push(...action.payload.data);
        } // @ts-ignore

        state.dashboard.proceduresList.totalPages = action.payload.totalPages;
        state.dashboard.proceduresList.types = action.payload.types;
      })
      .addCase(getListToDashboard.pending, (state) => {
        // @ts-ignore

        state.dashboardLoadings.push(WIDGET_NAMES.PROCEDURES_LIST);
      })
      .addCase(getListToDashboard.rejected, (state) => {
        state.dashboardLoadings = state.dashboardLoadings.filter(
          // @ts-ignore

          (widgetName) => widgetName !== WIDGET_NAMES.PROCEDURES_LIST,
        );
        state.dashboard.proceduresList.list = []; // @ts-ignore

        state.dashboard.proceduresList.totalPages = null;
      })
      //getParticipationListToDashboard
      .addCase(getParticipationListToDashboard.fulfilled, (state, action) => {
        state.dashboardLoadings = state.dashboardLoadings.filter(
          // @ts-ignore

          (widgetName) => widgetName !== WIDGET_NAMES.PROCEDURES_LIST,
        ); // @ts-ignore

        if (action.payload.changeList) {
          // @ts-ignore

          state.dashboard.participationList.list = action.payload.data;
        } else {
          // @ts-ignore

          state.dashboard.participationList.list.push(...action.payload.data);
        } // @ts-ignore

        state.dashboard.participationList.totalPages = action.payload.totalPages; // @ts-ignore

        state.dashboard.participationList.types = action.payload.types;
      })
      .addCase(getParticipationListToDashboard.pending, (state) => {
        // @ts-ignore

        state.dashboardLoadings.push(WIDGET_NAMES.PROCEDURES_LIST);
      })
      .addCase(getParticipationListToDashboard.rejected, (state) => {
        state.dashboardLoadings = state.dashboardLoadings.filter(
          // @ts-ignore

          (widgetName) => widgetName !== WIDGET_NAMES.PROCEDURES_LIST,
        );
        state.dashboard.participationList.list = []; // @ts-ignore

        state.dashboard.participationList.totalPages = null;
      }),
});

export const {
  clearProcedure,
  setProcedure,
  procedureStatus,
  clearDashboard,
  setProcedureId,
} = tradeSlice.actions;

export default tradeSlice.reducer;
