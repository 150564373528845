import {Components, Theme} from "@mui/material/styles";
import {palette} from "../paleteOverrides/paleteOverrides";
// const autoFillStyles = {
//   boxShadow: "none",
//   WebkitBoxShadow: "0 0 0 1000px white inset",
// } as const;

export const muiInput: Components<Theme>["MuiInput"] = {
  defaultProps: {},
  styleOverrides: {
    input: ({theme}) => ({
      height: "100%",
      padding: "14px",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "normal",
      boxSizing: "border-box",
      "::placeholder": {
        color: "#9B9B9A",
      },
      ":disabled": {
        color: theme?.palette.text.disabled,
      },
      "&:-webkit-autofill": {
        boxShadow: "none",
        WebkitBoxShadow: `0 0 0 1000px ${theme.palette.backgroundBlock.default} inset`,
      },
      // "&:-webkit-autofill:hover": autoFillStyles,
      // "&:-webkit-autofill:focus": autoFillStyles,
      // "&:-webkit-autofill:active": autoFillStyles,
    }),
    error: ({theme}) => ({
      borderColor: theme?.palette.error.main,
    }),
    root: ({theme}) => ({
      overflow: "hidden",
      border: `1px solid ${palette.border.main}`,
      borderRadius: "4px",
      height: "42px",
      "::before": {
        display: "none",
      },
      ":after": {
        display: "none",
      },
      ":hover": {
        border: `1px solid ${theme.palette.primary.main}`,
      },

      "&.Mui-focused": {
        border: `1px solid ${palette.primary.main}`,
      },

      "& .MuiOutlinedInput-root:hover": {
        "& > fieldset": {
          borderColor: theme.palette.primary.main,
        },
      },
    }),
  },
};
