import {useLayoutEffect, useState} from "react";
import {userCustomApi} from "entities/user";
import {useDebounce} from "../useDebounce";
import {useAppSelector} from "../useAppSelector";

/**
 * Алгоритм инициализации приложения
 * -1- Инициализация BroadcastChanel, Определение главной вкладки
 * -2- Получение пользователя (понимаем авторизован или нет)
 * -3- Получение соединения по WS --> перенес в useMainTabListener
 */

/**
 * Инициализация приложения
 */
export const useInitApp = () => {
  const {userData} = useAppSelector((store) => store.UserRegisterSlice);
  const {isMainApp} = useAppSelector((store) => store.broadcastChannelSlice);
  const [isInit, setIsInit] = useState(false);
  const [triggerUserData] = userCustomApi.useLazyUsersV2MeGetQuery({
    pollingInterval: 1000 * 3600, //раз в час обновляем данные
  });
  const observableValue = useDebounce(isInit, 1000);

  useLayoutEffect(() => {
    // -1-
    if (!isMainApp || userData) {
      setIsInit(true);
      return;
    }
    // -2-
    triggerUserData(undefined, true)
      .then((res) => {
        setIsInit(true);
        if ("error" in res) {
          console.log("Пользователь не авторизован");
          return;
        }
        // -3-
        setIsInit(true);
        // if (!isMainApp) return;
        // dispatch(setIsMainTabApp(true));
        // data && dispatch(setUser(data)); -> onQueryStarted есть dispatch->setUser->data
        // webSocketConnectV2();
        // TODO реавлизвать
        // dispatch(onLogin());
        console.log("Пользователь загружен");
      })
      .catch(() => setIsInit(true));
  }, [isMainApp]);

  return {isLoading: !observableValue};
};
