import {ComponentType} from "react";
import {Provider} from "react-redux";
import {store} from "shared/store/store";

const WithStoreProvider = (Component: ComponentType) => {
  // console.log(store.getState());
  // debugger;
  return (
    <Provider store={store}>
      <Component />
    </Provider>
  );
};
export {WithStoreProvider};
