import {PayloadAction, createSlice} from "@reduxjs/toolkit";
import {generateActionsForSync} from "shared/utils/generateActionsForSync";
import {initialStateChat} from "./initialState_draft";
import {
  TChat,
  TChatMenuType,
  TChatMessage,
  TChatType,
  TChatWithUserType,
  TInitialStateChat,
  TInvitationRequestChat,
} from "./types";
import {
  ArchiveApiArg,
  ChatCreatedMessage,
  ClosedApiResponse,
  DirectCreateSearchApiResponse,
  GetArchivedChatsApiResponse,
  GetBlacklistChatsApiResponse,
  GetChatsApiResponse,
  GetMediaApiResponse,
  GetMessagesApiResponse,
  GetUnreadMessagesCntApiResponse,
  GroupCreateSearchApiResponse,
  InProtocolApiResponse,
  IsReadApiResponse,
  MeetingCreateApiResponse,
  MeetingCreateSearchApiResponse,
  MeetingInviteAcceptApiResponse,
  MessageSendApiResponse,
  OpenedApiResponse,
  UserBlacklistApiArg,
} from "RTKQuery/types/chatApi";

export const chatSlice = createSlice({
  name: "chatSlice",
  initialState: initialStateChat,
  reducers: {
    // ...resetState,
    clearChatsState: () => initialStateChat,
    setChatsIsInit: (state, {payload}: PayloadAction<boolean>) => {
      state.isInit = payload;
    },
    assignChatsState: (state, {payload}) => {
      Object.assign(state, payload);
    },
    setUserId: (state, {payload}: PayloadAction<{message: {userId?: string}}>) => {
      state.userId = payload.message?.userId || "";
    },
    setUnreadMessagesCnt: (
      state,
      {payload}: PayloadAction<GetUnreadMessagesCntApiResponse["message"]>,
    ) => {
      state.GetUnreadMessagesCnt = payload.unreadMessagesCnt;
    },
    /** первоначальная инициализация store для чатов и обновление списка чатов при скролле  */
    setChats: (state, {payload}: PayloadAction<GetChatsApiResponse["message"]>) => {
      state.GetChats.chats.push(...payload.chats);
      state.GetChats.users.push(...payload.users);
    },
    /** добавление чата в начало списка чатов при добавлении нового и при получении сообщения из незагруженного чата  */
    prependChats: (state, {payload}: PayloadAction<ChatCreatedMessage["message"]>) => {
      state.GetChats.chats.unshift(payload.chat);
      state.GetChats.users.unshift(...payload.users);
    },
    // событие пользователь в сети
    setUserIsOnline: (
      state,
      {
        payload,
      }: PayloadAction<{
        user: OpenedApiResponse["message"] | ClosedApiResponse["message"];
        online: boolean;
      }>,
    ) => {
      const newUsersArr = state.GetChats.users.map((user) =>
        user.id === payload.user.userId
          ? {...user, online: {...user.online, isOnline: payload.online}}
          : user,
      );
      state.GetChats = {...state.GetChats, users: newUsersArr};
    },
    setArchivedChats: (
      state,
      {payload}: PayloadAction<GetArchivedChatsApiResponse["message"]>,
    ) => {
      state.GetArchivedChats = {chats: payload.chats, users: payload.users};
    },
    setBlackListChats: (
      state,
      {payload}: PayloadAction<GetBlacklistChatsApiResponse["message"]>,
    ) => {
      state.GetBlackListChats = {chats: payload.chats, users: payload.users};
    },
    setChatMessages: (
      state,
      {payload}: PayloadAction<GetMessagesApiResponse["message"]>,
    ) => {
      const chatId = payload[0]?.chatId;
      if (!chatId) return;
      state.GetMessages[chatId] = [...payload, ...(state.GetMessages[chatId] || [])];
    },
    /** добавление / исключение сообщения в / из протокола */
    triggerChatMessageInProtocol: (
      state,
      {payload}: PayloadAction<InProtocolApiResponse["message"]>,
    ) => {
      if (state.GetMessages[payload.chatId]?.length) {
        const messageInd = state.GetMessages[payload.chatId].findIndex(
          ({id}) => id === payload.messageId,
        );
        state.GetMessages[payload.chatId][messageInd].inProtocol = payload.value;
      }
    },
    // метод запрещающий прокручивание при нескольких непрочитанных сообщениях
    setChatScrollState: (state, {payload}: PayloadAction<boolean>) => {
      state.GetMessagesScroll = payload;
    },
    setChatMedia: (state, {payload}: PayloadAction<GetMediaApiResponse["message"]>) => {
      state.GetMedia = payload;
    },
    // отправка сообщения в чат
    sendNewMessageToChat: (
      state,
      {payload}: PayloadAction<MessageSendApiResponse["message"]>,
    ) => {
      state.GetMessages[payload.chatId].push(payload as TChatMessage);
      const chatInd = state.GetChats.chats.findIndex(({id}) => id === payload.chatId);
      state.GetChats.chats[chatInd].lastMessage = payload;
      state.GetChats.chats.unshift(state.GetChats.chats.splice(chatInd, 1)[0]);
      state.GetMessagesScroll = true;
    },
    // получение нового сообщения в чате
    setNewMessageInChat: (
      state,
      {payload}: PayloadAction<MessageSendApiResponse["message"]>,
    ) => {
      // обновление списка сообщений
      if (state.GetMessages[payload.chatId]?.length) {
        // state.GetMessages = [...(state.GetMessages || []), payload];
        state.GetMessages[payload.chatId].push(payload as TChatMessage);
      }
      // обновление статуса сообщения в обозначении чата
      const chatInd = state.GetChats.chats.findIndex(({id}) => id === payload.chatId);
      const chatByInd = state.GetChats.chats[chatInd];
      state.GetChats.chats[chatInd].lastMessage = payload;
      state.GetChats.chats[chatInd].unreadMessagesCnt =
        payload.userId === state.userId || state.currentChat === payload.chatId
          ? chatByInd.unreadMessagesCnt
          : chatByInd.unreadMessagesCnt + 1;
      // state.GetChats.chats.splice(chatInd, 1).unshift(newChatItem);
      state.GetChats.chats.unshift(state.GetChats.chats.splice(chatInd, 1)[0]);
      // state.GetChats.chats.splice(chatInd, 1).unshift(newChatItem);
      state.GetMessagesScroll = true;
    },
    // событие успешной доставки сообщения на сервер
    setMessageSendSuccess: (
      state,
      {payload}: PayloadAction<MessageSendApiResponse["message"]>,
    ) => {
      // обновление статуса сообщения в обозначении чата
      const chatInd = state.GetChats.chats.findIndex(({id}) => id === payload.chatId);
      // обновление сообщения в списке чатов
      if (
        state.GetChats.chats[chatInd]?.lastMessage &&
        state.GetChats.chats[chatInd].lastMessage.id === payload.messageId
      ) {
        state.GetChats.chats[chatInd].lastMessage.isDelivered = true;
        state.GetMessagesScroll = true;
      }
      // обновление статуса сообщения в переписке
      if (!state.GetMessages[payload.chatId]?.length) return;
      const messageIndex = state.GetMessages[payload.chatId].findLastIndex(
        ({id}) => id === payload.id || id === payload.messageId,
      );
      if (state.GetMessages[payload.chatId][messageIndex]?.isDelivered !== undefined)
        state.GetMessages[payload.chatId][messageIndex].isDelivered = true;
    },
    // событие прочтения сообщения собеседником или собой
    setMessageIsRead: (state, {payload}: PayloadAction<IsReadApiResponse["message"]>) => {
      // обновление статуса сообщения в обозначении чата
      const chatInd = state.GetChats.chats.findIndex(({id}) => id === payload.chatId);
      if (chatInd === -1) return;
      const currentChatUnreadMessages = state.GetChats.chats[chatInd]?.unreadMessagesCnt;
      // обновление сообщения в списке чатов
      state.GetChats.chats[chatInd].lastMessage.isRead = true;
      state.GetChats.chats[chatInd].lastMessage.isDelivered = true;
      // обновление количества непрочитанных сообщений в списке чатов
      if (currentChatUnreadMessages && currentChatUnreadMessages > 0)
        state.GetChats.chats[chatInd].unreadMessagesCnt = currentChatUnreadMessages - 1;

      // обновление статуса сообщения в переписке
      if (!state.GetMessages[payload.chatId]?.length) return;
      const messageIndex = state.GetMessages[payload.chatId].findLastIndex(
        ({id}) => id === payload.messageId,
      );
      if (state.GetMessages[payload.chatId][messageIndex]?.isRead !== undefined)
        state.GetMessages[payload.chatId][messageIndex].isRead = true;
      if (state.GetMessages[payload.chatId][messageIndex]?.isRead !== undefined)
        state.GetMessages[payload.chatId][messageIndex].isRead = true;
    },
    setCurrentChat: (state, {payload}: PayloadAction<TChat | undefined>) => {
      state.currentChat = payload;
    },
    setCurrentChatMenu: (state, {payload}: PayloadAction<TChatWithUserType>) => {
      state.currentChatMenu = payload;
    },
    /** для переключения между чатами (текущие, архив, ЧС) */
    setCurrentMainMenu: (state, {payload}: PayloadAction<TChatMenuType>) => {
      state.currentMainMenu = payload;
    },
    setFullscreen: (state, {payload}: PayloadAction<boolean>) => {
      state.isFullScreen = payload;
    },
    setMobileView: (state, {payload}: PayloadAction<boolean>) => {
      state.isMobileView = payload;
    },
    setOpenChat: (state, {payload}: PayloadAction<boolean>) => {
      state.isOpenChat = payload;
    },
    setFindTextFiles: (state, {payload}: PayloadAction<string>) => {
      state.findChatsFileText = payload;
    },
    setMuteChat: (state, {payload}: PayloadAction<boolean>) => {
      state.isMuteChat = payload;
    },
    setCreateChat: (state, {payload}: PayloadAction<TChatType>) => {
      state.typeCreateState = payload;
    },
    setFindChats: (state, {payload}: PayloadAction<string>) => ({
      ...state,
      findChatsText: payload,
    }),
    setChatLoaders: (
      state,
      {payload}: PayloadAction<Partial<TInitialStateChat["loaders"]>>,
    ) => {
      state.loaders = {...state.loaders, ...payload};
    },
    setDirectSearchUsers: (
      state,
      {payload}: PayloadAction<Partial<DirectCreateSearchApiResponse["message"]>>,
    ) => {
      state.DirectSearch = payload.users || [];
    },
    setGroupSearchUsers: (
      state,
      {payload}: PayloadAction<Partial<GroupCreateSearchApiResponse["message"]>>,
    ) => {
      state.GroupSearch = payload.users || [];
    },
    setMeetingSearchUsers: (
      state,
      {payload}: PayloadAction<MeetingCreateSearchApiResponse["message"]>,
    ) => {
      state.MeetingSearch = payload || [];
    },
    // для хука открытия чата из любого места на площадке
    setLastCreatedChat: (
      state,
      {payload}: PayloadAction<MeetingCreateApiResponse["message"] | undefined>,
    ) => {
      state.lastCreatedChat = payload?.chat;
    },
    /** для загрузки предыдущих сообщений и запрета скролла до предыдущих */
    updateLastMessageInChat: (state, {payload}: PayloadAction<boolean>) => {
      if (payload && state.currentChat) {
        if (state.GetMessages[state.currentChat.id][0]?.id < 3) return;
        state.lastRededMessage = state.GetMessages[state.currentChat.id][0]?.id;
      } else {
        state.lastRededMessage = undefined;
      }
    },
    /** получение приглашения в чат */
    receiveInvitationRequestInChat: (
      state,
      {payload}: PayloadAction<TInvitationRequestChat["message"]>,
    ) => {
      state.GetChats = {
        chats: [payload.chat, ...state.GetChats.chats],
        users: [...payload.users, ...state.GetChats.users],
      };
    },
    /** принял приглашение в чат / группу / переговоры */
    setInviteAccepted: (
      state,
      {payload}: PayloadAction<MeetingInviteAcceptApiResponse["message"]>,
    ) => {
      let chatInd = state.GetChats.chats.findIndex(({id}) => id === payload.chatId);
      state.GetChats.chats[chatInd].invitationState = "ACCEPT";
      state.currentChat = state.GetChats.chats[chatInd];
    },
    /** отклонил приглашение в чат / группу / переговоры */
    setInviteRejected: (
      state,
      {payload}: PayloadAction<MeetingInviteAcceptApiResponse["message"]>,
    ) => {
      let chatInd = state.GetChats.chats.findIndex(({id}) => id === payload.chatId);
      state.GetChats.chats.splice(chatInd, 1);
    },
    /** отправить чат в архив или вернуть из архива */
    setArchive: (
      state,
      {payload}: PayloadAction<ArchiveApiArg["httpChatArchiveDto"]["message"]>,
    ) => {
      // если этот чат сейчас открыт
      if (payload.chatId === state.currentChat?.id) {
        state.currentChat = undefined;
      }
      // если чат отправляется в архив
      if (payload.value) {
        // из общего списка
        const chatInd = state.GetChats.chats.findIndex(({id}) => id === payload.chatId);
        if (chatInd !== -1) {
          state.GetChats.chats.splice(chatInd, 1);
        } else {
          // из ЧС
          const blackListChatInd = state.GetBlackListChats.chats.findIndex(
            ({id}) => id === payload.chatId,
          );
          blackListChatInd !== -1 &&
            (state.GetBlackListChats.chats[blackListChatInd].inArchive = true);
        }
      } else {
        // если мы из вкладки архивных чатов убираем из архива
        let chatInd = state.GetArchivedChats.chats.findIndex(
          ({id}) => id === payload.chatId,
        );
        if (chatInd !== -1) {
          const archivedChat = state.GetArchivedChats.chats[chatInd];
          state.GetArchivedChats.chats.splice(chatInd, 1);
          !archivedChat.userInBlackList &&
            state.GetChats.chats.unshift({...archivedChat, inArchive: false});
        } else {
          // из ЧС
          const blackListChatInd = state.GetBlackListChats.chats.findIndex(
            ({id}) => id === payload.chatId,
          );
          blackListChatInd !== -1 &&
            (state.GetBlackListChats.chats[blackListChatInd].inArchive = false);
        }
      }
    },
    /** отправить чат в ЧС или вернуть из ЧС */
    setBlackList: (
      state,
      {
        payload,
      }: PayloadAction<UserBlacklistApiArg["httpChatUserBlacklistDto"]["message"]>,
    ) => {
      // если этот чат сейчас открыт
      if (payload.chatId === state.currentChat?.id) {
        state.currentChat = undefined;
      }
      // если чат отправляется в ЧС
      if (payload.value) {
        // из общего списка
        let chatInd = state.GetChats.chats.findIndex(({id}) => id === payload.chatId);
        if (chatInd !== -1) {
          state.GetChats.chats.splice(chatInd, 1);
        } else {
          // из архива
          const archiveChatInd = state.GetArchivedChats.chats.findIndex(
            ({id}) => id === payload.chatId,
          );
          archiveChatInd !== -1 &&
            (state.GetArchivedChats.chats[archiveChatInd].userInBlackList = true);
        }
      } else {
        // если мы из вкладки ЧС убираем из ЧС
        let chatInd = state.GetBlackListChats.chats.findIndex(
          ({id}) => id === payload.chatId,
        );
        if (chatInd !== -1) {
          const blackListChat = state.GetBlackListChats.chats[chatInd];
          state.GetBlackListChats.chats.splice(chatInd, 1);
          !blackListChat.inArchive &&
            state.GetChats.chats.unshift({...blackListChat, userInBlackList: false});
        } else {
          // из архива
          const archivedChat = state.GetArchivedChats.chats.findIndex(
            ({id}) => id === payload.chatId,
          );
          archivedChat !== -1 &&
            (state.GetArchivedChats.chats[archivedChat].userInBlackList = false);
        }
      }
    },
    deleteChatById: (state, {payload}: PayloadAction<TChat["id"]>) => {
      // если этот чат сейчас открыт
      if (payload === state.currentChat?.id) {
        state.currentChat = undefined;
      }
      let chatInd = state.GetChats.chats.findIndex(({id}) => id === payload);
      state.GetChats.chats.splice(chatInd, 1);
    },
    /** отправка файлов путем дропа в переписке */
    setDroppedFile: (
      state,
      {payload}: PayloadAction<TInitialStateChat["droppedFiles"]>,
    ) => {
      state.droppedFiles = payload;
    },
  },
});

export const {
  clearChatsState,
  setChatsIsInit,
  // chatsInit,
  assignChatsState,
  setUserId,
  setUnreadMessagesCnt,
  setChats,
  prependChats,
  setUserIsOnline,
  setArchivedChats,
  setBlackListChats,
  setChatMessages,
  triggerChatMessageInProtocol,
  setChatScrollState,
  setChatMedia,
  // onMessageChat,
  setCurrentChat,
  setCurrentChatMenu,
  setCurrentMainMenu,
  setFullscreen,
  setMobileView,
  setOpenChat,
  // setBlackList,
  // setArchive,
  // setAssignStateChat,
  setFindTextFiles,
  setMuteChat,
  setCreateChat,
  setFindChats,
  setChatLoaders,
  sendNewMessageToChat,
  setNewMessageInChat,
  setMessageSendSuccess,
  setMessageIsRead,
  setDirectSearchUsers,
  setGroupSearchUsers,
  setMeetingSearchUsers,
  setLastCreatedChat,
  updateLastMessageInChat,
  receiveInvitationRequestInChat,
  setInviteAccepted,
  setInviteRejected,
  setArchive,
  setBlackList,
  deleteChatById,
  setDroppedFile,
} = chatSlice.actions;

export const chatSliceActions = generateActionsForSync(chatSlice, {
  blackList: [
    "setChatsIsInit",
    "chatsInit",
    "setMobileView",
    "setFullscreen",
    "setMobileView",
    "setOpenChat",
    // "setMuteChat",
    // "setChatMessages",
    "triggerChatMessageInProtocol",
    "setChatScrollState",
    "setChatMedia",
    // "sendNewMessageToChat",
    // "setNewMessageInChat",
    // "setMessageSendSuccess",
    // "setMessageIsRead",
    "setCurrentChat",
    "setCurrentChatMenu",
    "setCurrentMainMenu",
    "setFindTextFiles",
    "setCreateChat",
    "setFindChats",
    // "setChatLoaders",
    "setDirectSearchUsers",
    "setGroupSearchUsers",
    "setMeetingSearchUsers",
    "setLastCreatedChat",
    "updateLastMessageInChat",
    // "setInviteAccepted",
  ],
});

export default chatSlice.reducer;
