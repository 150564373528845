import {FC, PropsWithChildren} from "react";
import {useModal} from "../lib/useModal/useModal";
import {ModalContext} from "shared/hooks/useModalContext/ModalContext";
import {UI_Modal} from "shared/components/UI/UI_Modal";
import Button from "@mui/material/Button";

const ModalWrapper: FC<PropsWithChildren> = ({children}) => {
  const modalControl = useModal();
  const {open, titleText, handleClose} = modalControl;

  return (
    <ModalContext.Provider value={modalControl}>
      {children}

      <UI_Modal
        titleProps={{title: titleText}}
        sx={{maxWidth: "500px", display: "flex", alignItems: "center"}}
        modalProps={{open, onClose: handleClose}}
      >
        <Button variant="main" onClick={handleClose}>
          Закрыть
        </Button>
      </UI_Modal>
    </ModalContext.Provider>
  );
};

export {ModalWrapper};
