import {FC, PropsWithChildren} from "react";
import UI_Snackbar from "shared/components/UI/UI_Snackbar/UI_Snackbar";
import {SnackbarContext} from "shared/hooks/useSnakbarContext/SnackbarContext";
import {useSnackbar} from "../lib/useSnackbar/useSnackbar";

const SnackbarWrapper: FC<PropsWithChildren> = ({children}) => {
  const snackControl = useSnackbar();
  const {colorSnackbar, handleClose, open, textSnackbar, title} = snackControl;
  return (
    <SnackbarContext.Provider value={snackControl}>
      {children}

      <UI_Snackbar
        type={colorSnackbar}
        text={textSnackbar}
        onClose={handleClose}
        title={title}
        open={open}
      />
    </SnackbarContext.Provider>
  );
};

export {SnackbarWrapper};
