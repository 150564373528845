import {Components} from "@mui/material/styles";

export const muiAutocomplete: Components["MuiAutocomplete"] = {
  defaultProps: {
    noOptionsText: "Список пуст",
    openText: "Открыть",
    closeText: "Закрыть",
  },
  styleOverrides: {
    root: ({theme}) => ({
      padding: 0,
      "& .MuiOutlinedInput-root": {
        paddingTop: 0,
        paddingBottom: 0,
        ".MuiInputBase-input": {
          paddingTop: "4px",
          paddingBottom: "4px",
        },
        "#CloseIcon": {
          color: "red",
        },
      },
    }),
  },
};

// добавить красную кнопку удаления и подпись "удалить"
// https://stackoverflow.com/questions/64817614/how-to-add-edit-function-to-the-clear-button-icon-in-material-ui-autocomplete
