import Box, {BoxProps} from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal, {ModalProps} from "@mui/material/Modal";
import Typography, {TypographyProps} from "@mui/material/Typography";
import {useTheme} from "@mui/material/styles";
import {FC, ReactNode} from "react";
import Close from "@mui/icons-material/Close";

interface UI_ModalProps extends BoxProps {
  modalProps: Omit<ModalProps, "children">;
  titleProps?: {title: string} & TypographyProps;
  children: ReactNode;
  putCloseButton?: boolean;
}

const UI_Modal: FC<UI_ModalProps> = ({
  modalProps,
  children,
  titleProps,
  putCloseButton = true,
  ...props
}) => {
  const {palette} = useTheme();
  const {sx, ...rest} = props;
  return (
    <Modal disableAutoFocus {...modalProps}>
      <Box
        sx={[
          {
            color: palette.text.primary,
            backgroundColor: palette.backgroundBlock.default,
            borderRadius: 2,
            width: "100%",
            maxWidth: "400px",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -49.9%)",
            flexDirection: "column",
            display: "flex",
            p: "40px",
            maxHeight: "90vh",
            overflow: "auto",
            mx: 2,
            outline: "none",
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
        {...rest}
        data-testid="modal"
      >
        {putCloseButton && (
          <Button
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              backgroundColor: palette.primary.main,
              width: "40px",
              height: "40px",
              minWidth: "40px",
              borderRadius: "0 4px 0 0",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",

              "&:focus-visible": {
                outline: "none",
              },

              "&:hover": {
                backgroundColor: palette.red.main,
              },
            }}
            onClick={() => {
              modalProps?.onClose && modalProps?.onClose({}, "escapeKeyDown");
            }}
            data-testid="modal-close-button"
          >
            <Close sx={{color: palette.white.main}} />
          </Button>
        )}
        {titleProps ? (
          <Typography paragraph variant="h1-medium-20" {...titleProps} mb={6} data-testid="modal-title">
            {titleProps.title}
          </Typography>
        ) : null}
        {children}
      </Box>
    </Modal>
  );
};

export {UI_Modal};
