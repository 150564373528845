import {Components, Theme} from "@mui/material/styles";
import type {} from "@mui/x-date-pickers/themeAugmentation";
import {renderScrollStyle} from "shared/components/UI/UI_ScrollContainer/utils/ScrollStyle";

export const muiTimePicker: Components["MuiTimePicker"] = {
  defaultProps: {
    slotProps: {
      textField: {
        fullWidth: true,
        helperText: null,
      },
      layout: {
        sx: ({palette}) => ({
          ul: {
            ...renderScrollStyle(palette),

            li: {
              "&.Mui-selected": {
                background: `${palette.primary700.main} !important`,

                ":hover": {
                  background: `${palette.primary.main} !important`,
                },
              },
            },
          },
        }),
      },
    },
  },
};
