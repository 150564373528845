import RegisterOfCompaniesSlice from "shared/ReduxSlices/RegisterOfCompaniesSlice/RegisterOfCompaniesSlice";
import UserRegisterSlice from "shared/ReduxSlices/userSlice/userSlice";
import breadCrumbsSlice from "shared/ReduxSlices/breadCrumbsSlice";
import authSlice from "shared/ReduxSlices/authSlice/authSlice";
import catalogSlice from "shared/ReduxSlices/catologSlice/catologSlice";
import ticketsSlice from "shared/ReduxSlices/ticketsSlice/ticketsSlice";
import feedbackSlice from "shared/ReduxSlices/feedbackSlice/feedbackSlice";
import tradeSlice from "shared/ReduxSlices/tradeSlice/tradeSlice";
import chatSlice from "shared/ReduxSlices/chatSlice/chatSlice";
import WSSlice from "shared/ReduxSlices/webSocketSlice/WSSlice";
import notesSlice from "shared/ReduxSlices/notesSlice/notesSlice";
import broadcastChannelSlice from "shared/ReduxSlices/bcSlice/bcSlice";
import companyVerificationSlice from "shared/ReduxSlices/serviceSlices/companyVerificationSlice";
import notificationSlice from "shared/ReduxSlices/notificationsSlice/notificationsSlice";
import cacheSlice from "shared/ReduxSlices/cacheSlice";
import {api} from "RTKQuery/api";
import {userReducer} from "shared/ReduxSlices/srmUserSlice/userSRMSlice";

export const reducers = {
  RegisterOfCompaniesSlice,
  UserRegisterSlice,
  breadCrumbsSlice,
  authSlice,
  catalogSlice,
  ticketsSlice,
  feedbackSlice,
  tradeSlice,
  chatSlice,
  webSocketSlice: WSSlice,
  notesSlice,
  broadcastChannelSlice,
  companyVerificationSlice,
  notificationSlice,
  userSRMSlice: userReducer,
};

export const reducersForStore = {
  ...reducers,
  cache: cacheSlice,
  api: api.reducer,
};
