export interface ICatalogState {
  cashGoods: any;
  errorData: string;
  goodsIsLoading: boolean;
  charsIsLoadingCash: any;
  searchString: string;
  amountGoods: number;
}
export const initialState: ICatalogState = {
  cashGoods: {},
  errorData: "",
  goodsIsLoading: false,
  charsIsLoadingCash: {},
  searchString: "",
  amountGoods: 0,
};
