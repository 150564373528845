import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {companyService} from "shared/services/CompanyService/CompanyService";
import {counterAgentService} from "shared/services/CounterAgentService";
import {resetState} from "../../store/resetState";
import {initialState} from "./types";

export const getReqMini = createAsyncThunk(
  "getReqMini",
  async (/** @type {params:any,onError:any} */ {params, onError}, {rejectWithValue}) => {
    try {
      const {data} = await counterAgentService.reqMini(params);
      return data;
    } catch (e) {
      onError(e.response.data);
      return rejectWithValue(e.response.data);
    }
  },
);

export const findCompanyByInn = createAsyncThunk(
  "findCompanyByInn",
  async (params, {rejectWithValue}) => {
    try {
      if (params.search === "") {
        return [];
      }
      const {data} = await companyService.searchCompany(params);
      return data;
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  },
);
export const companyVerificationSlice = createSlice({
  name: "companyVerificationSlice",
  initialState,
  reducers: {
    ...resetState,
    clearReqMini(state) {
      state.reqMini = null;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getReqMini.fulfilled, (state, action) => {
        state.isLoading = false;
        state.reqMini = action.payload;
      })
      .addCase(getReqMini.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getReqMini.rejected, (state) => {
        state.isLoading = false;
        state.reqMini = null;
      })
      //findCompanyByInn
      .addCase(findCompanyByInn.fulfilled, (state, {payload}) => {
        state.isLoadingFindCompanies = false;
        state.registredCompanies = payload;
      })
      .addCase(findCompanyByInn.pending, (state) => {
        state.isLoadingFindCompanies = true;
      })
      .addCase(findCompanyByInn.rejected, (state) => {
        state.isLoadingFindCompanies = false;
        state.registredCompanies = [];
      }),
});

export const {clearReqMini} = companyVerificationSlice.actions;

export default companyVerificationSlice.reducer;
