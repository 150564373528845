import {PayloadAction, createSlice} from "@reduxjs/toolkit";
import {resetState} from "shared/store/resetState";
import {IStateUser, initialState} from "./user.types";
import {generateActionsForSync} from "shared/utils/generateActionsForSync";

export const UserRegisterSlice = createSlice({
  name: "UserRegisterSlice",
  initialState,
  reducers: {
    ...resetState,
    clearUserData: (state) => {
      state.userData = undefined;
    },
    setUser: (state, {payload}: PayloadAction<IStateUser["userData"]>) => {
      state.userData = payload;
    },
    // setIdForUserInfo: (state, action: PayloadAction<string | null>) => {
    //   state.idForUserInfo = action.payload;
    // },
    setUserCompanyIsSupplier: (state) => {
      if (!state.userData?.company) return;
      state.userData.company.supplier = true;
    },
    setUserCompanyIsCustomer: (state) => {
      if (!state.userData?.company) return;
      state.userData.company.customer = true;
      state.userData.company.srmAccess = true;
    },
  },
});

export const {
  clearUserData,
  setUser,
  setUserCompanyIsSupplier,
  setUserCompanyIsCustomer,
  // setIdForUserInfo,
} = UserRegisterSlice.actions;
export const userRegisterSliceActions = generateActionsForSync(UserRegisterSlice, {
  blackList: ["setUser", "setIdForUserInfo"],
  whiteList: ["clearUserData"],
});
export default UserRegisterSlice.reducer;
