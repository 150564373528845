import {Components, Theme} from "@mui/material/styles";

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    main: true;
    link: true;
    green: true;
    lightGreen: true;
    reject: true;
    red: true;
    block: true;
    clean: true;
    dashed: true;
    info: true;
    "green-hover-primary": true;
    "file-attach": true;
    preloader: true;
    orangeLight: true;
  }
}
export const muiButton: Components<Theme>["MuiButton"] = {
  variants: [
    {
      props: {variant: "main"},
      style: ({theme}) => ({
        backgroundColor: theme.palette.primary700.main,
        color: theme.palette.white.main,
        fontWeight: "500",
        ":hover": {
          backgroundColor: theme.palette.primary.main,
        },
        ":disabled": {
          background: "rgba(155, 155, 154, 0.40)",
          color: theme.palette.white.main,
        },
      }),
    },
    {
      props: {variant: "green"},
      style: ({theme}) => ({
        backgroundColor: theme.palette.green.main,
        color: theme.palette.white.main,
        ":hover": {
          backgroundColor: theme.palette.green.main,
        },
        ":active": {
          background: "#17A482",
        },
        ":disabled": {
          background: "rgba(155, 155, 154, 0.40)",
          color: theme.palette.white.main,
        },
      }),
    },
    {
      props: {variant: "lightGreen"},
      style: ({theme}) => ({
        background: theme.palette.green600.main,
        color: theme.palette.green.main,
      }),
    },
    {
      props: {variant: "reject"},
      style: ({theme}) => ({
        backgroundColor: theme.palette.red500.main,
        color: theme.palette.red.main,
        ":hover": {
          backgroundColor: theme.palette.red600.main,
        },
        ":active": {
          background: "#FFD3D8",
        },
        ":disabled": {
          background: "rgba(155, 155, 154, 0.40)",
          color: theme.palette.white.main,
        },
      }),
    },
    {
      props: {variant: "red"},
      style: ({theme}) => ({
        backgroundColor: theme.palette.red.main,
        color: theme.palette.white.main,
        ":hover": {
          backgroundColor: theme.palette.red.main,
        },
        ":active": {
          background: theme.palette.red.main,
        },
        ":disabled": {
          background: "rgba(155, 155, 154, 0.40)",
          color: theme.palette.white.main,
        },
      }),
    },
    {
      props: {variant: "block"},
      style: ({theme}) => ({
        backgroundColor: theme.palette.red.main,
        color: theme.palette.white.main,
        ":hover": {
          backgroundColor: theme.palette.red800.main,
        },
        ":active": {
          background: "#FFD3D8",
        },
        ":disabled": {
          background: theme.palette.black300.main,
          color: theme.palette.black500.main,
        },
      }),
    },
    {
      props: {variant: "text"},
      style: ({theme}) => ({
        color: theme.palette.text.primary,
      }),
    },
    {
      props: {variant: "link"},
      style: ({theme}) => ({
        color: theme.palette.primary.main,
        padding: 0,
        fontWeight: "500",
        ":hover": {
          textDecoration: "underline",
          backgroundColor: "inherit",
        },
        minHeight: "unset",
      }),
    },
    {
      props: {variant: "clean"},
      style: ({theme}) => ({
        backgroundColor: theme.palette.white.main,
        color: theme.palette.black.main,
        border: "1px solid rgb(155, 155, 154, 0.40)",
        ":active": {
          background: theme.palette.white.main,
        },
        ":disabled": {
          background: "rgba(155, 155, 154, 0.40)",
          color: theme.palette.white.main,
          border: "none",
        },
      }),
    },
    {
      props: {variant: "dashed"},
      style: ({theme}) => ({
        border: "1px dashed",
        borderColor: theme.palette.black400.main,
      }),
    },
    {
      props: {variant: "info"},
      style: ({theme}) => ({
        backgroundColor: theme.palette.primary100.main,
        color: theme.palette.primary.main,
        ":hover": {
          backgroundColor: theme.palette.primary100.main,
        },
        ":active": {
          background: "#17A482",
        },
        ":disabled": {
          background: "rgba(155, 155, 154, 0.40)",
          color: theme.palette.black500.main,
        },
      }),
    },
    {
      props: {variant: "green-hover-primary"},
      style: ({theme}) => ({
        backgroundColor: theme.palette.green.main,
        color: theme.palette.white.main,
        ":hover": {backgroundColor: theme.palette.primary.main},
        ":active": {background: "#17A482"},
        ":disabled": {
          background: "rgba(155, 155, 154, 0.40)",
          color: theme.palette.white.main,
        },
      }),
    },
    {
      props: {variant: "file-attach"},
      style: ({theme}) => ({
        border: `1px dashed ${theme.palette.border.main}`,
        minHeight: "unset",
        height: "65px",
        width: "100%",
        ":active": {background: theme.palette.primary300.main},
        ":disabled": {cursor: "auto"},
      }),
    },
    {
      props: {variant: "preloader"},
      style: ({theme}) => ({
        backgroundColor: "transparent",
        color: theme.palette.primary700.main,
        fontWeight: 500,
        fontSize: 14,
        lineHeight: "110%",
        ":hover": {
          textDecoration: "underline",
          backgroundColor: "transparent",
        },
        ".MuiLoadingButton-loadingIndicatorStart": {
          left: 6,
        },
      }),
    },
    {
      props: {variant: "orangeLight"},
      style: ({theme}) => ({
        backgroundColor: theme.palette.yellow600.main,
        border: `1px solid ${theme.palette.orange.main}`,
        color: theme.palette.orange.main,
        fontWeight: 400,
        fontSize: 14,
        lineHeight: "110%",
        ":hover": {backgroundColor: theme.palette.orange600.main},
        // ":disabled": {backgroundColor: theme.palette.orangeLight.main},
        ":active": {backgroundColor: theme.palette.orange600.main},
      }),
    },
  ],
  styleOverrides: {
    sizeSmall: {
      height: "30px",
      fontSize: "12px",
      minWidth: "64px",
      padding: "1px",
      minHeight: "unset",
    },
    sizeLarge: {
      minWidth: "160px",
    },
    root: ({theme}) => ({
      minHeight: "42px",
      textTransform: "none",
      fontSize: "13px",
      borderRadius: "8px",
      minWidth: "120px",
    }),
  },
};
