import {CommonPermissionActions} from "../../constants/userActionsForCheck";
import {CommonPermissions} from "../../constants/permissions";

export type TAction =
  (typeof CommonPermissionActions)[keyof typeof CommonPermissionActions][];

/**
 * Доступные действия по пермишенам
 */
export const rolePermissions: Partial<
  Record<(typeof CommonPermissions)[keyof typeof CommonPermissions], TAction>
> = {
  "company:edit_profile": [CommonPermissionActions.editCompanyProfile],
  "employees:update_profile": [CommonPermissionActions.editCompanyUserProfile],
  "company:keys:write": [CommonPermissionActions.companyKeysWrite], // возможность отправлять приглашения в компанию
};
