import {RouteObject} from "react-router-dom";
import {ROUTES} from "shared/constants/paths";

/**
 * Получить из ROUTES обьект типа RouteObject
 * нужен для matchRoutes и useMatch из "react-router-dom"
 */
export const createAppConfig = (prefix = ""): RouteObject[] => {
  const children: RouteObject[] = [];
  Object.entries(ROUTES).forEach(([_, v]) => {
    if (v !== ROUTES.HOME) {
      children.push({path: prefix + v});
    }
  });
  return children;
};
