
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import {FC} from "react";
import {fields} from "../../lib/constants/profileFields";
import {UI_Profile} from "shared/components/UI/UI_Profile/UI_Profile";
import {EmployeeProfileSemiPublic, RolesSplit} from "RTKQuery/types/companyApi";
import {getLabelAndValue} from "../../lib/utils/getLabelAndValue";
import {useAppUserData} from "shared/hooks/useAppUserData";
import {LoadingButton} from "@mui/lab";
import {useOpenChatWithUser} from "entities/chat/lib/utils/openChatWithUser";

const Profile: FC<
  EmployeeProfileSemiPublic &
    RolesSplit & {
      isSkeleton?: boolean;
      title?: string;
      onClose: () => void;
    }
> = (props) => {
  const {fio, avatar, title, isSkeleton, id, onClose, ...otherProps} = props;
  const userData = useAppUserData();
  const {isLoading, onOpenChatWithUser} = useOpenChatWithUser({onClose});

  const handleOpenChat = () => {
    onOpenChatWithUser({userId: id});
  };

  return (
    <Box>
      <UI_Profile
        fio={fio}
        photoUrl={avatar?.thumbUrl}
        isSkeleton={isSkeleton}
        title={title}
      >
        {fields.map((field) => {
          const {label, value} = getLabelAndValue(
            field,
            otherProps,
            !!otherProps.isNotMyCompany,
          );
          return (
            <Box
              mb="16px"
              key={field}
              sx={{
                mb: "16px",
                ":last-of-type": {
                  mb: 0,
                },
              }}
            >
              <Typography variant="h3-medium-13" paragraph>
                {label}:
              </Typography>
              <Typography variant="h3-regular-13">
                {isSkeleton ? <Skeleton variant="rectangular" width={100} /> : value}
              </Typography>
            </Box>
          );
        })}
      </UI_Profile>
      {id && userData?.id !== id && userData?.company?.id ? (
        <LoadingButton
          loading={isLoading}
          variant="link"
          size="small"
          sx={{fontSize: "13px !important"}}
          onClick={handleOpenChat}
        >
          Связаться
        </LoadingButton>
      ) : null}
    </Box>
  );
};

export {Profile};
