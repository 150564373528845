import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {counterAgentService} from "shared/services/CounterAgentService";
import {initialState} from "./initialState";
import {resetState} from "shared/store/resetState";

async function getData() {
  return new Promise((res) => {
    setTimeout(() => {
      res("hello");
    }, 2000);
  });
}

export const searchCompanies = createAsyncThunk(
  "searchCompanies",
  async (data, {rejectWithValue}) => {
    try {
      return await getData();
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const reqMini = createAsyncThunk("reqMini", async (params, {rejectWithValue}) => {
  try {
    const {data} = await counterAgentService.reqMini(params);
    return data;
  } catch (e) {
    return rejectWithValue(e);
  }
});

export const analytics = createAsyncThunk(
  "analytics",
  async (params, {rejectWithValue}) => {
    try {
      const {data} = await counterAgentService.analytics(params);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const finanValues = createAsyncThunk(
  "finanValues",
  async (params, {rejectWithValue}) => {
    try {
      const {data} = await counterAgentService.finanValues(params);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const companyProps = createAsyncThunk(
  "companyProps",
  async (params, {rejectWithValue}) => {
    try {
      const {data} = await counterAgentService.companyProps(params);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const hierarhy = createAsyncThunk(
  "hierarhy",
  async (params, {rejectWithValue}) => {
    try {
      const {data} = await counterAgentService.hierarhy(params);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const briefReport = createAsyncThunk(
  "briefReport",
  async (params, {rejectWithValue}) => {
    try {
      const {data} = await counterAgentService.briefReport(params);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);
export const getOKVEDList = createAsyncThunk(
  "getOKVEDList",
  async (params, {rejectWithValue}) => {
    try {
      const {data} = await counterAgentService.getOKVEDList(params);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);
export const getTrademarks = createAsyncThunk(
  "getTrademarks",
  async (params, {rejectWithValue}) => {
    try {
      const {data} = await counterAgentService.trademarks(params);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const getLicences = createAsyncThunk(
  "getLicences",
  async (params, {rejectWithValue}) => {
    try {
      const {data} = await counterAgentService.licences(params);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const getEGRUL = createAsyncThunk(
  "getEGRUL",
  async (params, {rejectWithValue}) => {
    try {
      const {data} = await counterAgentService.getEGRUL(params);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const getTaxes = createAsyncThunk(
  "getTaxes",
  async (params, {rejectWithValue}) => {
    try {
      const {data} = await counterAgentService.taxes(params);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const loadCompanyInHierarchy = createAsyncThunk(
  "loadCompanyInHierarchy",
  async (params, {rejectWithValue}) => {
    try {
      const {data} = await counterAgentService.reqMini(params);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const RegisterOfCompaniesSlice = createSlice({
  name: "RegisterOfCompaniesSlice",
  initialState,
  reducers: {
    ...resetState,

    clearCurrentCompany(state) {
      state.curentCompany = null;
    },
    clearHierarchy(state) {
      state.hierarhyGraph = null;
    },
    clearReq(state) {
      state.hierarhyGraph = null;
    },
    clearCurrentBriefReport(state) {
      state.currentBriefReport = null;
      state.currentOKVEDList = null;
      state.currentTadeMarks = null;
      state.currentLicences = null;
      state.currentEGRULRecords = null;
      state.currentTaxes = null;
    },

    ...resetState,
  },

  extraReducers: (builder) =>
    builder
      .addCase(searchCompanies.fulfilled, (state) => {
        state.isLoading = false;
      })

      .addCase(searchCompanies.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(searchCompanies.rejected, (state) => {
        state.isLoading = false;
      })
      //reqMini
      .addCase(reqMini.fulfilled, (state) => {
        state.isLoading = false;
      })

      .addCase(reqMini.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(reqMini.rejected, (state) => {
        state.isLoading = false;
      })

      //analytics
      .addCase(analytics.fulfilled, (state) => {
        state.isLoading = false;
      })

      .addCase(analytics.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(analytics.rejected, (state) => {
        state.isLoading = false;
      })

      //finanValues
      .addCase(finanValues.fulfilled, (state) => {
        state.isLoading = false;
      })

      .addCase(finanValues.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(finanValues.rejected, (state) => {
        state.isLoading = false;
      })
      //companyProps
      .addCase(companyProps.fulfilled, (state, action) => {
        state.isLoading = false;
        state.curentCompany = action.payload;
      })
      .addCase(companyProps.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(companyProps.rejected, (state) => {
        state.isLoading = false;
        state.curentCompany = null;
      })
      //hierarhy
      .addCase(hierarhy.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hierarhyGraph = action.payload;
      })
      .addCase(hierarhy.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(hierarhy.rejected, (state) => {
        state.isLoading = false;
        state.hierarhyGraph = null;
      })
      //briefReport
      .addCase(briefReport.fulfilled, (state, action) => {
        state.isLoadingReport = false;
        state.currentBriefReport = action.payload;
      })
      .addCase(briefReport.pending, (state) => {
        state.isLoadingReport = true;
      })
      .addCase(briefReport.rejected, (state) => {
        state.isLoadingReport = false;
        state.currentBriefReport = null;
      })
      //getOKVEDList
      .addCase(getOKVEDList.fulfilled, (state, action) => {
        state.isLoadingReport = false;
        state.currentOKVEDList = action.payload;
      })
      .addCase(getOKVEDList.pending, (state) => {
        state.isLoadingReport = true;
      })
      .addCase(getOKVEDList.rejected, (state) => {
        state.isLoadingReport = false;
        state.currentOKVEDList = null;
      })
      //getTrademarks
      .addCase(getTrademarks.fulfilled, (state, action) => {
        state.isLoadingReport = false;
        state.currentTadeMarks = action.payload;
      })
      .addCase(getTrademarks.pending, (state) => {
        state.isLoadingReport = true;
      })
      .addCase(getTrademarks.rejected, (state) => {
        state.isLoadingReport = false;
        state.currentTadeMarks = null;
      })
      //getLicences
      .addCase(getLicences.fulfilled, (state, action) => {
        state.isLoadingReport = false;
        state.currentLicences = action.payload;
      })
      .addCase(getLicences.pending, (state) => {
        state.isLoadingReport = true;
      })
      .addCase(getLicences.rejected, (state) => {
        state.isLoadingReport = false;
        state.currentLicences = null;
      })
      //getEGRUL
      .addCase(getEGRUL.fulfilled, (state, action) => {
        state.isLoadingReport = false;
        state.currentEGRULRecords = action.payload;
      })
      .addCase(getEGRUL.pending, (state) => {
        state.isLoadingReport = true;
      })
      .addCase(getEGRUL.rejected, (state) => {
        state.isLoadingReport = false;
        state.currentEGRULRecords = null;
      })
      //getTaxes
      .addCase(getTaxes.fulfilled, (state, action) => {
        state.isLoadingReport = false;
        state.currentTaxes = action.payload;
      })
      .addCase(getTaxes.pending, (state) => {
        state.isLoadingReport = true;
      })
      .addCase(getTaxes.rejected, (state) => {
        state.isLoadingReport = false;
        state.currentTaxes = null;
      })
      //loadCompanyInHierarchy
      .addCase(loadCompanyInHierarchy.fulfilled, (state, action) => {
        state.loadingInDrawer = false;
        state.selectedCompanyInHierarchy = action.payload;
      })
      .addCase(loadCompanyInHierarchy.pending, (state) => {
        state.loadingInDrawer = true;
      })
      .addCase(loadCompanyInHierarchy.rejected, (state) => {
        state.loadingInDrawer = false;
        state.selectedCompanyInHierarchy = null;
      }),
});

export const {clearCurrentCompany, clearHierarchy, clearReq, clearCurrentBriefReport} =
  RegisterOfCompaniesSlice.actions;

export default RegisterOfCompaniesSlice.reducer;
