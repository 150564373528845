export const ROUTES = {
  CONSUMER: "/customers",
  PROVIDER: "/suppliers",
  NEWS: "/news",
  NEWS_DETAIL: "/news/:id",
  REGISTRATION_USER: "/registration",
  TICKETS: "/tickets",
  // SAFE_REGISTRY: "/safe-registry",
  TICKETS_PAGE: "/tickets/:page",
  // TICKETS_SEARCH: "/tickets/search/:page/:query",
  TICKETS_COMPANY: "/tickets-company",
  TICKETS_COMPANY_PAGE: "/tickets-company/:page",
  // TICKETS_COMPANY_SEARCH: "/tickets-company/search/:page/:query",
  TICKET_DETAIL_PROCEDURE: "/tickets/procedure/:id",
  TICKET_DETAIL_COMPANY: "/tickets/company/:id",
  REGISTRATION_COMPANY: "/registration-company",
  HOME: "/",
  VERIFICATION: "/registration/verification/:code",
  CATALOG: "/catalog",
  SRM: "/srm",
  // FZ_223: "/223-fz",
  BZ: "/bz",
  BLOG: "/blog",
  BLOG_ID: "/blog/:id",
  TRADE_CREATE_PURCHASES: "/trade/trade-create-purchases",
  TRADE_CREATE: "/trade/trade-create",
  TRADE: "/trade",
  TRADE_DETAIL: "/procedure/:idProc",
  PARTICIPATION_IN_PROCEDURE: "/trade/participation",
  PARTICIPATION_TICKETS: "/trade/participations",
  TRADE_EDIT: "/trade/trade-edit/:id",
  PARTICIPATION_TICKET: "/trade/participation/:ticket_participation_id",
  PART_OF_PARTICIPANT_TICKET: "/ticket_participation/:ticket_participation_id",
  COMPANIES: "/companies",
  COMPANY: "/companies/:inn",
  QUOTES: "/quotes",
  SERVICES: "/services",
  SERVICES_COMPANY_VERIFICATION: "/services/company-verification",
  SERVICES_FINAN: "/services/company-verification",
  SERVICES_115FZ: "/services/company-verification",
  SERVICES_USRLE: "/services/company-verification",
  TARIFFS: "/tariffs",
  ABOUT_US: "/about-us",
  FEEDBACK: "/feedback",
  SEARCH: "/site-search",
  DASHBOARD: "/dashboard",
  PROFILE: "/profile",
  COMPANY_PROFILE: "/company-profile",
  USERS: "/company-users",
  COMPANY_USER_PROFILE: "/company-users/:id",
  NOTIFICATION_CONTROL: "/notification-control",
  NOTIFICATIONS: "/notifications",
  FORGOT_PASSWORD: "/users/confirmation-forgot-password/:code",
  JOIN_TO_COMPANY: "/join-to-company",
  FAQ: "/faq",
  SAFE_DEAL: "/safe-deal",
  SAFE_DEAL_EDIT: "/safe-deal/:id",
  SAFE_HISTORY_DEAL: "/safe-deal-history/:id",
  ANALITICS: "/analitics",
  MY_SUPPLIERS: "/my-suppliers",
  TRANSACTION_EVALUATION: "/transaction-evaluation",
  SAFETY: "/safety",
  TEST_CRYPTO_PRO: "/test-crypto-pro",
  FINANCE: "/finance",
  DOCUMENTS: "/documents",
  404: "/*",
} as const;
