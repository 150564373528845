import {createSlice} from "@reduxjs/toolkit";

const cacheSlice = createSlice(({
  name: "cache",
  initialState: {},
  reducers: {
    setCacheState: (state, {payload}) => {
      const hash = payload.hash;
      delete payload.hash;
      if (!state[hash]) state[hash] = {};
      state[hash] = payload;
      state[hash].lastUpdate = Date.now();
    },
  },
}));

export const {setCacheState} = cacheSlice.actions;

export default cacheSlice.reducer;
