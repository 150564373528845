import {Components} from "@mui/material/styles";
import type {} from "@mui/x-date-pickers/themeAugmentation";
import {renderScrollStyle} from "shared/components/UI/UI_ScrollContainer/utils/ScrollStyle";

export const muiDateCalendar: Components["MuiDateCalendar"] = {
  defaultProps: {
    sx: ({palette}) => ({
      ".MuiYearCalendar-root": {
        ...renderScrollStyle(palette),
      },
    }),
  },
};
