import axios from "axios";
import {API_URL} from "shared/constants/API";
import {axiosGlobal} from "shared/services/axiosInstance/instAxios";
import {IErrorBackendReq} from "./types";

export const errorLoggerService = {
  log: (body) =>
    axiosGlobal().post("/procedures/errors/setError/", body, {
      params: {user_id: body.userId},
    }),
};

export const errorGetService = {
  log: (body) => axiosGlobal().post("/procedures/errors/getErrors/", body),
};

export const logBackendErrors = async (body: IErrorBackendReq) =>
  await axios.post(API_URL + "/procedures/errors/setErrorBack/", body, {
    params: {user_id: body.userId},
  });
