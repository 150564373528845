import {Components, Theme} from "@mui/material/styles";
import {renderScrollStyle} from "shared/components/UI/UI_ScrollContainer/utils/ScrollStyle";

export const muiTextField: Components<Theme>["MuiTextField"] = {
  defaultProps: {
    autoComplete: "off",
    sx: {
      "& legend": {display: "none"},
      "& fieldset": {top: 0},
    },
    fullWidth: true,
    helperText: " ",
    InputLabelProps: {
      shrink: true,
    },
  },
  styleOverrides: {
    root: ({theme}) => ({
      "& .MuiOutlinedInput-root:hover": {
        "& > fieldset": {
          borderColor: theme.palette.primary.main,
        },
      },
      textArea: {
        ...renderScrollStyle(theme.palette),
      },
      input: {
        "&:-webkit-autofill": {
          WebkitBoxShadow: `0 0 0 1000px ${theme.palette.backgroundBlock.default} inset`,
        },
      },
    }),
  },
};
