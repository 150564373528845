import {UserInfo} from "RTKQuery/types/userApi";

export interface IStateUser {
  userData: UserInfo | undefined;
  // idForUserInfo: string | null;
}
export const initialState: IStateUser = {
  userData: undefined,
  // idForUserInfo: null,
};
