import TextField from "@mui/material/TextField";

function Message({onChange, message}) {
  function onMessageChange(event) {
    onChange(event.target.value);
  }

  return (
    <TextField
      label={"Подписываемое сообщение: *"}
      id="message"
      name="message"
      placeholder="Введите сообщение"
      value={message}
      onChange={onMessageChange}
    />
  );
}

export default Message;
