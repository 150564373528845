import {FC, ReactNode} from "react";
import {useRouteError} from "react-router-dom";

interface IErrorRouteLoaderProps {
  className?: string;
  children?: ReactNode;
}

const ErrorRouteLoader: FC<IErrorRouteLoaderProps> = () => {
  const error = useRouteError();
  throw error;
};

export default ErrorRouteLoader;
