import {EmployeeProfileSemiPublic, RolesSplit} from "RTKQuery/types/companyApi";

export const fields: readonly (keyof EmployeeProfileSemiPublic | keyof RolesSplit)[] = [
  "position",
  "department",
  "email",
  "phoneNumber",
  "blockedInCompany",
  "etpRoles",
  "srmRoles",
  "projectManagerRole",
  "systemRole",
] as const;

export const fieldLabels: Partial<Record<(typeof fields)[number], string>> = {
  email: "Email",
  phoneNumber: "Телефон",
  position: "Должность",
  department: "Отдел",
  blockedInCompany: "Заблокирован",
  srmRoles: "SRM роли",
  systemRole: "Системная роль",
  projectManagerRole: "Менеджер проекта в SRM",
  etpRoles: "ЭТП роли",
};
