export interface IFeedbackState {
  isLoading: boolean;
  dataError: any;
  isVisibleNotification: boolean;
  isLoadingQuestionComplex: boolean;
}
export const initialState: IFeedbackState = {
  isLoading: false,
  dataError: {},
  isVisibleNotification: false,
  isLoadingQuestionComplex: false,
};
