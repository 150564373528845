import {useAppSelector} from "./useAppSelector";

/**
 * Получить данные по user из стора (эндпойнт /users/me)
 */
export const useAppUserData = () => {
  const userData = useAppSelector((s) => s.UserRegisterSlice.userData);

  return userData;
};
