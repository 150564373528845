import {
  HTTP_400_BAD_REQUEST,
  HTTP_401_UNAUTHORIZED,
  HTTP_403_FORBIDDEN,
  HTTP_404_NOT_FOUND,
  HTTP_406_NOT_ACCEPTABLE,
  HTTP_409_CONFLICT,
  HTTP_418_IM_A_TEAPOT,
  HTTP_422_UNPROCESSABLE_ENTITY,
} from "shared/constants/API";

export class HTTPHelper {
  process409() {
    console.log(409);
  }
  process500() {
    console.log(500);
  }
  processServerError(response) {
    // if (this.serverErrorCB) {
    //   // this.serverErrorCB(response);
    // }
    return response;
  }

  handlerError(response, ...args) {
    console.log(response, "HTTPHelper handlerError");
    switch (response.status) {
      case HTTP_400_BAD_REQUEST:
        return this.process400(response, ...args);
      case HTTP_401_UNAUTHORIZED:
        return this.process401(response, ...args);
      case HTTP_403_FORBIDDEN:
        return this.process403(response, ...args);
      case HTTP_404_NOT_FOUND:
        return this.process404(response, ...args);
      case HTTP_406_NOT_ACCEPTABLE:
        return this.process406(response, ...args);
      case HTTP_409_CONFLICT:
        return this.process409(response, ...args);
      case HTTP_418_IM_A_TEAPOT:
        return this.process418(response, ...args);
      case HTTP_422_UNPROCESSABLE_ENTITY:
        return this.process422(response, ...args);

      default:
        if (response.status > 499 && response.status < 600) {
          return this.processServerError(response);
        }
        console.log(response, "!!!!!!!!!!! ERROR IS  CASE !!!!!!!!!!!!");
        return response;
    }
  }
}
