export type TLoginReqData = {
  username: string;
  password: string;
  fingerprint: string;
};

export type TConfirmationDeviceReqData = {
  fingerprint: string;
  PIN: number;
};
export type TFingerprintData = {
  fingerprint: string;
};
export interface IAuthState {
  isLoading: boolean;
  success: boolean;
  visitorId: any;
  dataError: any;
  isNeedToConfirm: boolean;
  isAuth: boolean;
  // needRefresh: boolean;
  refreshInProcess: boolean;
  expiresAt: number;
  isRememberMe: boolean;
  isLoadingFingerID: boolean;
  isLoadingRefresh: boolean;
  refreshError: any;
  isRegistered: boolean;
}
export const initialState: IAuthState = {
  isLoading: false,
  success: false,
  visitorId: null,
  dataError: {},
  isNeedToConfirm: false,
  isAuth: localStorage.getItem("auth") === "true",
  // needRefresh: true,
  refreshInProcess: false,
  expiresAt: 1800,
  isRememberMe: true,
  isLoadingFingerID: false,
  isLoadingRefresh: false,
  refreshError: null,
  isRegistered: false,
};
