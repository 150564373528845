import {RouteObject} from "react-router-dom";
import {lazy} from "react";
import {ROUTES} from "shared/SRM_shared/const/paths/paths";

const MainPage = lazy(() => import("pages/SRM/(private)/pages/MainPage"));
const ProjectCreatePage = lazy(
  () => import("pages/SRM/(private)/pages/CreateProjectPage"),
);
const EditNewProjectPage = lazy(
  () => import("pages/SRM/(private)/pages/EditNewProjectPage"),
);
const EstimateAddPage = lazy(() => import("pages/SRM/(private)/pages/EstimateAddPage"));
const EstimatePage = lazy(() => import("pages/SRM/(private)/pages/EstimatePage"));
const СonfirmationPage = lazy(() => import("pages/SRM/(private)/pages/CloseProjectPage"));
const TicketList = lazy(() => import("pages/SRM/(private)/pages/TicketList"));
const CreateTicket = lazy(() => import("pages/SRM/(private)/pages/CreateTicket"));
const TicketMovement = lazy(() => import("pages/SRM/(private)/pages/TicketMovement"));
const PositionsPage = lazy(() => import("pages/SRM/(private)/pages/PositionsPage"));
const RegisterTradePage = lazy(
  () => import("pages/SRM/(private)/pages/RegisterTradePage"),
);
const ApproveSupplier = lazy(() => import("pages/SRM/(private)/pages/ApproveSupplier"));
const CreateProtocolPage = lazy(() => import("pages/SRM/(private)/pages/CreateProtocol"));

export const APP_ROUTES_SRM: RouteObject[] = [
  {
    index: true,
    element: <MainPage />,
  },
  {
    path: ROUTES.projects,
    element: <MainPage />,
  },
  {
    path: ROUTES.createProject,
    element: <ProjectCreatePage />,
  },
  {
    path: ROUTES.editNewProject,
    element: <EditNewProjectPage />,
  },
  {
    path: ROUTES.editProject,
    element: <ProjectCreatePage />,
  },
  {
    path: ROUTES.estimateAdd,
    element: <EstimateAddPage />,
  },
  {
    path: ROUTES.estimate,
    element: <EstimatePage />,
  },
  {
    path: ROUTES.confirmation,
    element: <СonfirmationPage />,
  },
  {
    path: ROUTES.tickets,
    element: <TicketList />,
  },
  {
    path: ROUTES.createTicket,
    element: <CreateTicket />,
  },
  {
    path: ROUTES.ticketMovement,
    element: <TicketMovement />,
  },
  {
    path: ROUTES.editTicket,
    element: <CreateTicket />,
  },

  {
    path: ROUTES.positions,
    element: <PositionsPage />,
  },
  {
    path: ROUTES.registerTrade,
    element: <RegisterTradePage />,
  },
  {
    path: ROUTES.approveSupplier,
    element: <ApproveSupplier />,
  },
  {
    path: ROUTES.createProtocol,
    element: <CreateProtocolPage />,
  },
];
