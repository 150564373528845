import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import {NotesService} from "shared/services/NotesService";
import {resetState} from "shared/store/resetState";
import {initialState} from "./types";

export const getNotes = createAsyncThunk(
  "getNotes",
  async (params, {rejectWithValue}) => {
    try {
      const result = await NotesService.getNotes(params);
      return result.data;
    } catch (e: any) {
      return rejectWithValue(e.response.data.detail);
    }
  },
);
export const getNotesPast = createAsyncThunk(
  "getNotesPast",
  async (params, {rejectWithValue}) => {
    try {
      const result = await NotesService.getNotes(params);
      return result.data;
    } catch (e: any) {
      return rejectWithValue(e.response.data.detail);
    }
  },
);

export const getNotesFuture = createAsyncThunk(
  "getNotesFuture",
  async (params, {rejectWithValue}) => {
    try {
      const result = await NotesService.getNotes(params);
      return result.data;
    } catch (e: any) {
      return rejectWithValue(e.response.data.detail);
    }
  },
);
export const getNotesNear = createAsyncThunk(
  "getNotesNear",
  async (_, {rejectWithValue}) => {
    try {
      const result = await NotesService.getNotes({current: true});
      return result.data;
    } catch (e: any) {
      return rejectWithValue(e.response.data.detail);
    }
  },
);

export const addNote = createAsyncThunk(
  "addNote",
  async ({note, onSuccess}: any, {rejectWithValue}) => {
    try {
      const result = await NotesService.addNote(note);
      onSuccess();
      return result.data;
    } catch (e: any) {
      return rejectWithValue(e.response.data.detail);
    }
  },
);

export const deleteNote = createAsyncThunk(
  "deleteNote",
  async (noteId, {rejectWithValue}) => {
    try {
      const result = await NotesService.deleteNote(noteId);
      return result.data;
    } catch (e: any) {
      return rejectWithValue(e.response.data.detail);
    }
  },
);

export const editNote = createAsyncThunk("editNote", async (note, {rejectWithValue}) => {
  try {
    const result = await NotesService.editNote(note);
    return result.data;
  } catch (e: any) {
    return rejectWithValue(e.response.data.detail);
  }
});

export const searchNode = createAsyncThunk(
  "searchNode",
  async (params, {rejectWithValue}) => {
    try {
      const result = await NotesService.getNotes(params);

      return result.data;
      // return result;
    } catch (e: any) {
      return rejectWithValue(e.response.data.detail);
    }
  },
);

export const notesSlice = createSlice({
  name: "notesSlice",
  initialState,
  reducers: {
    ...resetState,
    clearErrorData: (state) => {
      state.dataError = "";
    },
    removeError: (state, action) => {
      // @ts-ignore
      state.dataError[action.payload] = "";
    },
    removeSearchDataNotes: (state) => {
      state.notesSearch = null;
    },
    setSelectedDate(state, action) {
      state.selectedDate = action.payload;
    },
    setMinDateUntilLoadedNotes(state, action) {
      state.minDateUntilLoadedNotes = action.payload;
    },
    setMaxDateUntilLoadedNotes(state, action) {
      state.maxDateUntilLoadedNotes = action.payload;
    },
  },
  extraReducers: (builder) =>
    builder
      //getNotes
      .addCase(getNotes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.notes = action.payload.notes;
        state.notesRangeThatExist = [
          // @ts-ignore
          action.payload.time_min,
          // @ts-ignore
          action.payload.time_max,
        ];
      })
      .addCase(getNotes.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getNotes.rejected, (state, action) => {
        state.isLoading = false;
        // @ts-ignore
        state.dataError = action.payload;
      })
      //getNotesFuture
      .addCase(getNotesFuture.fulfilled, (state, action) => {
        state.isLoading = false;
        // @ts-ignore
        state.notes = [...action.payload.notes, ...state.notes];
      })
      .addCase(getNotesFuture.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getNotesFuture.rejected, (state, action) => {
        state.isLoading = false;
        // @ts-ignore
        state.dataError = action.payload;
      })
      //getNotesPast
      .addCase(getNotesPast.fulfilled, (state, action) => {
        state.isLoading = false;
        // @ts-ignore
        state.notes = [...state.notes, ...action.payload.notes];
      })
      .addCase(getNotesPast.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getNotesPast.rejected, (state, action) => {
        state.isLoading = false;
        // @ts-ignore
        state.dataError = action.payload;
      })
      //getNotesNear
      .addCase(getNotesNear.fulfilled, (state, action) => {
        state.isLoading = false;
        state.nearNotes = action.payload.notes;
      })
      .addCase(getNotesNear.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getNotesNear.rejected, (state, action) => {
        state.isLoading = false;
        // @ts-ignore
        state.dataError = action.payload;
      })
      //addNote
      .addCase(addNote.fulfilled, (state, action) => {
        state.isLoading = false;
        // @ts-ignore
        state.notes.push(action.payload);
        // @ts-ignore
        state.nearNotes.push(action.payload);
      })
      .addCase(addNote.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addNote.rejected, (state, action) => {
        state.isLoading = false;
        // @ts-ignore
        state.dataError = action.payload;
      })
      //deleteNote
      .addCase(deleteNote.fulfilled, (state, action) => {
        state.isLoading = false;
        state.notes = state.notes.filter(({id}) => id !== action.payload.id);
        state.nearNotes = state.nearNotes.filter(({id}) => id !== action.payload.id);
      })
      .addCase(deleteNote.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteNote.rejected, (state, action) => {
        state.isLoading = false;
        // @ts-ignore
        state.dataError = action.payload;
      })
      //editNote
      .addCase(editNote.fulfilled, (state, action) => {
        state.isLoading = false;
        const idx = state.notes.findIndex(({id}) => action.payload.id === id);
        // @ts-ignore
        state.notes[idx] = action.payload;
      })
      .addCase(editNote.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editNote.rejected, (state, action) => {
        state.isLoading = false;
        // @ts-ignore
        state.dataError = action.payload;
      })
      //searchNode
      .addCase(searchNode.fulfilled, (state, action) => {
        state.isLoadingSearch = false;
        state.notesSearch = action.payload.notes;
      })
      .addCase(searchNode.pending, (state) => {
        state.isLoadingSearch = true;
      })
      .addCase(searchNode.rejected, (state, action) => {
        state.isLoadingSearch = false;
        // @ts-ignore
        state.dataError = action.payload;
      }),
  //
});
export const {
  clearErrorData,
  removeError,
  removeSearchDataNotes,
  setSelectedDate,
  setMaxDateUntilLoadedNotes,
  setMinDateUntilLoadedNotes,
} = notesSlice.actions;

export default notesSlice.reducer;
