
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import {FC, ReactNode} from "react";

interface ILazyLoadingProps {
  className?: string;
  children?: ReactNode;
}

/**
 * Используется в react-router-dom в provider
 */
const LazyLoading: FC<ILazyLoadingProps> = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export {LazyLoading};
