
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {Component, ReactNode} from "react";
import {errorLoggerService} from "shared/services/errorLoggerService/errorLoggerService";
import {store} from "shared/store/store";

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

let {location} = window;

class ErrorBoundary extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {hasError: false};
  }

  static getDerivedStateFromError(error) {
    return {hasError: true};
  }

  componentDidCatch(error, info) {
    console.error(error);
    //логирование ошибок
    if (process.env.NODE_ENV === "production") {
      // setTimeout(() => {
      //   location.href = "/";
      // }, 4000);
      errorLoggerService.log({
        error: JSON.stringify(error, Object.getOwnPropertyNames(error)),
        info,
        store: store.getState(),
        pathname: location.pathname,
        userId: store.getState().UserRegisterSlice.userData?.id,
      });
    }
  }

  render() {
    if (this.state?.hasError) {
      return (
        <Stack
          sx={{
            width: "100vw",
            height: "100vh",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Stack sx={{minHeight: "50vh", alignItems: "center", gap: 2}}>
            <Typography component={"h2"} sx={{fontSize: 24, fontWeight: "bold"}}>
              Что-то пошло не так...
            </Typography>
            <Typography component={"a"} href={"/"}>
              Перейти на главную страницу?
            </Typography>
          </Stack>
        </Stack>
      );
    }

    return this.props?.children;
  }
}

export default ErrorBoundary;
