import {axiosGlobal} from "shared/services/axiosInstance/instAxios";
import {ServiceResponse} from "shared/services/servicesAll";

const TICKETS_ENDPOINT = "/tickets";
const TICKETS_USER_ENDPOINT = `${TICKETS_ENDPOINT}/user`;
const TICKETS_COMPANY_ENDPOINT = `${TICKETS_ENDPOINT}/company`;

export const ticketsService = {
  ticket: ({service, ticketId}) => axiosGlobal().get(`${service}/tickets/${ticketId}`),

  userView: (): ServiceResponse<View> =>
    axiosGlobal().get(`${TICKETS_USER_ENDPOINT}/view`),

  user: (params): ServiceResponse<MainResponse> =>
    axiosGlobal().get(TICKETS_USER_ENDPOINT, {params}),

  companyView: (): ServiceResponse<View> =>
    axiosGlobal().get(`${TICKETS_COMPANY_ENDPOINT}/view`),

  company: (params): ServiceResponse<MainResponse> =>
    axiosGlobal().get(TICKETS_COMPANY_ENDPOINT, {params}),

  count: (params): ServiceResponse<{totalCount: number}> =>
    axiosGlobal().get(`${TICKETS_USER_ENDPOINT}/count`, {params}),
};

type Ticked = {
  id: string;
  status: string;
  theme: string;
  title: string;
  resolution: string;
  service: string;
  takenToWork: true;
  takenToWorkAt: string;
  takenToWorkBy: {
    id: string;
    fio: string;
    role: string;
  };
  company: {
    id: string;
    name: string;
    opf: string;
  };
  createdAt: string;
  createdBy: {
    id: string;
    fio: string;
    role: string;
  };
  updatedAt: string;
  updatedBy: {
    id: string;
    fio: string;
    role: string;
  };
  hid: number;
};

interface MainResponse {
  totalCount: number;
  count: number;
  totalPages: number;
  currentPage: number;
  data: Ticked[];
}

interface View extends MainResponse {
  themes: string[];
  statuses: string[];
}
