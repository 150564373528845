import {IRegisterCompanyState} from "./registerCompany.types";

export const initialState: IRegisterCompanyState = {
  isLoading: false,
  curentCompany: null,
  hierarhyGraph: null,
  currentReq: null,
  currentBriefReport: null,
  isLoadingReport: false,
  currentOKVEDList: null,
  currentTadeMarks: null,
  currentLicences: null,
  currentEGRULRecords: null,
  currentTaxes: null,
  loadingInDrawer: false,
  selectedCompanyInHierarchy: null,
};
