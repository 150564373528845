import {useState} from "react";
import {LOCAL_STORAGE_THEME_KEY, themes} from "shared/constants/themes";

export type TLight = (typeof themes)["LIGHT"];
export type TDark = (typeof themes)["DARK"];

export const useColorMode = (value: TLight | TDark = "light") => {
  const [colorMode, setColorMode] = useState(value);
  const html = document.getElementsByTagName("html");
  html[0].setAttribute("data-theme", colorMode);

  const setMode = (v: TLight | TDark) => {
    const html = document.getElementsByTagName("html");
    html[0].setAttribute("data-theme", v);

    localStorage.setItem(LOCAL_STORAGE_THEME_KEY, v);
    setColorMode(v);
  };
  return {colorMode, setColorMode: setMode};
};
