import {axiosGlobal} from "shared/services/axiosInstance/instAxios";
import {ServiceResponse} from "shared/services/servicesAll";

const REG_COMPANY_API = "/counteragent";
export const REG_COMPANY_API_V2 = "/counteragent/v2";

export const counterAgentServiceList = {
  address: REG_COMPANY_API + "/address",
  reqMini: REG_COMPANY_API_V2 + "/req-mini",
  req: REG_COMPANY_API_V2 + "/req",
  analytics: REG_COMPANY_API_V2 + "/analytics",
  finan: REG_COMPANY_API_V2 + "/finan",
  excerpt: REG_COMPANY_API_V2 + "/excerpt",
  finanValues: REG_COMPANY_API_V2 + "/finan-values",
  companyProps: REG_COMPANY_API_V2 + "/company-props",
  hierarhy: REG_COMPANY_API_V2 + "/hierarchy2",
  briefReport: REG_COMPANY_API_V2 + "/briefReport",
  getOKVEDList: REG_COMPANY_API_V2 + "/okved",
  trademarks: REG_COMPANY_API_V2 + "/trademarks",
  licences: REG_COMPANY_API_V2 + "/licences",
  getEGRUL: REG_COMPANY_API_V2 + "/egrul",
  taxes: REG_COMPANY_API_V2 + "/taxes",
  getRequisitesPdf: REG_COMPANY_API_V2 + "/req.pdf",
  getbriefReportPdf: REG_COMPANY_API_V2 + "/briefReport.pdf",
};

export const counterAgentService = {
  req: (params): ServiceResponse<CounterAgentServiceResponse["req"]> =>
    axiosGlobal().get(counterAgentServiceList.req, {params}),

  address: (address) =>
    axiosGlobal().get(counterAgentServiceList.address, {params: {address}}),
  reqMini: (params): ServiceResponse<CounterAgentServiceResponse["reqMini"]> =>
    axiosGlobal().get(counterAgentServiceList.reqMini, {params}),
  analytics: (params) => axiosGlobal().get(counterAgentServiceList.analytics, {params}),
  finan: (params) => axiosGlobal().get(counterAgentServiceList.finan, {params}),

  excerpt: (params) => axiosGlobal().get(counterAgentServiceList.excerpt, {params}),
  finanValues: (params): ServiceResponse<CounterAgentServiceResponse["finanValues"]> =>
    axiosGlobal().get(counterAgentServiceList.finanValues, {params}),
  companyProps: (params): ServiceResponse<CounterAgentServiceResponse["companyProps"]> =>
    axiosGlobal().get(counterAgentServiceList.companyProps, {params}),
  hierarhy: (params): ServiceResponse<CounterAgentServiceResponse["hierarhy"]> =>
    axiosGlobal().get(counterAgentServiceList.hierarhy, {params}),
  briefReport: (params): ServiceResponse<CounterAgentServiceResponse["briefReport"]> =>
    axiosGlobal().get(counterAgentServiceList.briefReport, {params}),
  getOKVEDList: (params): ServiceResponse<CounterAgentServiceResponse["getOKVEDList"]> =>
    axiosGlobal().get(counterAgentServiceList.getOKVEDList, {params}),
  trademarks: (params): ServiceResponse<CounterAgentServiceResponse["trademarks"]> =>
    axiosGlobal().get(counterAgentServiceList.trademarks, {params}),
  licences: (params): ServiceResponse<CounterAgentServiceResponse["licenses"]> =>
    axiosGlobal().get(counterAgentServiceList.licences, {params}),
  getEGRUL: (params): ServiceResponse<CounterAgentServiceResponse["getEGRUL"]> =>
    axiosGlobal().get(counterAgentServiceList.getEGRUL, {params}),
  taxes: (params): ServiceResponse<CounterAgentServiceResponse["taxes"]> =>
    axiosGlobal().get(counterAgentServiceList.taxes, {params}),

  getRequisitesPdf: (params) =>
    axiosGlobal().get(counterAgentServiceList.getRequisitesPdf, {params}),
} as const;

export type CounterAgentServiceResponse = {
  req: {
    inn: string;
    ogrn: string;
    opf: string;
    status: {
      statusStr: string;
      status: true;
      dissolving: true;
      date: string;
    };
    okvedMain: {
      code: string;
      text: string;
    };
    okvedList: [
      {
        code: string;
        text: string;
      },
    ];
    name: {
      name: string;
      shortName: string;
      readableName: string;
    };
    address: string;
    topManager: {
      topManagerName: string;
      topManagerPosition: string;
      topManagerInn: string;
    };
    kpp: string;
    okpo: string;
    okato: string;
    okfs: string;
    oktmo: string;
    okogu: string;
    okopf: string;
  };

  getOKVEDList: {
    okvedMain: {
      code: string;
      text: string;
    };
    okvedList: {
      code: string;
      text: string;
    }[];
  };

  reqMini: {
    inn: string;
    ogrn: string;
    opf: string;
    name: string;
    kpp: string;
    okpo: string;
    address: string;
    status: {
      statusStr: string;
      status: boolean;
      dissolving: boolean;
      date: string;
    };
  };

  analytics: {
    inn: string;
    ogrn: string;
  };

  finanValues: {
    expertRating: string;
    year: number;
    statisticalScore: number;
  };
  trademarks: {
    name: string;
    total: number;
    trademarks: {
      dateEnd: string;
      active: true;
      image: string;
      docNumber: string;
    }[];
  }[];

  getEGRUL: {
    documents: {
      name: string;
      date: string;
    }[];

    certificates: {
      serialNumber: string;
      date: string;
    }[];

    grn: string;
    date: string;
    name: string;
    isInvalid: true;
    invalidSince: string;
    regName: string;
    regCode: string;
  }[];

  taxes: {
    data: {
      name: string;
      sum: number;
    }[];

    year: number;
  }[];

  licenses: Record<
    string,
    {
      activity: string | null;
      dateEnd: string | null;
      dateStart: string;
      officialNum: string;
      source: string;
      status: string | null;
      statusStr: string | null;
    }[]
  >;

  companyProps: {
    company: {
      name: string;
      address: string;
      phones: string[];

      sites: string[];

      email: string;
    };
    rating: {
      expertRating: string;
      year: number;
      statisticalScore: number;
    };
    internalRating: number;
    smp: string;
    rnp: true;
  };
  hierarhy: {
    inn: string;
    name: string;
    logo: string;
    childrens: {
      name: string;
      inn: string;
      logo: string;
    }[];

    root: true;
  }[];

  briefReport: {
    info: {
      name: string;
      registrationDate: string;
      dissolutionDate: string;
      statusStr: string;
      req: {
        inn: string;
        ogrn: string;
        kpp: string;
        okpo: string;
        okato: string;
        okfs: string;
        oktmo: string;
        okogu: string;
        okopf: string;
        pfr: string;
        fss: string;
        foms: string;
      };
      address: string;
      topManager: {
        topManagerName: string;
        topManagerPosition: string;
        topManagerInn: string;
      };
      okved: {
        okvedMain: {
          code: string;
          text: string;
        };
        okvedList: {
          code: string;
          text: string;
        };
        total: number;
      };
      statedCapital: {
        sum: number;
        date: string;
        sumRepr: string;
      };
      nalogRegBody: {
        nalogCode: string;
        nalogName: string;
        nalogRegDate: string;
        kpp: string;
        date: string;
      };
    };
    history: {
      kpps: {
        kpp: string;
        date: string;
      }[];

      legalNames: {
        name: string;
        date: string;
      }[];

      legalAddresses: {
        name: string;
        date: string;
      }[];

      managementCompanies: {
        name: string;
        inn: string;
        ogrn: string;
        date: string;
      }[];

      heads: {
        fio: string;
        inn: string;
        date: string;
        position: string;
      }[];

      shareholders: {
        shareholdersFL: {
          fio: string;
          address: string;
          votingSharesPercent: number;
          capitalSharesPercent: number;
          date: string;
        }[];

        shareholdersUL: {
          ogrn: string;
          inn: string;
          fullName: string;
          address: string;
          votingSharesPercent: number;
          capitalSharesPercent: number;
          date: string;
        }[];

        shareholdersOther: {
          fullName: string;
          address: string;
          votingSharesPercent: number;
          capitalSharesPercent: number;
          date: string;
        }[];

        date: string;
        source: string;
      };
      shareholdersManual: {
        shareholdersFL: {
          fio: string;
          address: string;
          votingSharesPercent: number;
          capitalSharesPercent: number;
          date: string;
        }[];

        shareholdersUL: {
          ogrn: string;
          inn: string;
          fullName: string;
          address: string;
          votingSharesPercent: number;
          capitalSharesPercent: number;
          date: string;
        }[];

        shareholdersOther: {
          fullName: string;
          address: string;
          votingSharesPercent: number;
          capitalSharesPercent: number;
          date: string;
        }[];

        date: string;
        source: string;
      };
      statedCapitals: {
        sum: number;
        date: string;
        sumRepr: string;
      }[];

      foundersFL: {
        fio: string;
        inn: string;
        date: string;
        position: string;
      }[];

      foundersUL: {
        name: string;
        inn: string;
        ogrn: string;
        date: string;
      }[];
    };
    taxes: {
      year: string;
      sum: number;
      sumRepr: string;
    }[];

    filials: {
      address: string;
      kpp: string;
      date: string;
    }[];

    established: {
      name: string;
      inn: string;
    }[];

    licenses: {
      name: string;
      qty: number;
    }[];
    trademarks: {
      total: number;
    };
    inspections: {
      nearestYear: {
        year: number;
        total: number;
      };
      total: {
        unknown: number;
        violations: number;
      };
    };
    sanctionsLists: string[];

    govContracts: {
      participant: {
        sumRepr: string;
        sum: number;
        qty: number;
      };
      concluded: {
        sumRepr: string;
        sum: number;
        qty: number;
      };
      customer: {
        sumRepr: string;
        sum: number;
        qty: number;
      };
    };
    recordsInEgrul: {
      total: number;
      records: {
        name: string;
        date: string;
      }[];
    };
  };
};
