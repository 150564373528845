import {axiosGlobal} from "shared/services/axiosInstance/instAxios";

const NOTES_ENDPOINT = "/procedures";

export const NotesService = {
  getNotes: (params) => axiosGlobal().get(`${NOTES_ENDPOINT}/dashboard/notes`, {params}),
  addNote: (note) => axiosGlobal().post(`${NOTES_ENDPOINT}/dashboard/notes`, note),
  deleteNote: (noteId) =>
    axiosGlobal().delete(`${NOTES_ENDPOINT}/dashboard/notes/${noteId}`),
  editNote: (note) =>
    axiosGlobal().post(`${NOTES_ENDPOINT}/dashboard/notes/${note.id}`, note),
};
