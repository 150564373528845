import CopyAllOutlined from "@mui/icons-material/CopyAllOutlined";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import React from "react";

function Hash({hash, hashStatus, hashError, copyData}) {
  return (
    <Box margin={"0 10px"}>
      <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
        <legend>Результат</legend>
        <Button variant="contained" disabled={!hash} onClick={copyData}>
          Скопировать данные <CopyAllOutlined />
        </Button>
      </Stack>

      <br />

      <TextField
        label="Хеш (ГОСТ Р 34.11-2012 256 бит):"
        multiline
        id="hash"
        cols="80"
        rows="5"
        value={hash}
        placeholder={hashStatus}
        readOnly
      />
      {/* <textarea
        id="hash"
        cols="80"
        rows="5"
        value={hash}
        placeholder={hashStatus}
        readOnly
      /> */}

      <pre>{hashError || null}</pre>
    </Box>
  );
}

export default Hash;
