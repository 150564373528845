import {addMonths} from "date-fns";

export interface INotesState {
  notes: any[];
  isLoading: boolean;
  dataError: string;
  notesSearch: any;
  isLoadingSearch: boolean;
  nearNotes: any[];
  notesRangeThatExist: any[];
  selectedDate: Date;
  minDateUntilLoadedNotes: Date;
  maxDateUntilLoadedNotes: Date;
}
export const initialState = {
  notes: [],
  isLoading: false,
  dataError: "",
  notesSearch: null,
  isLoadingSearch: false,
  nearNotes: [],
  notesRangeThatExist: [],
  selectedDate: new Date(),
  minDateUntilLoadedNotes: new Date(addMonths(new Date(), -2)),
  maxDateUntilLoadedNotes: new Date(addMonths(new Date(), 2)),
};
