import {axiosGlobal} from "shared/services/axiosInstance/instAxios";

const endp = "/procedures";

export const PartisipantService = {
  participation: ({body, progreesCallback}) =>
    axiosGlobal().post(`${endp}/participations`, body, {
      onUploadProgress: progreesCallback,
    }),
  getListPartisipatnTickets: (body) =>
    axiosGlobal().post(`${endp}/participations/getList/`, body),
  getPartisipationTicket: (id) => axiosGlobal().get(`${endp}/participations/${id}`),

  disabled: (body) => axiosGlobal().post(`${endp}/participations/getList/disabled`, body),
  getTicket: (id) => axiosGlobal().get(`${endp}/participations/${id}`),

  getListByProc: (id) => axiosGlobal().get(`${endp}/participations/getListByProc/${id}`),
  setStatusesForProc: ({ticket_participation_id, body}) =>
    axiosGlobal().post(
      `${endp}/participations/setStatusesForProc/${ticket_participation_id}`,
      body,
    ),
  byCustomer: (ticket_participation_id) =>
    axiosGlobal().get(`${endp}/participations/byCustomer/${ticket_participation_id}`),
  getCountries: () => axiosGlobal().get(`${endp}/extra/getCountries/`),

  removeTiketParticipation: (ticket_participation_id) =>
    axiosGlobal().delete(`${endp}/participations/${ticket_participation_id}`),
  uploadParticipationInvoice: (body) =>
    axiosGlobal().post(`${endp}/crm/uploadParticipationInvoice`, body),
};
