import {AxiosRequestConfig} from "axios";
import {API_URL} from "shared/constants/API";
import {axiosGlobal} from "./axiosInstance/instAxios";
import {ServiceResponse} from "./servicesAll";

const CATALOG_API = API_URL + "/procedures";

export const createTradeServiseList = {
  getCats: CATALOG_API + "/catalog/getCats",
  getProds: CATALOG_API + "/catalog/getProds",
  getChars: CATALOG_API + "/catalog/getChars",
  searchProds: CATALOG_API + "/catalog/searchProds",
  getDraft: (id) => CATALOG_API + "/tickets/" + id,
  procCreate: CATALOG_API + "/tickets",
  getHolidays: CATALOG_API + "/extra/getHolidays",
  getFloatUnits: CATALOG_API + "/extra/getFloatUnits",
  searchProdWithOkpd: CATALOG_API + "/catalog/searchOkpd",
  getCompany: CATALOG_API + "/extra/getCompany",
  getRegisteredCompanies: CATALOG_API + "/extra/getRegisteredCompanies",
  convertExcelPositions: CATALOG_API + "/excel/convertExcelPositions",
  getRegions: CATALOG_API + "/extra/getRegions",
} as const;

export const createTradeServise = {
  getCats: () => axiosGlobal().get(CATALOG_API + "/catalog/getCats"),

  getProds: ({onpage, page, catID}) =>
    axiosGlobal().get(CATALOG_API + "/catalog/getProds", {
      params: {
        cat_id: catID,
        onpage,
        page,
      },
    }),
  getChars: (prod_id): ServiceResponse<createTradeServise["getChars"]> =>
    axiosGlobal().get(CATALOG_API + "/catalog/getChars", {
      params: {
        prod_id,
      },
    }),
  searchProds: (params) =>
    axiosGlobal().get(CATALOG_API + "/catalog/searchProds", {
      params,
    }),
  getDraft: (id) => axiosGlobal().get(CATALOG_API + "/tickets/" + id),

  procCreate: ({
    body,
    progreesCallback,
  }: {
    body: any;
    progreesCallback?: AxiosRequestConfig["onUploadProgress"];
  }) =>
    axiosGlobal().post(CATALOG_API + "/tickets", body, {
      onUploadProgress: progreesCallback,
    }),

  procCreateV2: (data, options) =>
    axiosGlobal().post(CATALOG_API + "/tickets", data, options),

  getHolidays: (params) =>
    axiosGlobal().get(CATALOG_API + "/extra/getHolidays", {
      params,
    }),

  getFloatUnits: (): ServiceResponse<createTradeServise["getFloatUnits"][]> =>
    axiosGlobal().get(CATALOG_API + "/extra/getFloatUnits"),

  searchProdWithOkpd: (body) =>
    axiosGlobal().post(CATALOG_API + "/catalog/searchOkpd", body),

  getCompany: () => axiosGlobal().get(CATALOG_API + "/extra/getCompany"),

  getRegisteredCompanies: () =>
    axiosGlobal().get(CATALOG_API + "/extra/getRegisteredCompanies"),

  convertExcelPositions: (body, progreesCallback) =>
    axiosGlobal().post(CATALOG_API + "/excel/convertExcelPositions", body, {
      onUploadProgress: progreesCallback,
    }),

  getRegions: () => axiosGlobal().get(CATALOG_API + "/extra/getRegions"),
};

export type createTradeServise = {
  getChars: {
    id: string;
    name: string;
    char_unit: string;
    values: {
      id: string;
      value: string;
    }[];
  }[];

  getRegisteredCompanies: {
    customer: string;
    id: string;
    inn: string;
    name: string;
    opf: string;
    supplier: boolean;
  };

  getFloatUnits: {name: string; float: boolean};
};
